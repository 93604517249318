(function (angular, app, document) {
    'use strict';

    app.controller('SideNavCtrl', [
        '$scope', '$rootScope', '$timeout', '$q', '$state', '$filter', 'Util', 'Config', 'User', 'Cart', 'Orders','PaymentsService',
        'Api', 'Dialog', 'ORDER_STATUS_STAGES', 'SP_SERVICES', 'ShippingTermsPopup', 'CREDIT_CARD_3DS_MODES', 'SP_PAYMENTS', 'DataLayer', 'Loyalty', 'LOYALTY_CLUB_DRIVERS', 'LocalStorage',
        function ($scope, $rootScope, $timeout, $q, $state, $filter, Util, Config, User, Cart, Orders, PaymentsService,
                  Api, Dialog, ORDER_STATUS_STAGES, SP_SERVICES, ShippingTermsPopup, CREDIT_CARD_3DS_MODES, SP_PAYMENTS, DataLayer, Loyalty, LOYALTY_CLUB_DRIVERS, LocalStorage) {
            var sideNavCtrl = this,
                _requestedHistoryOrderId,
                _addOrdersHistoryChunkPromise,
                _ordersHistoryChunkSize = 10,
                _resizeTimeOut,
                _cancelOrderTimeOut,
                _roundCurrencyFilter = $filter('roundCurrency'),
                _productNameFilter = $filter('productName'),
                _getLiveResults = false;

            $rootScope.noAvailableDeliverySlots = false;
            $rootScope.language = {culture: Config.retailer.culture};
            sideNavCtrl.ordersHistory = [];
            sideNavCtrl.ordersService = Orders;
            sideNavCtrl.historyOrderCheckedLinesTotal = 0;
            sideNavCtrl.cartIsEmpty = null;
            sideNavCtrl.toggleCart = toggleCart;
            sideNavCtrl.setHistoryOrder = setHistoryOrder;
            sideNavCtrl.clearHistoryOrder = clearHistoryOrder;
            sideNavCtrl.toggleOrdersHistory = toggleOrdersHistory;
            sideNavCtrl.checkAllHistoryOrderLines = checkAllHistoryOrderLines;
            sideNavCtrl.onCheckHistoryOrderLine = onCheckHistoryOrderLine;
            sideNavCtrl.addCheckedHistoryOrderLines = addCheckedHistoryOrderLines;
            sideNavCtrl.addHistoryOrderLine = addHistoryOrderLine;
            sideNavCtrl.addOrdersHistoryChunk = addOrdersHistoryChunk;
            sideNavCtrl.openOrderProduct = openOrderProduct;
            sideNavCtrl.editOrderCancel = editOrderCancel;
            sideNavCtrl.checkLineLoyaltyGift = checkLineLoyaltyGift;
            sideNavCtrl.checkout = checkout;
            sideNavCtrl.checkoutV2 = checkoutV2;
            sideNavCtrl.toggleSpecialReminders = toggleSpecialReminders;
            sideNavCtrl.deliveryNoAvailableSlotsPopup = deliveryNoAvailableSlotsPopup;
            sideNavCtrl.goToSmartList = goToSmartList;
            sideNavCtrl.clearCart = clearCart;
            sideNavCtrl.setErrorTooltipPosition = setErrorTooltipPosition;
            sideNavCtrl.userData = User.data;
            sideNavCtrl.sortCartByCategories = Config.retailer.settings.sortCartByCategories === 'true';
            sideNavCtrl.sortedCartByCategories = [];
            sideNavCtrl.sortedCartByTree = [];
            sideNavCtrl.mainColor = hexToRGB((sp.themeConfig && sp.themeConfig.mainColor) || '#207d3f', .2);
            sideNavCtrl.openDeliveryFeePopup = openDeliveryFeePopup;
            sideNavCtrl.openConfirmCVVPopup = openConfirmCVVPopup;
            sideNavCtrl.isEmptyObjectWithDelivery = isEmptyObjectWithDelivery;
            sideNavCtrl.cvvUpdateConfirm = Config.retailer.settings.cvvUpdateConfirm === 'true';
            sideNavCtrl.deliveryCheckoutOrServiceFee = null;
            sideNavCtrl.moneyToPoint = 0;
            sideNavCtrl.loyaltyClubDriver = Loyalty.getRetailerClubDriver();
            sideNavCtrl.isCanUpdateTimeSlot = isCanUpdateTimeSlot;
            sideNavCtrl.isAccumulatedPointsDriver = false;

            if(Config.retailer.loyaltyClubDrivers && Array.isArray(Config.retailer.loyaltyClubDrivers) && Config.retailer.loyaltyClubDrivers.length) {
                var pointsAccumulationDriver = null;
                for(var i = 0; i < Config.retailer.loyaltyClubDrivers.length && !pointsAccumulationDriver; i++) {
                    if (Config.retailer.loyaltyClubDrivers[i] && Config.retailer.loyaltyClubDrivers[i].loyaltyClubDriverId === LOYALTY_CLUB_DRIVERS.ACCUMULATED_POINTS) {
                        pointsAccumulationDriver = Config.retailer.loyaltyClubDrivers[i];
                    }
                }

                if (pointsAccumulationDriver && pointsAccumulationDriver.clientConfig && pointsAccumulationDriver.clientConfig.loyaltyClubs) {
                    var currentLoyaltyClubId = pointsAccumulationDriver.loyaltyClubIds && pointsAccumulationDriver.loyaltyClubIds[0];
                    var pointsAccumulationDriverSettings = currentLoyaltyClubId && pointsAccumulationDriver.clientConfig.loyaltyClubs[currentLoyaltyClubId];
                    if (pointsAccumulationDriverSettings) {
                        if (pointsAccumulationDriverSettings) {
                            sideNavCtrl.isAccumulatedPointsDriver = pointsAccumulationDriverSettings.loyaltyPages.accumulatedPointElementInCartComponent.isActive;
                            sideNavCtrl.currentRootCulture = (localStorage.getItem('frontend') && JSON.parse(localStorage.getItem('frontend')).language) || Config.retailer.culture;
                            sideNavCtrl.accumulatedPointsUnit = pointsAccumulationDriverSettings.loyaltyPages.visibility.tex && pointsAccumulationDriverSettings.loyaltyPages.visibility.tex[sideNavCtrl.currentRootCulture] || '';
                            sideNavCtrl.clubPointIcon = pointsAccumulationDriverSettings.loyaltyPages.visibility.clubPointIcon;
                            sideNavCtrl.moneyToPointRatio = (pointsAccumulationDriverSettings.moneyToPointRatio || 1);
                            sideNavCtrl.componentPointsText = pointsAccumulationDriverSettings.loyaltyPages.accumulatedPointElementInCartComponent.textNames && pointsAccumulationDriverSettings.loyaltyPages.accumulatedPointElementInCartComponent.textNames[sideNavCtrl.currentRootCulture] ||  $filter('translate')('points');
                            sideNavCtrl.accumulatedPointsTextColor = pointsAccumulationDriverSettings.loyaltyPages.accumulatedPointElementInCartComponent.textColor;
                            sideNavCtrl.accumulatedPointsBgColor = pointsAccumulationDriverSettings.loyaltyPages.accumulatedPointElementInCartComponent.backgroundColor;
                        }
                   }
                }
            }

            $rootScope.$on('cart.lines.remove', _onCartLinesRemoved);
            $rootScope.$on('cartCleared', _onCartClear);
            $rootScope.$on('continueShopping', _onContinueShopping);
            $rootScope.$on('checkout', _onClearSortedItemsByCategories);
            $rootScope.$on('logout', _onClearSortedItemsByCategories);
            $rootScope.$on('editOrder.updated', _onClearSortedItemsByCategories);
            $rootScope.$on('store.changed', function () {
                sideNavCtrl.finalPriceForViewWithDeliveryFee = null;
                sideNavCtrl.finalPriceForViewWithDeliveryFee = $rootScope.cart.total.finalPriceForViewWithDeliveryFee;
                _checkForDeliveryServiceOrPickupFee();
            });
            $rootScope.$on('cart.totalCalculated', function () {
                if (!isEmptyObjectWithDelivery($rootScope.cart.lines) && !!$rootScope.cart.total.finalPriceForViewWithDeliveryFee) {
                    sideNavCtrl.finalPriceForViewWithDeliveryFee = $rootScope.cart.total.finalPriceForViewWithDeliveryFee
                }
                if (sideNavCtrl.isAccumulatedPointsDriver) {
                    sideNavCtrl.moneyToPoint = Math.floor(((Util.checkForDeliveryPickupServiceFee(Cart.lines) ? (Cart.total.finalPriceForViewWithDeliveryFee || Cart.total.finalPriceForView) : Cart.total.finalPriceForView) / sideNavCtrl.moneyToPointRatio) * 10) / 10;
                }
                User.getData().then(function (data) {
                    if (!data) return;
                    sideNavCtrl.userLoyaltyClub = Loyalty.getUserLoyaltyClub(data);
                    if (data.loyaltyClubs.length > 0 && sideNavCtrl.loyaltyClubDriver) { 
                        sideNavCtrl.loyaltyClubClientConfig = sideNavCtrl.userLoyaltyClub && sideNavCtrl.userLoyaltyClub.loyaltyClubId && Loyalty.getRetailerClubConfig(sideNavCtrl.userLoyaltyClub.loyaltyClubId);
                        if(sideNavCtrl.loyaltyClubClientConfig && sideNavCtrl.loyaltyClubClientConfig.loyaltyPages && sideNavCtrl.loyaltyClubClientConfig.pointsToCashback
                            && sideNavCtrl.loyaltyClubClientConfig.loyaltyPages.accumulatedPointElementInCartComponent && sideNavCtrl.loyaltyClubClientConfig.loyaltyPages.accumulatedPointElementInCartComponent.isActive){
                            sideNavCtrl.moneyToPoint = Math.floor(((Util.checkForDeliveryPickupServiceFee(Cart.lines) ? (Cart.total.finalPriceForViewWithDeliveryFee || Cart.total.finalPriceForView) : Cart.total.finalPriceForView) / sideNavCtrl.loyaltyClubClientConfig.moneyToPointRatio) * 10) / 10;
                            var pointElement = document.getElementById('loyalty-point');
                            if(pointElement){
                                pointElement.classList.add('point-scale');
                                $timeout(function () {
                                    pointElement.classList.remove('point-scale');
                                }, 600);
                            }
                        }
                    }
                });
            });

            $rootScope.$on('cart.lines.quantityChanged.click', function () {
                _checkForDeliveryServiceOrPickupFee();
            });

            Util.currentScopeListener($scope, $rootScope.$on('changeLanguage', function(culture){
                if (sideNavCtrl.isAccumulatedPointsDriver) {
                    var currentLoyaltyClubId = Config.retailer.loyaltyClubDriver.loyaltyClubIds[0];
                    sideNavCtrl.accumulatedPointsUnit = sideNavCtrl.loyaltyClubDriver.clientConfig.loyaltyClubs[currentLoyaltyClubId].loyaltyPages.visibility.tex[$rootScope.language.culture];
                    sideNavCtrl.componentPointsText = sideNavCtrl.loyaltyClubDriver.clientConfig.loyaltyClubs[currentLoyaltyClubId].loyaltyPages.accumulatedPointElementInCartComponent.textNames[$rootScope.language.culture];
                }
            }));

            Util.currentScopeListener($scope, $rootScope.$on('order.update.timeslot', function (event, data) {
                checkout({
                    isUpdateTimeslot: true,
                    isCancelOnClose: data.isCancelOnClose
                });
            }));

            User.getData();
            sideNavCtrl.isCartClosed = Cart.close();
            _setSpecialRemindersLength();

            if (sideNavCtrl.sortCartByCategories) {
                DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: (sideNavCtrl.isCartClosed ? 'Collapse' : 'Expand') + ' Shopping Cart'}});
            }

            function _onCartLinesRemoved(event, data) {
                if (sideNavCtrl.sortCartByCategories) {
                    sideNavCtrl.sortedCartByTree = Cart.filterCartLineRemoved(sideNavCtrl.sortedCartByCategories, data)
                }
                _checkForDeliveryServiceOrPickupFee()
            }

            function isEmptyObjectWithDelivery (data) {
                if (data) {
                    var keys = Object.keys(data);
                    if (keys.length === 1) {
                        return data[keys[0]].type === SP_SERVICES.CART_LINE_TYPES.DELIVERY;
                    }
                    return keys.length === 0;
                }
                return false;
            }

            function _onCartClear(event, data){
                if (sideNavCtrl.sortCartByCategories) {
                        sideNavCtrl.sortedCartByCategories = [];
                        sideNavCtrl.sortedCartByTree = [];
                        sideNavCtrl.finalPriceForViewWithDeliveryFee = null;
                        _checkForDeliveryServiceOrPickupFee()
                }
                _checkForDeliveryServiceOrPickupFee()
            }

            function _onContinueShopping(event, data){
                _sortItemsByCategoriesOnPageTransition();
            }

            function _checkForDeliveryServiceOrPickupFee() {
                sideNavCtrl.deliveryCheckoutOrServiceFee = Util.checkForDeliveryPickupServiceFee(Cart.lines);
            }


            function _sortItemsByCategoriesOnPageTransition() {
                if (sideNavCtrl.sortCartByCategories) {
                    var sortedCartByCategoriesData = Cart.sortCartByCategories($rootScope.cart.lines, [], false, true)
                    sideNavCtrl.sortedCartByTree = Cart.sortByTree(sortedCartByCategoriesData.sortedObjByCategories);
                }
            }

            function _onClearSortedItemsByCategories() {
                sideNavCtrl.sortedCartByCategories = [];
                sideNavCtrl.sortedCartByTree = [];
            }

            function openDeliveryFeePopup() {
                Dialog.show({
                    controller: 'DeliveryFeeInfoCtrl',
                    templateUrl: 'template/dialogs/delivery-fee-info/index.html',
                    styleClass: 'delivery-fee-info-dialog',
                    bypass: true,
                    locals: {
                        displayText: $rootScope.config.retailer.settings.legalTermsDeliveryText || $filter('translate')('delivery-fee-explain-default'),
                    }
                });
            }

            function toggleCart() {
                sideNavCtrl.isCartClosed = Cart.close();
                Cart.close(!sideNavCtrl.isCartClosed);
                if (window.event) {
                    DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: (sideNavCtrl.isCartClosed ? 'Expand' : 'Collapse') + ' Shopping Cart'}});
                }

                sideNavCtrl.isCartClosed = Cart.close();
                _resizeTimeOut && $timeout.cancel(_resizeTimeOut);
                _resizeTimeOut = $timeout(function () {
                    $rootScope.$emit('resize');
                }, 500);
            }

            function clearCart() {
                Util.openClearCartDialog(Cart);
            }

            function setErrorTooltipPosition(event, top) {
                sideNavCtrl.errorTooltipStyle = {
                    top: (event.currentTarget.getBoundingClientRect().top - top) + 'px',
                }
            }

            function addOrdersHistoryChunk(size) {
                if (angular.isDefined(sideNavCtrl.ordersHistoryTotal) && sideNavCtrl.ordersHistoryTotal == sideNavCtrl.ordersHistory.length) {
                    return $q.reject('Total orders exceeded');
                }

                if (_addOrdersHistoryChunkPromise) {
                    return _addOrdersHistoryChunkPromise.then(function () {
                        return addOrdersHistoryChunk(size);
                    });
                }

                _addOrdersHistoryChunkPromise = Orders.getOrders(sideNavCtrl.ordersHistory.length, size || _ordersHistoryChunkSize, _getLiveResults).then(function (ordersData) {
                    sideNavCtrl.ordersHistory.push.apply(sideNavCtrl.ordersHistory, ordersData.orders);
                    sideNavCtrl.ordersHistoryTotal = ordersData.total;
                    return ordersData;
                }).finally(function () {
                    _getLiveResults = false;
                    _addOrdersHistoryChunkPromise = null;
                });

                return _addOrdersHistoryChunkPromise;
            }

            function clearHistoryOrder() {
                _requestedHistoryOrderId = null;
                delete sideNavCtrl.historyOrder;
            }

            function setHistoryOrder(orderId) {
                if (_requestedHistoryOrderId == orderId) {
                    return $q.resolve();
                }

                clearHistoryOrder();
                sideNavCtrl.historyOrderCheckedLinesTotal = 0;
                _requestedHistoryOrderId = orderId;

                return Orders.getOrderDetails(orderId, {
                    fireAndForgot: true
                }).then(function (resOrder) {
                    if (_requestedHistoryOrderId != orderId) {
                        return;
                    }

                    resOrder.lines = resOrder.lines.filter(function (line) {
                        return line.type == SP_SERVICES.CART_LINE_TYPES.PRODUCT;
                    });
                    sideNavCtrl.historyOrder = resOrder;
                    $rootScope.$emit('sideNav.historyOrder.fetched');
                    checkAllHistoryOrderLines(true);
                });
            }

            function toggleOrdersHistory() {
                if (!User.session.userId) {
                    return Util.openLoginDialog().then(function () {
                        if (!User.session.userId) {
                            return $q.reject('User not logged in');
                        }

                        sideNavCtrl.isOrdersHistoryOpenOnLogin = true;
                        return toggleOrdersHistory();
                    });
                }

                DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Previous Orders In Shopping Cart'}});
                sideNavCtrl.ordersHistoryOpen = !sideNavCtrl.ordersHistoryOpen;
                return $q.resolve();
            }

            function checkAllHistoryOrderLines(isChecked) {
                var _replacementLines = [];
                angular.forEach(sideNavCtrl.historyOrder.lines, function (line) {
                    if (!!line.checked == !!isChecked) {
                        return;
                    }
                    line.isProductOutOfStock = Util.isProductOutOfStock(line);
                    line.isNeedToShowOutOfStockLabel = Util.isNeedToShowOutOfStockLabel(line);
                    if (Cart.isEligibleForReplacementSuggestions(line)) {
                        _replacementLines.push(line);
                    }
                    line.checked = line.isProductOutOfStock ? false : !!isChecked;
                    onCheckHistoryOrderLine(line);
                });
                Cart.setReplacements(_replacementLines);
            }

            function onCheckHistoryOrderLine(line) {
                if (line.isProductOutOfStock || line.isPseudo) {
                    return;
                }
                sideNavCtrl.historyOrderCheckedLinesTotal += line.checked ? 1 : -1;
            }

            function addCheckedHistoryOrderLines() {
                var lines = sideNavCtrl.historyOrder.lines.filter(function (line) {
                    return line.checked && !line.isPseudo;
                });

                var linesWithoutPropertySelected = [];

                angular.forEach(lines, function (line) {
                    if (line.product && line.product.productProperties && line.product.productProperties.length && !line.productPropertyValue) {
                        linesWithoutPropertySelected.push(_productNameFilter(line.product, line.product.isCaseMode));
                    }
                });

                if (linesWithoutPropertySelected.length !== 0) {
                    Util.showCommonDialog({
                        content: '{{\'Product properties need to be selected for\' | translate}}' + ': ' + linesWithoutPropertySelected.join(', '),
                        buttons: [{
                            text: '{{\'View details\' | translate}}',
                            click: 'onViewDetailsClicked()'
                        }, {
                            text: '{{\'Cancel\' | translate}}',
                            click: '$dialog.hide()'
                        }],
                        controller: ['$scope', function ($dialogScope) {
                            $dialogScope.onViewDetailsClicked = function () {
                                Dialog.hide();
                                $state.go('app.singleOrder', {oid: sideNavCtrl.historyOrder.id});
                            };
                        }]
                    });

                    return;
                }

                var isLimitPassed = Cart.checkHeavyLimitBeforeAddLines(lines);
                if (isLimitPassed === false) {
                    if (Cart.lines && Object.keys(Cart.lines).length === 0 &&
                        $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                        Cart.addDeliveryFeeLineIfNeeded();
                    }
                    return _addHistoryOrderLines(lines);
                }
            }

            function _addHistoryOrderLines(lines) {
                if (sideNavCtrl.ordersHistoryOpen) {
                    toggleOrdersHistory();
                }

                var toAdd = Util.getActiveLines(lines.filter(function (line) {
                    return line.type == SP_SERVICES.CART_LINE_TYPES.PRODUCT;
                }));

                var originatedId = sideNavCtrl.historyOrder ? sideNavCtrl.historyOrder.cartId : undefined;
                var isLimitPassed = Cart.checkHeavyLimitBeforeAddLines(toAdd);
                if (isLimitPassed === false) {
                    if (Cart.lines && Object.keys(Cart.lines).length === 0 &&
                        $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                        Cart.addDeliveryFeeLineIfNeeded();
                    }
                    Cart.addLines(toAdd, undefined, undefined, originatedId);
                    return toAdd;
                }
            }

            function addHistoryOrderLine(line) {
                var isLimitPassed = Cart.checkHeavyLimitBeforeAddLine(line);
                if (isLimitPassed === false) {
                    if (Cart.lines && Object.keys(Cart.lines).length === 0 &&
                        $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                        Cart.addDeliveryFeeLineIfNeeded();
                    }
                    DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Add Product from Previous Orders'}});

                    var originatedId = sideNavCtrl.historyOrder ? sideNavCtrl.historyOrder.cartId : undefined;
                    var addedLines = _addHistoryOrderLines([line], originatedId);
                    return addedLines ? addedLines.length && addedLines[0] : false;
                }
            }

            function openOrderProduct(orderLine) {
                //TODO: check why openProduct with the orderLine.product instance triggers digest loop
                orderLine.product && Util.openProduct(angular.copy(orderLine.product));
            }

            function openConfirmCVVPopup() {
            return  Dialog.show({
                  controller: [function(){
                      var cvvConfirmCtrl = this;
                      User.getData(true).then(function (userData) {
                          if (userData.creditCards && cvvConfirmCtrl.creditCards.length !== userData.creditCards.length) {
                              cvvConfirmCtrl.creditCards = userData.creditCards || [];
                              cvvConfirmCtrl.summaryData.creditCard = _getOrderCreditCard(userData.creditCards, Orders.orderInEdit) || cvvConfirmCtrl.creditCards[0];
                          }

                      });
                      cvvConfirmCtrl.creditCards = User.data.creditCards || [];
                      cvvConfirmCtrl.summaryData = {
                          paymentMethod: null,
                          storeCredit: '',
                      creditCard: {},
                      paymentsNumber: 1
                    }
                      cvvConfirmCtrl.showAddCardDialog = function () {
                          Dialog.hide();
                          sideNavCtrl.compiledUserAddress = Util.compiledUserAddress();
                          if (Config.retailer.settings.useDeliveryAddressAsBilling && sideNavCtrl.compiledUserAddress) {

                              return Dialog.show({
                                  templateUrl: 'template/dialogs/use-delivery-address-as-billing/index.html',
                                  controller: ['$scope', function ($dialogScope) {
                                      var dialogCtrl = this;
                                      dialogCtrl.sendUserAddress = false;
                                      dialogCtrl.compiledUserAddress = sideNavCtrl.compiledUserAddress;
                                      sideNavCtrl.compiledUserAddress = Util.compiledUserAddress();
                                      var userAddress = User.data.addresses[0];
                                      var userCountryName = userAddress && userAddress.country
                                      if (userCountryName) {
                                          userAddress.countryCode = Util.getCountryCode(userCountryName);
                                      }
                                      $dialogScope.continue = function () {
                                          Dialog.hide();
                                          return PaymentsService.addCreditCard({
                                              sendUserAddress: dialogCtrl.sendUserAddress,
                                              address: userAddress.text1 ? userAddress.text1 : userAddress.text2 ? userAddress.text2 : '',
                                              city: userAddress.city ? userAddress.city : '',
                                              country: userAddress.country ? userAddress.country : '',
                                              countryCode: userAddress.countryCode ? userAddress.countryCode : '',
                                          }).then(function () {
                                              setTimeout(function () {
                                                  User.getData(true).then(function () {
                                                      checkout();
                                                  });
                                              }, 1000);
                                          });

                                      };
                                  }],
                                  controllerAs: 'dialogCtrl',
                                  styleClass: 'clear-cart-popup'
                              });
                          } else {
                              cvvConfirmCtrl.summaryData.proceedToCheckout = false;
                              cvvConfirmCtrl.summaryData.popupAgain = true;
                              return PaymentsService.addCreditCard().then(function () {
                                  setTimeout(function () {
                                      sideNavCtrl.openConfirmCVVPopup()
                                  }, 1000)
                              });
                          }
                  }
                      var setData = function () {
                          cvvConfirmCtrl.summaryData.creditCard = _getOrderCreditCard(cvvConfirmCtrl.creditCards, Orders.orderInEdit) || cvvConfirmCtrl.creditCards[0];
                          cvvConfirmCtrl.hasCard = true;
                      }
                      cvvConfirmCtrl.closeDialog = function () {
                          Dialog.hide();
                          cvvConfirmCtrl.summaryData.proceedToCheckout = false;
                          cvvConfirmCtrl.summaryData.popupAgain = false;
                          return cvvConfirmCtrl.summmaryData
                      }
                      cvvConfirmCtrl.chooseCreditCard = function (creditCard) {
                          cvvConfirmCtrl.summaryData.proceedToCheckout = true;
                          cvvConfirmCtrl.summaryData.popupAgain = false;
                          cvvConfirmCtrl.summaryData.creditCard = creditCard;
                          cvvConfirmCtrl.summaryData.cvv = '';
                      }
                      cvvConfirmCtrl.confirmUpdate = function () {
                          cvvConfirmCtrl.summaryData.proceedToCheckout = true;
                          cvvConfirmCtrl.summaryData.popupAgain = false;
                          sideNavCtrl.summaryData = cvvConfirmCtrl.summaryData;
                          if (!cvvConfirmCtrl.summaryData.cvv || cvvConfirmCtrl.summaryData.cvv.length < 3) {
                              cvvConfirmCtrl.errorState = {
                                  cvv: 'CVV Should not be empty and should have at least 3 digits'
                              }
                              return
                          } else {
                              return Dialog.hide();
                          }
                      }
                      setData()
                  }],
                  controllerAs: 'cvvConfirmCtrl',
                  templateUrl: 'template/dialogs/cvv-confirm-popup/index.html',
                  styleClass: 'cvv-confirm-popup',
                  bypass: true,
                  disableClosing: true
              })
          }
            /*Edit Order*/
            function editOrderCancel() {
                Util.showCommonDialog({
                    title: '{{\'Cancel Order Update\' | translate}}',
                    content: '{{\'Are you sure you want to cancel your order update and return to your original order\' | translate}} ?',
                    buttons: [{
                        text: '{{\'Cancel Update\' | translate}}',
                        click: '$dialog.hide(true)'
                    }, {
                        text: '{{\'Close\' | translate}}',
                        click: '$dialog.hide()'
                    }]
                }).then(function (is) {
                    if (!is) {
                        return;
                    }

                    DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Cancel Update Order'}});
                    sideNavCtrl.editOrderCancelled = true;
                    Orders.cancelEdit().then(function () {
                        Cart.clear();

                        _cancelOrderTimeOut && $timeout.cancel(_cancelOrderTimeOut);
                        _cancelOrderTimeOut = $timeout(function () {
                            sideNavCtrl.editOrderCancelled = false;
                        }, 4500);
                    });
                });
            }

            function _validateTagSellDate(order){
                function _showIrrelevantSalesDialog(isNewPromotionDesignEnabled, irrelevantSalesLines, lineTypes) {
                    return Dialog.show({
                        templateUrl: isNewPromotionDesignEnabled ? 'template/dialogs/new-irrelevant-sales/index.html' : 'template/dialogs/irrelevant-sales/index.html',
                        styleClass: isNewPromotionDesignEnabled ? 'cart-summary-dialog-new notice-sale-date-new' : 'cart-summary-dialog notice-sale-date',
                        controller: [
                            '$scope',
                            function ($scope) {
                                $scope.lineTypes = lineTypes;
                                $scope.deliveryDate = deliveryDate;
                                $scope.totalPriceUpdate = $rootScope.cart.total.finalPriceForViewPotential || $rootScope.cart.total.finalPriceForView;
                                $scope.irrelevantSellDateObj = {
                                    mainTitle: 'The following products are not available on this chosen time',
                                    subTitle: 'If you continue, they will be removed from your cart',
                                    items: irrelevantSalesLines,
                                    itemTemplateUrl: 'template/dialogs/sell-date/product.html',
                                    className: 'sell-date-dialog'
                                }
                                $scope.displayChoosenDateButton = false;
                                irrelevantSalesLines.forEach(function (line) {
                                    var finalPrice = line.finalPriceForView || line.finalPriceForViewPotential || 0
                                    $scope.totalPriceUpdate -= Number(finalPrice || 0);
                                })
                                $scope.irrelevantSalesCount = 0;// for old Promotion display
                                $scope.displayLines = isNewPromotionDesignEnabled ? [$scope.irrelevantSellDateObj] : $scope.irrelevantSellDateObj;
                            },
                        ],
                    });
                }
                if (order.shippingTimeTo) {
                    var isNewPromotionDesignEnabled = $rootScope.config.retailer.settings.isNewPromotionDesignEnabled === 'true';
                    var irrelevantSalesLines = [];
                    var lineTypes = {};
                    var deliveryDate = new Date(order.shippingTimeTo.split('T')[0]);
                    angular.forEach(Cart.lines || [], function(line) {
                        if(line && !Util.isSellOnDate(line,deliveryDate)){
                            irrelevantSalesLines.push(line);
                            lineTypes[line.type] = true;
                        }
                    });
                    if(irrelevantSalesLines.length) {
                        return _showIrrelevantSalesDialog(isNewPromotionDesignEnabled, irrelevantSalesLines, lineTypes).then(function(rs){
                            if (rs){
                                if (irrelevantSalesLines.length > 0) {
                                    return Cart.removeLines(irrelevantSalesLines).then(function(values){
                                        return Cart.save();
                                    }).catch(function(){
                                        return false;
                                    });
                                } else {
                                    return true;
                                }
                            }
                            return false;
                        });
                    }

                }
                return $q.resolve(true);
            }

            // premium update time slot
            function checkoutV2(options){
                if(!Util.isEnableUpdateTimeSlot()){
                    return checkout(options);
                }

                var selectedTime = {
                    typeId: Orders.orderInEdit.deliveryTimeTypeId,
                    newFrom: Orders.orderInEdit.shippingTimeFrom,
                    newTo: Orders.orderInEdit.shippingTimeTo,
                    daysRange: null,
                }

                var curOrder = {
                  id: Orders.orderInEdit.id,
                  timePlaced: Orders.orderInEdit.timePlaced,
                };

                return Cart.checkIrrelevantSales(selectedTime, curOrder).then(function(irrelevantSales){
                    if(!irrelevantSales){
                        return checkout({
                          isUpdateTimeslot: true,
                        });
                    }

                    Cart.setIrreSalesData(irrelevantSales);

                    return Util.openEditOrderDialog(
                      "IRRE_SALES",
                      false,
                      Orders.orderInEdit.id,
                      irrelevantSales
                    );
                })
            }

            /**
            * checkout
            * @param {Object} [options] - Additional options.
            * @param {boolean} [options.isUpdateTimeslot] - If true, then checkout will be performed without
            *                                              validation of sell date and out of stock lines.
            * @returns {Promise.<Object>}
             */
            function checkout(options) {
                options = options || {};
                if (!User.session.userId) {
                    return Util.openLoginDialog().then(function () {
                        if (!User.session.userId) {
                            return $q.reject('User not logged in');
                        }
                    });
                }

                if (Config.retailer.settings.isExternalLoyaltyClubRegistrationEnabled === 'true' && !User.data.foreignId) {
                    Util.closeUpdateDialog().then(function () { 
                        Util.showLoyaltyIFrameDialog(Config.retailer.settings.externalLoyaltyClubRegistrationUrl);
                    });
                    return $q.reject('foreignIdRequired');
                }

                sideNavCtrl.closeOrderHistory();
                // need to improve and return error message
                // not just resolve empty promise         
                if (!options.isUpdateTimeslot && (!Cart.serverCartId || !Cart.total.actualLines || sideNavCtrl.disableUpdateOrder)) {
                    return $q.resolve();
                }

                var isLimitPassed = Cart.checkHeavyLimitBeforeAddLines([]);
                if (isLimitPassed === false) {
                    $rootScope.$emit('firstCheckout');
                    //== checkout button pressed - show credit limitation popup or proceed to Cart Summary
                    if (!Orders.orderInEdit) {
                        return ShippingTermsPopup.showPopup().then(function (isProceed) {
                            if (!isProceed) {
                                return $q.reject('shippingTermsDeclined');
                            }

                            return Cart.checkUserCreditLimited(false, '');
                        }).then(function (isLimited) {
                            if (!isLimited) {
                                return $state.go('app.cartSummary');
                            }
                        });
                    }

                    sideNavCtrl.disableUpdateOrder = true;

                    var currentOrder = null;
                    return Cart.checkUserCreditLimited(false, '', true).then(function (isLimited) {
                        if (isLimited) {
                            return $q.reject('userCreditLimited');
                        }

                        return Orders.getOrderDetails(Orders.orderInEdit.id, {fireAndForgot: true});
                    }).then(function (order) {
                        if (!order) {
                            return $q.resolve();
                        }
                        // if(order.isCustomerEditBlocked){
                        //     return $q.reject('userCreditLimited');
                        // }
                        currentOrder = order;

                        if (!Util.isOrderEditable(order) || order.isCustomerEditBlocked) {
                            if (!Util.isOrderEditable(order) && sideNavCtrl.ordersService.orderInEdit) {
                                return Util.closeUpdateDialog().then(function () {
                                    return _editOrderDialog(order);
                                })
                            };
                        }

                        if (Cart.total.lines === 0) {
                            return Util.closeUpdateDialog().then(function(){
                                Util.showCommonDialog('{{\'Your cart is empty\' | translate}}.')
                            });
                        }

                        if(options && options.isUpdateTimeslot) {
                            return true;
                        } 

                        return _validateTagSellDate(order);
                    }).then(function (isContinueToNextTab) {
                        // $q.reject(isContinueToNextTab);
                        if(!isContinueToNextTab) {
                            return $q.reject(isContinueToNextTab);
                        }

                        if(options && options.isUpdateTimeslot) {
                            return Config.initPromise;
                        }

                        if (Cart.outOfStockLines && Cart.outOfStockLines.length) {
                            return Util.openOutOfStockDialog(Cart.outOfStockLines).then(function (result) {
                                return !result || !result.isConfirmed ? $q.reject('Out of stock not confirmed') : Config.initPromise;
                            });
                        }

                        return Config.initPromise;
                    }).then(function () {
                        var remainingCharge = _roundCurrencyFilter(Cart.total.finalPriceWithTax +
                                Cart.total.serviceFee.finalPriceWithTax + Cart.total.deliveryCost.finalPriceWithTax) -
                            currentOrder.initialCheckoutCharge;
                        if (Util.isPaymentAmountRemaining(currentOrder) || Util.isAdditionalPaymentRequired(currentOrder)) {
                            return Util.closeUpdateDialog().then(function(){ return _additionalPaymentDialog(remainingCharge) });
                        } else {
                            var currentArea = _getCurrentArea() || {},
                                minimumOrderPrice = (!!User.data.personalMinOrderLimit || User.data.personalMinOrderLimit === 0) ?
                                    User.data.personalMinOrderLimit : (Number(currentArea.deliveryMinimumCost) || Number(Config.retailer.settings.minimumOrderPrice));
                            return Util.validateMinimumCost(minimumOrderPrice, Config.retailer.notIncludeSpecials, currentArea.deliveryMinimumCost && currentArea.name)
                                .then(function () {
                                    //var isEBTEligibleAddition = Cart.checkEBTEligibleCartChange(currentOrder.lines);
                                    if (currentOrder.paymentData && currentOrder.paymentData.ebtPayment) {
                                        return Util.closeUpdateDialog(2000).then(function(){ return $state.go('app.update-order.ebt-payment') }) ;
                                    }
                                    var  shouldUse3DSOnUpdate =  Config.retailer.settings.creditCard3DSModes && Config.retailer.settings.creditCard3DSModes.map(function(value) {return +value});
                                    if(
                                      sideNavCtrl.cvvUpdateConfirm &&
                                      !shouldUse3DSOnUpdate &&
                                      currentOrder.paymentData &&
                                      currentOrder.paymentData.mainPayment &&
                                      currentOrder.paymentData.mainPayment.paymentMethodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD
                                    ){
                                    Util.closeUpdateDialog().then(function(){openConfirmCVVPopup()
                                      .then(function(){
                                        var summaryData = sideNavCtrl.summaryData;
                                      
                                        if(summaryData){
                                          if(summaryData.proceedToCheckout && !summaryData.popupAgain){
                                            return _checkoutUpdate(summaryData);
                                          }
                                          else if(!summaryData.proceedToCheckout && summaryData.summaryData.popupAgain){
                                            openConfirmCVVPopup()
                                          }
                                        }

                                      })});
                                    } else if (shouldUse3DSOnUpdate &&
                                        sideNavCtrl.cvvUpdateConfirm &&
                                        currentOrder.paymentData &&
                                        currentOrder.paymentData.mainPayment &&
                                        currentOrder.paymentData.mainPayment.paymentMethodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD) {
                                    Util.closeUpdateDialog().then(function(){closeopenConfirmCVVPopup()
                                      .then(function(){
                                        var summaryData = sideNavCtrl.summaryData;
                                        if(summaryData){
                                          if(summaryData.proceedToCheckout && !summaryData.popupAgain){
                                            PaymentsService.payWithCreditCard({
                                              creditCardId: (summaryData.creditCard || {}).id,
                                              cvv: summaryData.cvv,
                                              action: 'checkout',
                                            }).then(function(paymentData) {
                                              summaryData.cvvConfirmUpdate = true;
                                              if(!paymentData){
                                                return
                                              }
                                              var payment = {
                                                cvv: summaryData.cvv,
                                                methodId: SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD,
                                                typeId: SP_PAYMENTS.PAYMENT_TYPES.CREDIT,
                                                token: paymentData.paymentToken,
                                                authNum: paymentData.authNum,
                                                preAuthWithStaticAmount: paymentData.preAuthWithStaticAmount,
                                                preAuthStaticAmount: paymentData.preAuthStaticAmount,
                                                creditCardId: summaryData.creditCard && summaryData.creditCard.id,
                                                preAuthAmount: !sideNavCtrl.isEmptyObjectWithDelivery($rootScope.cart.lines) ?
                                                  ($rootScope.cart.total.finalPriceForViewWithDeliveryFee || $rootScope.cart.total.finalPriceForView) :
                                                  ($rootScope.cart.total.finalPriceForView)
                                                }
                                              summaryData.payment = payment;
                                              summaryData.orderId = currentOrder.id;
                                              return _checkoutUpdate(summaryData, true);
                                            }).catch(function (err) {
                                              if (err.statusCode === 409 && err.response &&
                                                err.response.error === 'Already checked out' && err.response.orderId) {
                                                  return {actionResponse: {id: err.response.orderId}};
                                              } else {
                                                throw err;
                                              }
                                            });
                                          }
                                          else if(!summaryData.proceedToCheckout && summaryData.summaryData.popupAgain){
                                            openConfirmCVVPopup()
                                          }
                                        }
                                      })})
                                    } else {
                                        return _checkoutUpdate();
                                    }
                                });
                        }
                    }).catch(function (err) {
                        if (err !== 'userCreditLimited') {
                            return $q.reject(err);
                        }
                    }).finally(function () {
                        sideNavCtrl.disableUpdateOrder = false;
                    });
                }
            }

            function _checkoutUpdate(options, goToUpdateOrderFinish) {
                var localStorageTimeSlotDataId = 'updateTimeSlot-orderId-' + Orders.orderInEdit.id + '-cartId-' + Cart.serverCartId;
                var timeSlotData = Util.getNewTimeSlotData(Orders.orderInEdit.id, Cart.serverCartId);

                return Api.request({
                    method: 'POST',
                    url: '/v2/retailers/:rid/branches/:bid/users/:uid/_checkoutUpdate',
                    data: {
                        newCartId: Cart.serverCartId,
                        orderId: Orders.orderInEdit.id,
                        skipActiveProductsValidation: true,
                        creditCard: options && options.creditCard || {},
                        cvv: options && options.cvv,
                        cvvConfirmUpdate: options && options.cvvConfirmUpdate || false,
                        payment: options && options.payment || {},
                        newAreaDeliveryTimeId: timeSlotData && timeSlotData.newAreaDeliveryTimeId,
                        newDate: timeSlotData && timeSlotData.newDate
                    }
                }).then(function () {
                    Orders.getOrderDetails(Orders.orderInEdit.id).then(function (order) {
                        $rootScope.$emit('order.update.refresh', {
                            order: order
                        });
                    });

                    // just temporary solution
                    // we should make it better
                    if(timeSlotData) {
                        var isUpdateDialogOpenning = !!document.querySelector('.update-order-v2');
                    
                        if(!isUpdateDialogOpenning) {
                            Util.openEditOrderDialog("FINISH", false, Orders.orderInEdit.id);
                        } else {
                            $rootScope.$emit('order.update.timeslot.finish');
                        }
                    }
                    
                    sideNavCtrl.updatedOrder = Orders.orderInEdit;
                    $rootScope.orderInEdit = Orders.orderInEdit
                    //Google analytics listener.
                    //$rootScope.$emit('checkout', order);
                    Orders.orderInEdit = null;
                    Cart.clear(true);
                    // $rootScope.$emit('editOrder.updated');

                    //== refreshing User's Credit data after order update
                    if ($rootScope.isCreditCustomer) {
                        User.getData(true);
                    }
                    if(options.orderId){
                        Orders.getOrderDetails(options.orderId).then(function (order) {
                            if (order) {
                                if (goToUpdateOrderFinish) {
                                    $state.go('app.update-order.finish', { order: order });
                                }
                                $rootScope.$emit('updateOrderPrice.event', {
                                    orderId: order.id,
                                    price: order.totalAmount
                                });
                            }
                        })
                    }
                }).catch(function (data) {
                    if (data.statusCode !== 400) {
                        Orders.cancelEdit().then(function () {
                            Cart.clear();
                        }).catch(function () {
                            Orders.orderInEdit = null;
                            Cart.clear(true);
                        });
                    }

                    if (data.statusCode !== 300) {
                        if (data.response && data.response.error) {
                            //== when Credit customer is limited we need to display a custom popup with remaining sum
                            if (data.response.code === 'creditCustomerLimited') {
                                return Cart.checkUserCreditLimited(true, '');
                            } else {
                                Util.closeUpdateDialog().then(function () {
                                    Util.showCommonDialog({
                                        title: '{{\'Something Went Wrong\' | translate}}...',
                                        content: '{{\'Update order failed\' | translate}}.<br/>{{\'' + data.response.error.replace(/'/g, '\\\'') + '\' | translate}}'
                                    });
                                });
                            }
                        }
                    }
                }).finally(function () {
                    LocalStorage.removeItem(localStorageTimeSlotDataId);
                });
            }

            function toggleSpecialReminders() {
                sideNavCtrl.isSpecialRemindersExpanded = !sideNavCtrl.isSpecialRemindersExpanded;

                if (sideNavCtrl.isSpecialRemindersExpanded) {
                    DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Expand Cart Special Reminders'}});
                }
            }

            function _getCurrentArea() {
                if (!Config.branchAreaId) {
                    return;
                }

                for (var i = 0; i < Config.branch.areas.length; i++) {
                    if (Config.branch.areas[i].id == Config.branchAreaId) {
                        return Config.branch.areas[i];
                    }
                }
            }

            function _additionalPaymentDialog(remainingCharge) {
                return Util.showCommonDialog({
                    disableClosing: true,
                    title: '<div style="font-size: 1em; padding: 0 2em">{{\'In order to complete the\' | translate}}<br/>{{\'order update, an additional\' | translate}}<br/>{{\'payment is needed\' | translate}}</div>',
                    content: '<div style="font-size: 0.9em; padding-top: 2em; padding-bottom: 2em">{{\'Remaining balance\' | translate}} {{ remainingCharge | currency }}.</div>',
                    controller: ['$scope', '$state', function ($scope, $state) {
                        $scope.contentTitle = 'In order to complete the order update, an additional payment is needed';
                        $scope.contentFooter = 'Remaining balance:';
                        $scope.remainingCharge = remainingCharge;
                        $scope.$state = $state;
                    }],
                    buttons: [{
                        text: '{{\'Cancel\' | translate}}',
                        click: '$dialog.hide()'
                    }, {
                        text: '{{\'Proceed to payment\' | translate}}',
                        click: '$state.go(\'app.update-order.payment\');$dialog.hide()'
                    }]
                });
            }

            function _editOrderDialog(order) {
                var contentHeader = '',
                    contentFooter = '',
                    contentTitle = '';

                if (Util.isOrderInStatuses(order, ORDER_STATUS_STAGES.IN_PROCESS) || order.isCustomerEditBlocked) {
                    contentHeader = 'We have started collecting your order.';
                    contentTitle = 'Your order cannot be changed at this time';
                } else if (Util.isOrderInStatuses(order, ORDER_STATUS_STAGES.READY)) {
                    contentHeader = 'We have finished collecting your order.';
                } else if (Util.isOrderInStatuses(order, ORDER_STATUS_STAGES.FINISH)) {
                    contentHeader = 'We have finished collecting your order.';
                } else if (Util.isOrderInStatuses(order, ORDER_STATUS_STAGES.CANCELLED)) {
                    contentHeader = 'This is a canceled order.';
                    contentTitle = 'Your order can no longer be changed';
                }

                return Util.showCommonDialog({
                    disableClosing: true,
                    title: '{{\'Edit Order\' | translate}}',
                    content: '<div style="font-size: 0.9em;">{{contentHeader | translate}}<br/>{{contentTitle | translate}}.<br/>{{contentFooter | translate}}.</div>',
                    controller: ['$scope', function ($scope) {
                        $scope.contentHeader = contentHeader;
                        $scope.contentTitle = contentTitle;
                        $scope.contentFooter = contentFooter;
                    }],
                    buttons: [{
                        text: '{{\'Cancel Update\' | translate}}',
                        click: '$dialog.hide()'
                    }]
                }).then(function (is) {
                    $rootScope.$emit('editOrder.reset');

                    return Orders.cancelEdit().then(function () {
                        if (is) {
                            return;
                        }
                        Cart.clear();
                    });
                });
            }

            function _getOrderCreditCard(userCards, order) {
                var userCard = null;
                if (userCards.length && order.paymentData && order.paymentData.mainPayment && order.paymentData.mainPayment.lastFourDigits) {
                    userCard = userCards.find(function (card) {
                        return card.lastFourDigits === order.paymentData.mainPayment.lastFourDigits;
                    })
                }
                return userCard ? userCard : userCards.length && userCards[0];
            }

            function checkLineLoyaltyGift(line) {
                if (line.gifts && line.gifts.length) {
                    for (var i = 0; i < line.gifts.length; i++) {
                        var promotion = line.gifts[i].promotion;

                        if (promotion.loyalty && promotion.loyalty.length) {
                            return true;
                        }
                    }
                    return false;
                }
            }

            function _setSpecialRemindersLength() {
                var specialRemindersLength = Object.keys(Cart.specialReminders && Cart.specialReminders.inCart || {}).length;
                if (sideNavCtrl.specialRemindersLength !== undefined && sideNavCtrl.specialRemindersLength !== specialRemindersLength && specialRemindersLength) {
                    sideNavCtrl.scrollToEnd();
                }

                sideNavCtrl.specialRemindersLength = specialRemindersLength;
            }

            function deliveryNoAvailableSlotsPopup() {
                Util.showDeliveryNoAvailableSlotsPopup(true);
            }

            function goToSmartList() {
                $state.go('app.smartList');
            }

            function hexToRGB(hex, alpha) {
                var r = parseInt(hex.slice(1, 3), 16),
                    g = parseInt(hex.slice(3, 5), 16),
                    b = parseInt(hex.slice(5, 7), 16);

                if (alpha) {
                    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
                } else {
                    return "rgb(" + r + ", " + g + ", " + b + ")";
                }
            }
            function isCanUpdateTimeSlot(order) {
                if (!order) {
                    return false;
                }

                return Cart.total.actualLines && !sideNavCtrl.disableUpdateOrder && Util.checkCanUpdateTimeSlot(order);
            }

            Util.currentScopeListener($scope, $rootScope.$on('editOrder.polling', function (event, data) {
                if (!Util.isOrderEditable(data) && sideNavCtrl.ordersService.orderInEdit) {
                    _editOrderDialog(data);
                }
            }));

            Util.currentScopeListener($scope, $rootScope.$on('logout', function () {
                sideNavCtrl.ordersHistory.splice(0);
                delete sideNavCtrl.ordersHistoryTotal;
                sideNavCtrl.ordersHistoryOpen = false;
                $rootScope.isCreditCustomer = false;
                Orders.orderInEdit = null;
                Cart.clear(true);
                sideNavCtrl.moneyToPoint = 0;
            }));

            Util.currentScopeListener($scope, $rootScope.$on('orderEdit', function () {
                if (Cart.close()) {
                    sideNavCtrl.toggleCart();
                }
            }));

            Util.currentScopeListener($scope, $rootScope.$on('cart.lines.add', function () {
                sideNavCtrl.updatedOrder = null;

                if (!Util.specialDetailsDialogShown) {
                    sideNavCtrl.isSpecialRemindersExpanded = false;
                }
                _checkForDeliveryServiceOrPickupFee()
            }));

            Util.currentScopeListener($scope, $rootScope.$on('cart.update.complete', _setSpecialRemindersLength));

            Util.currentScopeListener($scope, $rootScope.$on('checkout', function () {
                sideNavCtrl.ordersHistoryTotal++;
                sideNavCtrl.ordersHistory = [];
                _getLiveResults = true;
            }));

            //== If Cart data already received we don't need to subscribe to event, this event already fired
            if (Cart.sendingSucceeded) {
                sideNavCtrl.cartIsEmpty = Cart.total.actualLines === 0;
            } else {
                Util.currentScopeListener($scope, $rootScope.$on('cart.update.complete', function () {
                    sideNavCtrl.cartIsEmpty = Cart.total.actualLines === 0;
                }));
            }

            if (Config.retailer.settings.isNewPromotionDesignEnabled === 'true') {
                $rootScope.$on('cart.update.complete', _hidePromotionTooltip);
                $rootScope.$on('config.language.change', _hidePromotionTooltip);

                function _hidePromotionTooltip() {
                    $timeout(function () {
                        var cartNav = angular.element(document.querySelector('div.sidenav > div > nav.cart') || document.querySelector('div.sidenav > section.cart')),
                            content = angular.element(cartNav[0].querySelector('.content-wrapper > .content') || cartNav[0].querySelector('section.collapsible')),
                            products = angular.element(content[0].querySelector('.products-wrapper') || content[0].querySelector('section.cart'));

                        products.bind("scroll", function () {
                            if ($rootScope.promotionTooltip) {
                                var promotionTooltip = document.getElementById($rootScope.promotionTooltip);
                                if (promotionTooltip && promotionTooltip.classList.contains('cart')) {
                                    promotionTooltip.style.display = 'none';
                                    $rootScope.promotionTooltip = null;
                                    $timeout(function () {
                                        promotionTooltip.style.display = 'block';
                                    });
                                }
                            }
                        });
                    });
                }
            }
        }])
        // TODO: move to prutah template
        .controller('SidenavPrutahTemplateCtrl', [
            '$scope', '$rootScope', '$q', '$timeout', 'LocalStorage', 'Config', 'Util', 'User', 'Cart', 'SpDeliveryTimesService', 'DELIVERY_TIMES_TYPES', 'SP_SERVICES', 'ChooseAreaDialog', 'DataLayer', 'SidenavService',
            function ($scope, $rootScope, $q, $timeout, LocalStorageService, Config, Util, User, Cart, SpDeliveryTimesService, DELIVERY_TIMES_TYPES, SP_SERVICES, ChooseAreaDialog, DataLayer, SidenavService) {
                var sideNavCtrl = $scope.sideNavCtrl,
                    _mouseMoveTimeout = null,
                    _nextDeliveryTimeOut = null,
                    _isSideNavShown = true,
                    _closeHistoryOrderTimeout,
                    _ordersHistoryOpenTimeOut,
                    _baseToggleCart = sideNavCtrl.toggleCart,
                    _baseToggleOrdersHistory = sideNavCtrl.toggleOrdersHistory,
                    _baseAddCheckedHistoryOrderLines = sideNavCtrl.addCheckedHistoryOrderLines,
                    _baseAddHistoryOrderLine = sideNavCtrl.addHistoryOrderLine,
                    _affectedData,
                    _previousOrderId = null,
                    _sortedCartByCategoriesData;

                sideNavCtrl.scrollToEnd = scrollToEnd;
                sideNavCtrl.toggleCart = toggleCart;
                sideNavCtrl.openHistoryOrder = openHistoryOrder;
                sideNavCtrl.closeOrderHistory = closeOrderHistory;
                sideNavCtrl.toggleOrdersHistory = toggleOrdersHistory;
                sideNavCtrl.addCheckedHistoryOrderLines = addCheckedHistoryOrderLines;
                sideNavCtrl.addHistoryOrderLine = addHistoryOrderLine;
                sideNavCtrl.ordersHistoryCarouselLoadMore = ordersHistoryCarouselLoadMore;
                sideNavCtrl.toggleNextDeliveryMoreDetails = toggleNextDeliveryMoreDetails;
                sideNavCtrl.setCurrentMoreSlots = setCurrentMoreSlots;
                sideNavCtrl.ordersHistoryCarouselArray = [];
                sideNavCtrl.nextDelivery = {};
                sideNavCtrl.ordersHistoryCrouselFunctions = {
                    openHistoryOrder: openHistoryOrder,
                    closeOrderHistory: closeOrderHistory
                };
                sideNavCtrl.scrollToAffectedLine = scrollToAffectedLine;
                sideNavCtrl.toggleProductActions = toggleProductActions;
                sideNavCtrl.reloadCart = reloadCart;

                _openOrdersHistoryOnLogin();
                // _getNextDeliveryArea();

                if ($rootScope.windowSize.width < 1280 && !Cart.close() && !LocalStorageService.isExist('cartClosed')) {
                    sideNavCtrl.toggleCart();
                }

                $rootScope.$on('continueShopping', function (event, data) {
                        // must be done in next call-stack
                        setTimeout(function () {
                            _getNextDeliveryArea(true);
                        }, 0)
                });

                $rootScope.$on('cart.lines.add', _sortItemsByCategories);

                $rootScope.$on('suggestions.opened', function(event, data) {
                    if (sideNavCtrl.orderHistoryOpen) {
                        closeOrderHistory();
                        $rootScope.triggerSuggestionsClose = true;
                    }
                });

                $rootScope.$on('suggestions.closed', function(event, data) {
                    if (_previousOrderId) {
                        openHistoryOrder(_previousOrderId);
                    }
                });

                function _sortItemsByCategories(event, data) {
                    if (sideNavCtrl.sortCartByCategories) {
                        _affectedData = data;
                        _sortedCartByCategoriesData = Cart.sortCartByCategories($rootScope.cart.lines, sideNavCtrl.sortedCartByCategories, data, true)
                        sideNavCtrl.sortedCartByCategories = _sortedCartByCategoriesData.sortedObjByCategories;
                        sideNavCtrl.sortedCartByTree = Cart.sortByTree(sideNavCtrl.sortedCartByCategories);
                    }
                }

                function scrollToEnd() {
                    $timeout(function () {
                        var products = document.querySelector('section.products-wrapper'),
                            lastItem = document.querySelector('section.products-wrapper > div.products > div.product-in-cart:last-child');
                        if (products && lastItem) {
                            if (!lastItem.clientHeight) {
                                scrollToEnd();
                            }
                            products.scrollTop = products.scrollHeight - products.clientHeight;
                        }
                    }, 0, false);
                }

                function toggleCart() {
                    if (sideNavCtrl.ordersHistoryOpen) {
                        closeOrderHistory();
                    }

                    _baseToggleCart();
                    !Cart.close() && _getNextDeliveryArea();
                }

                function openHistoryOrder(orderId, halfOpen) {
                    _previousOrderId = orderId;
                    if (sideNavCtrl.orderHistoryOpen && halfOpen) {
                        return;
                    }

                    if (halfOpen) {
                        sideNavCtrl.orderHistoryHalfOpen = true;
                    } else {
                        sideNavCtrl.orderHistoryOpen = true;
                    }

                    $rootScope.navOrderHistoryOpen = !!sideNavCtrl.orderHistoryOpen;
                    angular.element(document.querySelector('html')).addClass('sidenav-history-order-shown');
                    _closeHistoryOrderTimeout && $timeout.cancel(_closeHistoryOrderTimeout);

                    return sideNavCtrl.setHistoryOrder(orderId);
                }

                function closeOrderHistory(closeHalf) {
                    if (closeHalf) {
                        sideNavCtrl.orderHistoryHalfOpen = false;
                    } else {
                        sideNavCtrl.orderHistoryOpen = false;
                    }
                    $rootScope.navOrderHistoryOpen = !!sideNavCtrl.orderHistoryOpen;

                    if (sideNavCtrl.orderHistoryOpen && closeHalf) {
                        return;
                    }

                    angular.element(document.querySelector('html')).removeClass('sidenav-history-order-shown');
                    _closeHistoryOrderTimeout && $timeout.cancel(_closeHistoryOrderTimeout);
                    _closeHistoryOrderTimeout = $timeout(function () {
                        sideNavCtrl.clearHistoryOrder();
                    }, 400);
                    $rootScope.triggerSuggestionsClose = false;
                }

                function addCheckedHistoryOrderLines() {
                    var addedLines = _baseAddCheckedHistoryOrderLines();
                    _scrollToLine(addedLines && addedLines[0]);
                    return addedLines;
                }

                function addHistoryOrderLine(line) {
                    var addedLine = _baseAddHistoryOrderLine(line);
                    _scrollToLine(addedLine);
                    return addedLine;
                }

                function ordersHistoryCarouselLoadMore(from, size, callback) {
                    return sideNavCtrl.addOrdersHistoryChunk(size).then(function (ordersData) {
                        callback(null, ordersData.total, ordersData.orders);
                        _setOrdersHistoryHeight();
                        return ordersData;
                    }).catch(callback);
                }

                function toggleOrdersHistory() {
                    var isLoggedIn = !!User.session.userId;
                    return _baseToggleOrdersHistory().then(function () {
                        _setOrdersHistoryHeight();

                        if (sideNavCtrl.updatedOrder) {
                            sideNavCtrl.updatedOrder = null;
                        }
                    });
                }

                function toggleNextDeliveryMoreDetails() {
                    DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Next Delivery List'}});
                    sideNavCtrl.nextDelivery.showMoreDetails = !sideNavCtrl.nextDelivery.showMoreDetails;
                    if (sideNavCtrl.nextDelivery.showMoreDetails) {
                        angular.element(document.body || document.querySelector('body')).bind('mousedown', _clickOnBodyToCloseMoreDetails);
                    } else {
                        angular.element(document.body || document.querySelector('body')).unbind('mousedown', _clickOnBodyToCloseMoreDetails);
                    }
                }

                function _scrollToLine(line) {
                    if (!line) {
                        return;
                    }

                    var productsWrapper = document.querySelector('div.sidenav > div > nav.cart .content-wrapper > .content .products-wrapper'),
                        productElement = document.getElementById('sidenav_line_' + Cart.keyByLine(line));
                    if (productsWrapper && productElement) {
                        productsWrapper.scrollTop = productElement.offsetTop - productsWrapper.offsetTop;
                    }
                }

                function _openOrdersHistoryOnLogin() {
                    if (!User.session.userId || Cart.total.actualLines || sideNavCtrl.ordersHistoryOpen) {
                        return;
                    }

                    _resetCarousel();
                    toggleOrdersHistory();
                }

                function _closeOrderHistoryOnLogout() {
                    sideNavCtrl.ordersHistoryOpen = false;
                    sideNavCtrl.orderHistoryOpen = false;
                }

                function _setOrdersHistoryHeight() {
                    sideNavCtrl.ordersHistoryOpen = !!sideNavCtrl.ordersHistory.length ? sideNavCtrl.ordersHistoryOpen : false;

                    _ordersHistoryOpenTimeOut && $timeout.cancel(_ordersHistoryOpenTimeOut);
                    _ordersHistoryOpenTimeOut = $timeout(function () {
                        var cartNav = angular.element(document.querySelector('div.sidenav > div > nav.cart')),
                            content = angular.element(cartNav[0].querySelector('.content-wrapper > .content')),
                            products = angular.element(content[0].querySelector('.products-wrapper')),
                            emptyCart = angular.element(content[0].querySelector('.empty-cart')),
                            ordersHistory = angular.element(content[0].querySelector('.orders-history'));

                        if (sideNavCtrl.ordersHistoryOpen) {
                            var productsHeight = products[0].querySelector('.products').offsetHeight || emptyCart[0].querySelector('.middle').offsetHeight,
                                contentHeight = content.prop('offsetHeight'),
                                productsMargin = contentHeight - productsHeight;
                            productsMargin = ordersHistory.prop('offsetHeight') - (productsMargin < 0 ? 0 : productsMargin);
                            products.css({
                                'margin-top': '-' + (productsMargin < 0 ? 0 : productsMargin) + 'px',
                                'height': (productsHeight > contentHeight ? contentHeight : productsHeight) + 'px'
                            });
                            emptyCart.css({
                                'margin-top': '-' + (productsMargin < 0 ? 0 : productsMargin) + 'px',
                                'height': (productsHeight > contentHeight ? contentHeight : productsHeight) + 'px'
                            });
                            cartNav.addClass('orders-history-open');
                        } else {
                            products.css({'margin-top': '', 'height': ''});
                            emptyCart.css({'margin-top': '', 'height': ''});
                            cartNav.removeClass('orders-history-open');
                        }
                    });
                }

                function _resetCarousel() {
                    angular.isFunction(sideNavCtrl.ordersHistoryCrouselFunctions.reset) && sideNavCtrl.ordersHistoryCrouselFunctions.reset();
                }

                function _clickOnBodyToCloseMoreDetails(event) {
                    var target = angular.element(event.target || event.srcElement || event.path[0]);
                    while (target.length && !target.hasClass('next-delivery-wrapper')) {
                        target = angular.element(target.prop('parentNode') || target.prop('parentElement'));
                    }
                    if (target.hasClass('next-delivery-wrapper')) {
                        return;
                    }

                    toggleNextDeliveryMoreDetails();
                    $scope.$applyAsync();
                }

                function setCurrentMoreSlots(areasSlots, deliveryTypeId) {
                    SpDeliveryTimesService.setNextDeliveryCurrentSlots(sideNavCtrl.nextDelivery, areasSlots, deliveryTypeId);
                    sideNavCtrl.selectedDeliveryType = deliveryTypeId;
                }

                function _getNextDeliveryArea(cancelTimeout) {
                    if (cancelTimeout && !!_mouseMoveTimeout) {
                        $timeout.cancel(_mouseMoveTimeout);
                        _mouseMoveTimeout = null;
                    }

                    if (!!_mouseMoveTimeout || Cart.close() || !_isSideNavShown) {
                        return;
                    }

                    Config.initPromise.then(function () {
                        return SpDeliveryTimesService.getNextDeliveryTimes(sideNavCtrl.nextDelivery, _getDeliveryTimesByUserAddress, Config.getBranchArea(), Cart.serverCartId);
                    }).then(function (result) {
                        Config.deliveryAreaPrice = result.area.deliveryAreaPrice;
                        if (!result.nextTime ||
                            (!result.nextTime.isToday && result.nextTime.type !== DELIVERY_TIMES_TYPES.DELIVERY_WITHIN_HOURS)) {
                            Util.showNoDeliveriesTodayPopup();
                        }

                        $rootScope.noAvailableDeliverySlots = result.noAvailableDeliverySlots; //== only false value, NOT null or undefined
                    }).catch(function () {
                        if (sideNavCtrl.nextDelivery.times) {
                            sideNavCtrl.nextDelivery.times.splice(0);
                        }
                    }).finally(function () {
                        sideNavCtrl.nextDelivery.loading = false;
                        // Cart.init();
                        if (sideNavCtrl.nextDelivery.moreSlotsTypes && sideNavCtrl.nextDelivery.moreSlotsTypes.length) {
                            setCurrentMoreSlots(sideNavCtrl.nextDelivery.moreSlots[sideNavCtrl.nextDelivery.currentNextDeliveryTypeId], sideNavCtrl.nextDelivery.currentNextDeliveryTypeId);
                        }
                    });

                    _mouseMoveTimeout = $timeout(function () {
                        angular.element(document.body || document.querySelector('body')).bind('mousemove', _nextDeliveryOnMouseMove);
                    }, 240000); // 4 minutes

                    // Cart.init();
                }

                function _getDeliveryTimesByUserAddress() {
                    return User.getData().then(function (data) {
                        var address = data.addresses[0];
                        if (!address || !address.text1 || !address.city) {
                            return $q.reject('No Address');
                        }

                        if (Config.isZipCodeArea && Config.area && !address.zipCode) {
                            address.zipCode = Config.area;
                        }

                        return {
                            address: {
                                text1: address.text1,
                                street: address.street,
                                houseNumber: address.houseNumber,
                                entry: address.entry,
                                city: address.city,
                                zipCode: address.zipCode
                            }
                        };
                    });
                }

                function _nextDeliveryOnMouseMove() {
                    _mouseMoveTimeout = null;
                    angular.element(document.body || document.querySelector('body')).unbind('mousemove', _nextDeliveryOnMouseMove);
                    _getNextDeliveryArea();
                }

                $rootScope.$on('$stateChangeStart', function () {
                    if (sideNavCtrl.ordersHistoryOpen) {
                        closeOrderHistory();
                    }
                });
                Util.currentScopeListener($scope, $rootScope.$on('cart.lines.add', function () {
                    if (sideNavCtrl.ordersHistoryOpen) {
                        toggleOrdersHistory();
                    }
                    scrollToEnd();
                }));
                Util.currentScopeListener($scope, $rootScope.$on('login', function () {
                    _openOrdersHistoryOnLogin();
                    _getNextDeliveryArea(true);
                }));
                Util.currentScopeListener($scope, $rootScope.$on('logout', function () {
                    _setOrdersHistoryHeight();
                    _getNextDeliveryArea(true);
                    _closeOrderHistoryOnLogout();
                }));
                Util.currentScopeListener($scope, $rootScope.$on('resize', _setOrdersHistoryHeight));
                Util.currentScopeListener($scope, $rootScope.$on('sidenav.show', function () {
                    _isSideNavShown = true;
                    _setOrdersHistoryHeight();
                    _getNextDeliveryArea();
                }));
                Util.currentScopeListener($scope, $rootScope.$on('sidenav.hide', function () {
                    _isSideNavShown = false;
                }));
                Util.currentScopeListener($scope, $rootScope.$on('cart.lines.remove', _setOrdersHistoryHeight));
                Util.currentScopeListener($scope, $rootScope.$on('cart.lines.add', _setOrdersHistoryHeight));
                Util.currentScopeListener($scope, $rootScope.$on('checkout', _resetCarousel));
                Util.currentScopeListener($scope, $rootScope.$on('config.branchAreaId.change', function () {
                    _getNextDeliveryArea(true);
                }));

                function scrollToAffectedLine(lineId, scrollableWrapperSelector) {
                    var params = {
                        lineId: lineId,
                        affectedData: _affectedData,
                        sortedCartByCategoriesData: _sortedCartByCategoriesData,
                        sortCartByCategories: sideNavCtrl.sortCartByCategories,
                        scrollableWrapperSelector: scrollableWrapperSelector,
                        mainColor: sideNavCtrl.mainColor
                    }
                    SidenavService.scrollToAffectedLine(params);
                }
                
                /**
                 * 
                 * @param {'mouseleave' | 'mouseenter'} eventType 
                 * @param {string | number} lineId 
                 */
                function toggleProductActions(eventType, lineId) {
                    var elementId = 'sidenav_line_' + lineId;
                    var host = document.getElementById(elementId);
                    if (host) {
                        if (eventType === 'mouseleave' || eventType === 'blur') {
                            host.classList.add('hide-product-actions');
                        }
                        if (eventType === 'mouseenter' || eventType === 'focus') {
                            host.classList.remove('hide-product-actions');
                        }
                    }
                }

                function reloadCart() {
                    $rootScope.$emit('continueShopping', {});
                }
            }])
        // TODO: move to kikar template
        .controller('SidenavKikarTemplateCtrl', ['$scope', '$rootScope', '$timeout', '$q', 'LocalStorage', 'Util', 'Cart', 'Config', 'SpDeliveryTimesService', 'SidenavService', function ($scope, $rootScope, $timeout, $q, LocalStorageService, Util, Cart, Config, SpDeliveryTimesService, SidenavService) {
            var sideNavCtrl = $scope.sideNavCtrl,
                _mouseMoveTimeout = null,
                _isSideNavShown = true,
                _baseToggleCart = sideNavCtrl.toggleCart,
                _baseToggleOrdersHistory = sideNavCtrl.toggleOrdersHistory,
                _justClosedHistoryOrderId,
                _closeHistoryOrderTimeout,
                _cartInitiatedListener,
                _cartWasTouched,
                _affectedData,
                _refreshCartTimeout,
                _sortedCartByCategoriesData;

            sideNavCtrl.nextDelivery = {};

            sideNavCtrl.scrollToEnd = scrollToEnd;
            sideNavCtrl.toggleCart = toggleCart;
            sideNavCtrl.toggleOrdersHistory = toggleOrdersHistory;
            sideNavCtrl.openHistoryOrder = openHistoryOrder;
            sideNavCtrl.closeOrderHistory = closeOrderHistory;
            sideNavCtrl.reopenJustClosedOrder = reopenJustClosedOrder;
            sideNavCtrl.scrollToAffectedLine = scrollToAffectedLine;
            sideNavCtrl.toggleProductActions = toggleProductActions;
            sideNavCtrl.reloadCart = reloadCart;

            // sideNavCtrl.toggleOrdersHistory();
            _catchAddOrdersHistoryChunk();
            // _getNextDeliveryArea();


            if (!Cart.close() && !LocalStorageService.isExist('cartClosed')) {
                sideNavCtrl.toggleCart();
            }

            var outerCart = document.querySelector('.cart');
            
            angular.element(outerCart).bind('mouseover', function () {
                var cartScrollable = document.querySelector('.cart-scrollable');
                var timer = null;
                angular.element(cartScrollable).bind('scroll', function () {
                    if (timer !== null) {
                        clearTimeout(timer);
                    }
                    timer = setTimeout(function () {
                        window.scrollBy(0, 1);
                        window.scrollBy(0, -1);
                    }, 10);
                })
            })

            $rootScope.$on('continueShopping', function (event, data) {
                    // must be done in next call-stack
                    setTimeout(function () {
                        _getNextDeliveryArea(true);
                    }, 0)
            });

            $rootScope.$on('cart.lines.add', _sortItemsByCategories);

            function _sortItemsByCategories(event, data) {
                if (sideNavCtrl.sortCartByCategories) {
                    _affectedData = data;
                    _sortedCartByCategoriesData = Cart.sortCartByCategories($rootScope.cart.lines, sideNavCtrl.sortedCartByCategories, data, true)
                    sideNavCtrl.sortedCartByCategories = _sortedCartByCategoriesData.sortedObjByCategories;
                    sideNavCtrl.sortedCartByTree = Cart.sortByTree(sideNavCtrl.sortedCartByCategories);
                }
            }

            Util.currentScopeListener($scope, $rootScope.$on('sidenav.show', function () {
                _isSideNavShown = true;
                _getNextDeliveryArea();
            }));

            Util.currentScopeListener($scope, $rootScope.$on('sidenav.hide', function () {
                _isSideNavShown = false;
            }));

            Util.currentScopeListener($scope, $rootScope.$on('login', function () {
                _getNextDeliveryArea(true);
            }));

            Util.currentScopeListener($scope, $rootScope.$on('logout', function () {
                _getNextDeliveryArea(true);
            }));

            Util.currentScopeListener($scope, $rootScope.$on('config.branchAreaId.change', function () {
                _getNextDeliveryArea(true);
            }));

            function scrollToEnd() {
                $timeout(function () {
                    var products = document.querySelector('section.collapsible section.cart'),
                        lastLine = document.querySelector('section.collapsible > section.cart > div.product-in-cart:last-child');
                    if (products) {
                        products.scrollTop = products.scrollHeight - products.clientHeight;
                    }

                    $scope.lineAdd = true;

                    if (lastLine) {
                        angular.element(lastLine).addClass('border-animation');

                        $timeout(function () {
                            angular.element(lastLine).removeClass('border-animation');
                        }, 2000);
                    }
                }, 0, false);
            }

            function toggleCart() {
                if (sideNavCtrl.ordersHistoryOpen) {
                    sideNavCtrl.toggleOrdersHistory();
                }

                if (sideNavCtrl.orderHistoryOpenPermanently || sideNavCtrl.orderHistoryOpen) {
                    closeOrderHistory(true);
                }

                _baseToggleCart();
                _refreshCart();

                !Cart.close() && _getNextDeliveryArea();
            }

            function toggleOrdersHistory() {
                if (!sideNavCtrl.ordersHistoryOpen && Cart.close()) {
                    sideNavCtrl.toggleCart();
                }
                
                _refreshCart();
                _baseToggleOrdersHistory();
            }
            
            function _refreshCart(){
                if(_refreshCartTimeout){
                    clearTimeout(_refreshCartTimeout);
                }

                _refreshCartTimeout = $timeout(function () {
                    if (!sideNavCtrl.ordersHistoryOpen && !Cart.close()) {
                        $rootScope.$emit('sidenav.cart.refresh', {});
                    }
                }, 500);
            }

            function openHistoryOrder(orderId, permanently) {
                if (sideNavCtrl.orderHistoryOpenPermanently && !permanently) {
                    return;
                }

                if (permanently) {
                    sideNavCtrl.orderHistoryOpenPermanently = true;
                } else {
                    sideNavCtrl.orderHistoryOpen = true;
                }

                angular.element(document.querySelector('html')).addClass('sidenav-history-order-shown');
                _justClosedHistoryOrderId = null;
                sideNavCtrl.openHistoryOrderId = orderId;
                _closeHistoryOrderTimeout && $timeout.cancel(_closeHistoryOrderTimeout);

                return sideNavCtrl.setHistoryOrder(orderId);
            }

            function closeOrderHistory(permanently) {
                sideNavCtrl.orderHistoryOpen = false;
                if (permanently) {
                    sideNavCtrl.orderHistoryOpenPermanently = false;
                }

                if (sideNavCtrl.orderHistoryOpenPermanently && !permanently) {
                    return;
                }

                angular.element(document.querySelector('html')).removeClass('sidenav-history-order-shown');
                _justClosedHistoryOrderId = sideNavCtrl.openHistoryOrderId;
                sideNavCtrl.openHistoryOrderId = null;
                _closeHistoryOrderTimeout && $timeout.cancel(_closeHistoryOrderTimeout);
                _closeHistoryOrderTimeout = $timeout(function () {
                    _justClosedHistoryOrderId = null;
                    sideNavCtrl.clearHistoryOrder();
                }, 400);
            }

            function reopenJustClosedOrder() {
                if (!_justClosedHistoryOrderId) {
                    return;
                }

                openHistoryOrder(_justClosedHistoryOrderId);
            }

            /**
             * Call sideNavCtrl.addOrdersHistoryChunk and catch 'Total orders exceeded' error when got one
             * catch the error in order to avoid writing it to log
             *
             * @param {Number} [size]
             *
             * @private
             */
            function _catchAddOrdersHistoryChunk(size) {
                return sideNavCtrl.addOrdersHistoryChunk(size).catch(function (err) {
                    if (err === 'Total orders exceeded') {
                        return;
                    }

                    return $q.reject(err);
                });
            }

            function _showCartOnFirstTouch() {
                if (!_cartInitiatedListener && !_cartWasTouched) {
                    _cartWasTouched = true;
                    if (Cart.close() && $rootScope.windowSize.width > 1280) {
                        sideNavCtrl.toggleCart();
                    }
                }
            }

            function _getNextDeliveryArea(cancelTimeout) {
                if (cancelTimeout && !!_mouseMoveTimeout) {
                    $timeout.cancel(_mouseMoveTimeout);
                    _mouseMoveTimeout = null;
                }

                if (!!_mouseMoveTimeout || Cart.close() || !_isSideNavShown) {
                    return;
                }

                Config.initPromise.then(function() {
                    return SpDeliveryTimesService.getNextDeliveryTimes(sideNavCtrl.nextDelivery, _getDeliveryTimesByUserAddress, Config.getBranchArea(), Cart.serverCartId);
                }).then(function(result) {
                    Config.deliveryAreaPrice = result.area.deliveryAreaPrice;
                    if (!result.nextTime ||
                        (!result.nextTime.isToday && result.nextTime.type !== DELIVERY_TIMES_TYPES.DELIVERY_WITHIN_HOURS)) {
                        Util.showNoDeliveriesTodayPopup();
                    }

                    $rootScope.noAvailableDeliverySlots = result.noAvailableDeliverySlots; //== only false value, NOT null or undefined
                }).catch(function() {
                    if (sideNavCtrl.nextDelivery.times) {
                        sideNavCtrl.nextDelivery.times.splice(0);
                    }
                }).finally(function () {
                    sideNavCtrl.nextDelivery.loading = false;
                    // Cart.init();
                });

                _mouseMoveTimeout = $timeout(function () {
                    angular.element(document.body || document.querySelector('body')).bind('mousemove', _nextDeliveryOnMouseMove);
                }, 240000); // 4 minutes

                // Cart.init();
            }

            function _nextDeliveryOnMouseMove() {
                _mouseMoveTimeout = null;
                angular.element(document.body || document.querySelector('body')).unbind('mousemove', _nextDeliveryOnMouseMove);
                _getNextDeliveryArea();
            }

            function _getDeliveryTimesByUserAddress() {
                return User.getData().then(function(data) {
                    var address = data.addresses[0];
                    if (!address || !address.text1 || !address.city) {
                        return $q.reject('No Address');
                    }

                    if (Config.isZipCodeArea && Config.area && !address.zipCode) {
                        address.zipCode = Config.area;
                    }

                    return  {
                        address: {
                            text1: address.text1,
                            street: address.street,
                            houseNumber: address.houseNumber,
                            entry: address.entry,
                            city: address.city,
                            zipCode: address.zipCode
                        }
                    };
                });
            }

            _cartInitiatedListener = $rootScope.$on('cart.update.complete', function () {
                _cartInitiatedListener();
                _cartInitiatedListener = null;
            });

            Util.currentScopeListener($scope, $rootScope.$on('cart.shown', function () {
                _cartWasTouched = true;
            }));

            Util.currentScopeListener($scope, $rootScope.$on('cart.closed', function () {
                _cartWasTouched = true;
            }));

            Util.currentScopeListener($scope, $rootScope.$on('cart.lines.add', function () {
                _showCartOnFirstTouch();

                if (sideNavCtrl.ordersHistoryOpen) {
                    if (sideNavCtrl.isOrdersHistoryOpenOnLogin) {
                        sideNavCtrl.ordersHistoryOpen = false;
                        sideNavCtrl.isOrdersHistoryOpenOnLogin = false;
                    }

                    sideNavCtrl.toggleOrdersHistory();
                }
                scrollToEnd();
            }));

            Util.currentScopeListener($scope, $rootScope.$on('cart.lines.quantityChanged', _showCartOnFirstTouch));

            Util.currentScopeListener($scope, $rootScope.$on('cart.lines.remove', _showCartOnFirstTouch));

            Util.currentScopeListener($scope, $rootScope.$on('login', function () {
                if (sideNavCtrl.ordersHistoryOpen) {
                    sideNavCtrl.isOrdersHistoryOpenOnLogin = true;
                }

                _catchAddOrdersHistoryChunk();
            }));

            Util.currentScopeListener($scope, $rootScope.$on('checkout', function () {
                sideNavCtrl.ordersHistoryTotal = null;
                sideNavCtrl.ordersHistory = [];
                _catchAddOrdersHistoryChunk();
            }));

            $rootScope.$on('document.click', function (data, event) {
                var element = angular.element(event.target || event.srcElement || event.path[0]);
                while (element.length && (element.prop('tagName') !== 'DIV' || (!element.hasClass('sidenav') && !element.hasClass('dialog')))) {
                    element = angular.element(element.prop('parentNode') || element.prop('parentElement'));
                }

                if (element.hasClass('sidenav') || element.hasClass('dialog')) {
                    return;
                }

                sideNavCtrl.closeOrderHistory(true);
                $scope.$applyAsync();
            });

            function scrollToAffectedLine(lineId, scrollableWrapperSelector) {
                var params = {
                    lineId: lineId,
                    affectedData: _affectedData,
                    sortedCartByCategoriesData: _sortedCartByCategoriesData,
                    sortCartByCategories: sideNavCtrl.sortCartByCategories,
                    scrollableWrapperSelector: scrollableWrapperSelector,
                    mainColor: sideNavCtrl.mainColor
                }
                SidenavService.scrollToAffectedLine(params);
            }

            /**
             * 
             * @param {'mouseleave' | 'mouseenter'} eventType 
             * @param {string | number} lineId 
             */
            function toggleProductActions(eventType, lineId) {
                var elementId = 'sidenav_line_' + lineId;
                var host = document.getElementById(elementId);
                if (host) {
                    if (eventType === 'mouseleave') {
                        host.classList.add('hide-product-actions');
                    }
                    if (eventType === 'mouseenter') {
                        host.classList.remove('hide-product-actions');
                    }
                }
            }

            function reloadCart() {
                $rootScope.$emit('continueShopping', {});
            }
        }]);
})(angular, app, document);
