(function (angular, app) {
    "use strict";

    app.service('HE', [
        function () {
            var self = this;

            self.id = 1;
            self.culture = 'he';
            self.title = 'עברית';
            self.label = 'Hebrew';
            self.short = 'עבר';
            self.direction = 'rtl';
            self.icon = 'https://d226b0iufwcjmj.cloudfront.net/localization/he.png';
            self.dateFormat = {
                date: 'dd/MM/yyyy',
                time: 'HH:mm',
                dateAndTime: 'dd/MM/yyyy' + ' ' + 'HH:mm'
            };
            self.translate = {
                'arabic': 'ערבית',
                'english': 'אנגלית',
                'spanish': 'ספרדית',
                'french': 'צרפתית',
                'hebrew': 'עברית',
                'russian': 'רוסית',

                'left': 'שמאל',
                'right': 'ימין',
                'up': 'למעלה',
                'down': 'למטה',
                'search': 'חיפוש',

                'add to cart': 'הוספה לסל',
                'add to current cart': 'הוספה לסל נוכחי',
                'create new order': 'יצירת הזמנה חדשה',
                'cannot add to cart': 'לא ניתן להוסיף לעגלה',
                'add cart products': 'הוסיפו מוצרים מהעגלה',
                'special products': 'מוצרים במבצע',
                'save to shopping list': 'שמירה לרשימת קניות',
                'new list name': 'שם רשימה חדשה',
                'home': 'בית',
                'powered by': 'נוצר ע"י',
                'remove': 'הסרה',
                'login': 'כניסה',
                'email': 'דואר אלקטרוני',
                'password': 'סיסמה',
                'logout': 'ניתוק',
                'orders history': 'היסטוריית הזמנות',
                'recent orders': 'הזמנות אחרונות',
                'checkout': 'לקופה',
                'clear cart': 'ניקוי סל',
                'callcustomer': 'התקשרו אלי',
                'creditcard': 'כרטיס אשראי',
                'storecredit': 'חשבון לקוח',
                'the delivery time is no longer available': 'חלון זמנים זה לא זמין יותר',
                'add card': 'הוסיפו כרטיס',
                'activate card': 'הפעילו כרטיס',
                'add another card': 'הוספת כרטיס נוסף',
                'order update': 'עדכון הזמנה',
                'updated order value': 'ערך הזמנה מעודכן',
                '(estimated)': '(מוערך)',
                'in order to complete the order update, please choose the payment method': 'על מנת להשלים את עדכון ההזמנה, אנא בחר אמצעי תשלום',
                'confirm update': 'אישור עדכון',
                'add another account': 'הוסיפו חשבון נוסף',
                'remove card': 'הסר/י את הכרטיס',
                'pay with': 'שלמו באמצעות',
                'pay with credit card': 'שלמו באמצעות כרטיס אשראי',
                'remove account': 'הסר חשבון',
                'credit card number': 'מספר כרטיס אשראי',
                'cvv': 'cvv',
                'delivery address': 'כתובת למשלוח',
                'city': 'עיר',
                'street': 'רחוב',
                'phone num': 'מספר טלפון',
                'recipient\'s name': 'שם הנמען',
                'floor / apartment': 'קומה / דירה',
                'house number': 'מספר בית',
                'house no.': 'מספר בית',
                'available days': 'ימים זמינים',
                'entry: spautocompleterequirematch error': 'הזן אות (א-י)',
                'house_entry_none': 'ללא',
                'house_entry_a': 'א',
                'house_entry_b': 'ב',
                'house_entry_c': 'ג',
                'house_entry_d': 'ד',
                'house_entry_e': 'ה',
                'house_entry_f': 'ו',
                'house_entry_g': 'ז',
                'house_entry_h': 'ח',
                'house_entry_i': 'ט',
                'house_entry_j': 'י',
                'delivery time': 'זמן משלוח',
                'pickup time': 'זמן איסוף',
                'today': 'היום',
                'sunday': 'יום ראשון',
                'monday': 'יום שני',
                'tuesday': 'יום שלישי',
                'wednesday': 'יום רביעי',
                'thursday': 'יום חמישי',
                'friday': 'יום שישי',
                'saturday': 'יום שבת',
                'sun': 'ראשון',
                'mon': 'שני',
                'tue': 'שלישי',
                'wed': 'רביעי',
                'thu': 'חמישי',
                'fri': 'שישי',
                'notes': 'הערות',
                'add instructions': 'הוסיפו הוראות',
                'summary': 'סיכום',
                'products': 'מוצרים',
                'you saved': 'חסכתם',
                'payment': 'תשלום',
                'delivery charge': 'עלות משלוח',
                'price without discount': 'מחיר ללא הנחה',
                'price after discount': 'מחיר לאחר הנחה',
                'total': 'סה"כ',
                'between': 'בין',
                'order id': 'מזהה הזמנה',
                'your order has been received': 'הזמנתכם התקבלה',
                'shopping lists': 'רשימות קניות',
                'shopping list created': 'רשימת קניות נוצרה בהצלחה',
                'forgot password': 'שכחתי סיסמה',
                'register': 'הרשמה',
                'retailer details': 'פרטי חנות',
                'in all departments': 'בכל המחלקות',
                'edit details': 'עריכת פרטים',
                'loading': 'טוען',
                'supervision': 'כשרות',
                'load previous orders': 'טעינת הזמנות קודמות',
                'close previous orders': 'סגירת הזמנות קודמות',
                'previous orders': 'הזמנות קודמות',
                'sale': 'מבצע',
                'club': 'מועדון',
                'club sale': 'מבצע מועדון',
                'sale price': 'מחיר מבצע',
                'regular price': 'מחיר מחירון',
                'open sale details': 'לחץ כדי לראות את פרטי המבצע',
                'choose brand': 'בחרו מותג',
                'specials_and_benefits_title': 'מבצעים והטבות',
                'specials': 'מבצעים',
                'go to specials': 'לכל המבצעים',
                'item': 'מוצר',
                'items': 'מוצרים',
                'hide cart': 'הסתר עגלה',
                'best ingredients for best results': 'הרכיבים המובחרים לתוצאות המושלמות',
                'order items by': 'מיינו מוצרים ע"פ',
                'name': 'שם',
                'actions': 'פעולות',
                'creation date': 'תאריך יצירה',
                'delete': 'מחק',
                'create new shopping list': 'יצירת רשימה חדשה',
                'shopping list name': 'שם רשימת קניות',
                'create': 'צרו',
                'id': 'מזהה',
                'time': 'זמן',
                'date and time': 'זמן ותאריך',
                'ok': 'אישור',
                'address': 'כתובת',
                'phone': 'טלפון',
                'the site is undergoing maintenance': 'מצטערים, האתר בתחזוקה',
                'please try again later': 'אנא נסו שוב מאוחר יותר',
                'the site is active only for registered users, please login': 'האתר פעיל למשתמשים רשומים בלבד, אנא התחברו',
                'the site is disabled': 'האתר מושבת',
                'comment': 'הערה',
                'noun search': 'חיפוש',
                'finish order': 'סיימו הזמנה',
                'reset password': 'אפסו את הסיסמה',
				'mobile': 'נייד',
				'mobile phone': 'טלפון נייד',
                'phone number': 'מספר טלפון',
                'login with a one time password': 'התחברות עם קוד חד פעמי',
                'continue with sms': 'המשך באמצעות סמס',
                'continue with email': 'המשך באמצעות אימייל',
                'please enter': 'אנא הזינו',
                'please enter your phone number': 'הקלד את מספר הטלפון שלך',
                'please enter email': 'אנא הזינו כתובת דוא"ל',
                'please enter a valid email address': 'נא הזינו דוא"ל ',
                'please enter phone number or email': 'אנא הזינו מספר טלפון או כתובת דוא"ל',
                'please enter a valid phone number or email': 'אנא הזינו מספר טלפון או דוא"ל חוקיים',
                'we will send you one time password': 'קוד חד פעמי ישלח למספר הנייד שלך',
                'send password': 'שלח קוד',
                'we\'ve sent a password to the mobile number': 'שלחנו SMS עם קוד חד פעמי למספר',
                'we\'ve sent a password to your email address at': 'שלחנו קוד חד פעמי למייל',
                'not your phone number?': 'לא המספר שלך?',
                'not your email?': 'לא המייל שלך? ',
                'please complete verification': 'הקלד קוד אימות',
                'sorry, the code is invalid or expired.': 'הקוד לא תקין או פג תוקף',
                'invalid otp code. please try again': 'קוד OTP לא תקף. אנא נסו שוב.',
                'sorry, we cannot proceed using this phone number': 'מצטערים, לא הייתה אפשרות להמשיך עם מספר זה',
                'sorry, we cannot proceed using this email': 'מצטערים, לא הייתה אפשרות להמשיך עם מייל זה',
                'please try again or contact customer service for assistance: xxx-xxxxxx': 'אנא נסה שוב או צור קשר עם שירות הלקוחות במספר:XXX-XXXXXX',
                'back to login with email and password': 'התחבר באמצעות מייל וסיסמא',
                'edit': 'ערוך',
                'add phone': 'הוסיפו טלפון',
                'first name': 'שם פרטי',
                'last name': 'שם משפחה',
                'birth date': 'תאריך לידה',
                'birthday': 'תאריך לידה',
                'user name and password': 'שם משתמש וסיסמה',
                'user name': 'שם משתמש',
                'gender': 'מין',
                'male': 'זכר',
                'female': 'נקבה',
                'prefer not to say': 'אעדיף שלא לציין',
                'personal information': 'פרטים אישיים',
                'user details': 'פרטי המשתמש',
                'contact details': 'פרטי התקשרות',
                'comments': 'הערות',
                'work': 'עבודה',
                'allow to send promotions': 'אני מאשר/ת קבלת עדכונים',
                'my items': 'המוצרים שלי',
                'image': 'תמונה',
                'price': 'מחיר',
                'quantity': 'כמות',
                'actions on marked items': 'פעולות על פריטים מסומנים',
                'create new cart': 'יצירת עגלה חדשה',
                'create shopping list': 'יצירת רשימת קניות',
                'no products were selected': 'לא נבחרו מוצרים',
                'total sum': 'סכום כולל',
                'order status': 'מצב הזמנה',
                'new': 'קיבלנו',
                'received': 'קיבלנו',
                'charged': 'חויבה',
                'delivered': 'נשלחה',
                'cancelled': 'בוטלה',
                'suspended': 'בתהליך',
                'processing': 'בתהליך',
                'waiting for confirmation': 'ממתינה לאישור',
                'waiting_for_confirmation': 'ממתינה לאישור',
                'user_confirmed': 'בתהליך',
                'collected': 'לוקטה',
                'registered': 'נרשמה לקופה',
                'received text': 'ההזמנה התקבלה בהצלחה במערכת',
                'charged text': 'כרטיס האשראי חויב בסכום הקנייה',
                'delivered text': 'ההזמנה נשלחה ונמצאת בדרכה אליכם',
                'cancelled text': 'ההזמנה בוטלה ואינה מטופלת',
                'suspended text': 'ההזמנה בתהליך ליקוט בחנות כעת',
                'processing text': 'ההזמנה בתהליך ליקוט בחנות כעת',
                'collected text': 'ההזמנה הודפסה בחנות ונמצאת בטיפול',
                'registered text': 'ההזמנה הודפסה בחנות ונמצאת בטיפול',
                'waiting for confirmation text': 'הודעה נשלחה למספר שמופיע בהזמנה, בבקשה אשר את המוצרים החלופיים כדי שנוכל להשלים את ההזמנה',
                'no products were found': 'לא נמצאו מוצרים',
                'order number': 'מספר הזמנה',
                'date': 'תאריך',
                'back': 'חזרה',
                '<< back': '<< חזרה',
                'save': 'שמור',
                'shopping list': 'רשימת קנייה',
                'address 1': 'רחוב + מספר בית',
                'address 2': 'כניסה + קומה + דירה',
                'i confirm that i have read the': 'אני מאשר/ת שקראתי את',
                'terms': 'תקנון',
                'similar products': 'מוצרים דומים',
                'check all': 'סמן הכל',
                'uncheck all': 'בטל סימון מהכל',
                'all': 'הכל',
                'narrow your choice': 'סינון מוצרים',
                'brand': 'מותג',
                'type': 'סוג',
                'category': 'קטגוריה',
                'sign in': 'כניסה',
                'sign in using facebook': 'כניסה באמצעות פייסבוק',
                'it\'s easy': 'זה קל',
                'please sign in to continue...': 'אנא היכנסו לאתר כדי להמשיך...',
                'country': 'מדינה',
                'at': 'ב',
                'let\'s start': 'בואו נתחיל',
                'adding some items': 'הוסיפו מוצרים',
                'it\'s easy!': 'זה פשוט!',
                'forgot password?': 'שכחתם סיסמה?',
                'confirm password': 'אימות סיסמה',
                'i agree to the': 'הנני מאשר/ת כי קראתי את ',
                'terms & conditions': 'תקנון האתר',
                'policy_end': 'ומסכימ/ה לתנאיהם',
                'please let me know about promotions': 'הנני מאשר/ת קבלת עדכונים והטבות בדוא"ל ובמסרונים (ניתן להפסיק את קבלת העדכונים בכל עת)',
                'reset': 'איפוס',
                'please check your email for the password reset information': 'אנא בדקו את הדואר האלקטרוני שלכם לקבלת המידע עבור איפוס הסיסמה',
                'please check your inbox and follow the instructions': 'להמשך התהליך, אנא בדוק את תיבת המייל שלך',
                'if you haven\'t received an email, please check if you mistyped your email address or contact customer service': 'במידה ולא קיבלת מייל, יש לוודא את המייל שהקלדת או צור קשר עם שירות לקוחות במספר',
                'this action cannot be completed at this time': 'הפעולה שניסית לבצע לא אפשרית כרגע',
                'back to login': 'חזרה להתחברות',
                'open previous carts:': 'פתחו קנייה קודמת:',
                'save and sign in': 'שמור והיכנס',
                'save for later': 'שמור לעתיד',
                'today\'s special deals': 'מבצעים מובילים',
                'no': 'לא',
                'yes': 'כן',
                'delivery': 'משלוח',
                'pickup': 'איסוף עצמי',
                'pick and go': 'שגר וסע',
                'pick & go': 'שגר וסע',
                'undefined': 'לא מוגדר',
                'anything else': 'הערה למשלוח',
                'delivery date': 'תאריך משלוח',
                'pickup date': 'תאריך איסוף',
                'estimated total': 'סה"כ',
                'estimated': 'מוערך',
                'allow substitutions': 'אישור החלפת מוצרים',
                'receipt': 'חשבונית',
                'receipt_option_title_1': 'חשבונית דיגיטלית',
                'receipt_option_title_2': 'גם וגם',
                'receipt_option_title_3': 'חשבונית מודפסת',
                'receipt_option_1': 'החשבונית תשלח באמצעות SMS',
                'receipt_option_2': 'החשבונית תשלח באמצעות SMS והעתק מודפס יצורף להזמנה שלך',
                'receipt_option_3': 'החשבונית תצורף להזמנה שלך',
                'delivery date and time': 'תאריך וזמני משלוח',
                'next': 'הבא',
                'contact details & payment': 'פרטים אישיים ותשלום',
                'checkout contact details': 'פרטים אישיים',
                'summary & payment': 'סיכום ותשלום',
                'delivery/pickup preferences': 'איסוף/משלוח',
                'how do you want to get your order?': 'איך ברצונכם לקבל את ההזמנה שלכם ?',
                'the site is active only for registered users': 'האתר פעיל למשתמשים רשומים בלבד',
                'please login': 'אנא התחברו',
                'sort by': 'מיון לפי',
                'save as a shopping list': 'שמרו לרשימת קניות',
                'your shopping cart': 'עגלת הקניות שלך',
                'save to list': 'שמור לרשימה',
                'product name': 'שם המוצר',
                'sort in ascending order': 'מיין לפי סדר עולה',
                'sort in descending order': 'מיין לפי סדר יורד',
                'order ': 'הזמנה',
                'order': 'סדר',
                'unit price': 'מחיר יחידה',
                'units': 'יחידות',
                'unit': 'יח\'',
                'proceed to checkout': 'לסיום קנייה',
                'est.': 'בערך',
                'est. total weight':  'משקל מוערך כ-',
                'est. weight':  'משקל מוערך כ-',
				'mobile: required error': 'אנא הכניסו טלפון נייד',
				'mobilephone: required error': 'אנא הכניסו טלפון נייד',
                'phone: required error': 'אנא הכניסו מספר טלפון',
                'phone: minlength error': 'מספר טלפון חייב להכיל לפחות 7 ספרות',
                'city: required error': 'לאיזו עיר לשלוח?',
                'city: spautocompleterequirematch error': 'לא נמצאה עיר',
                'cityinput: spautocompleterequirematch error': 'לא נמצאה עיר',
                'street: required error': 'אנא הכניסו רחוב',
                'street: spautocompleterequirematch error': 'לא נמצא רחוב',
                'streetinput: spautocompleterequirematch error': 'לא נמצא רחוב',
                'housenum: required error': 'אנא הכניסו מספר בית',
                'text1: required error': 'אנא הכניסו כתובת',
                'addresstext: required error': 'אנא הכניסו כתובת',
				'fullname: required error': 'אנא הכניסו שם מלא',
                'email: required error': 'אנא הקלידו את כתובת הדואר האלקטרוני שלכם',
                'email: email error': 'אנא הקלידו כתובת דואר אלקטרוני תקינה',
                'firstname: required error': 'אנא הכניסו שם פרטי',
                'lastname: required error': 'אנא הכניסו שם משפחה',
                'password: required error': 'אנא הכניסו סיסמה',
                'password: minlength error': 'סיסמה חייבת להכיל 6 תווים לפחות',
                'password: maxlength error': 'סיסמה יכולה להכיל עד 30 תווים',
                'password: pattern error': 'סיסמה חייבת להכיל לפחות ספרה אחת ואות אחת',
                'confirmpassword: required error': 'אנא אשרו סיסמה',
                'confirm_password: required error': 'אנא אשרו סיסמה',
                'policyapproval: required error': 'אנא אשרו את תקנון האתר ותנאיו',
                'please confirm that you accept the terms and conditions for using this site': 'נא לוודא שהנכם מסכימים לתנאי השימוש באתר',
                'zipcode: required error': 'אנא הכניסו את המיקוד שלך',
                'message: required error': 'אנא הכניסו טקסט',
				'loyaltycardid: required error': 'אנא הכניסו מספר חבר מועדון',
                'membershipapproval: required error': 'אנא אשרו שבבעלותכם כרטיס מועדון',
				'ValidationKey: required error': 'אנא הכניסו מספר תעודת זהות',
				'birthdate: date error': 'אנא הכניסו תאריך לידה',
                'confirmpassword: passwords do not match error': 'הסיסמאות אינן תואמות',
                'confirm_password: Passwords do not match error': 'הסיסמאות אינן תואמות',
                'confirmPassword: pattern error': 'הסיסמאות אינן תואמות',
                'confirm_password: pattern error': 'הסיסמאות אינן תואמות',
                'floor: step error': 'אנא הכניסו מספר שלם',
                'floor: not_editable error': 'אין אפשרות לערוך שדה זה לפני הכנסת מיקוד לפי הכתובת שלך',
                'floor: not_editable_locked error': 'כדי לשנות את הכתובת יש לחזור לבחירת כתובת משדה המיקוד',
                'floor: not_editable_locked_empty error': 'יש לבחור את הכתובת מתוך רשימת הכתובות לאחר הכנסת המיקוד',
                'text1: not_editable error': 'אין אפשרות לערוך שדה זה לפני הכנסת מיקוד לפי הכתובת שלך',
                'text1: not_editable_locked error': 'כדי לשנות את הכתובת יש לחזור לבחירת כתובת משדה המיקוד',
                'text1: not_editable_locked_empty error': 'יש לבחור את הכתובת מתוך רשימת הכתובות לאחר הכנסת המיקוד',
                'text2: not_editable error': 'אין אפשרות לערוך שדה זה לפני הכנסת מיקוד לפי הכתובת שלך',
                'text2: not_editable_locked error': 'כדי לשנות את הכתובת יש לחזור לבחירת כתובת משדה המיקוד',
                'text2: not_editable_locked_empty error': 'יש לבחור את הכתובת מתוך רשימת הכתובות לאחר הכנסת המיקוד',
                'street: not_editable error': 'אין אפשרות לערוך שדה זה לפני הכנסת מיקוד לפי הכתובת שלך',
                'street: not_editable_locked error': 'כדי לשנות את הכתובת יש לחזור לבחירת כתובת משדה המיקוד',
                'street: not_editable_locked_empty error': 'יש לבחור את הכתובת מתוך רשימת הכתובות לאחר הכנסת המיקוד',
                'cityinput: not_editable error': 'אין אפשרות לערוך שדה זה לפני הכנסת מיקוד לפי הכתובת שלך',
                'cityinput: not_editable_locked error': 'כדי לשנות את הכתובת יש לחזור לבחירת כתובת משדה המיקוד',
                'cityinput: not_editable_locked_empty error': 'יש לבחור את הכתובת מתוך רשימת הכתובות לאחר הכנסת המיקוד',
                'city: not_editable error': 'אין אפשרות לערוך שדה זה לפני הכנסת מיקוד לפי הכתובת שלך',
                'city: not_editable_locked error': 'כדי לשנות את הכתובת יש לחזור לבחירת כתובת משדה המיקוד',
                'city: not_editable_locked_empty error': 'יש לבחור את הכתובת מתוך רשימת הכתובות לאחר הכנסת המיקוד',
                'countryinput: not_editable error': 'אין אפשרות לערוך שדה זה לפני הכנסת מיקוד לפי הכתובת שלך',
                'countryinput: not_editable_locked error': 'כדי לשנות את הכתובת יש לחזור לבחירת כתובת משדה המיקוד',
                'countryinput: not_editable_locked_empty error': 'יש לבחור את הכתובת מתוך רשימת הכתובות לאחר הכנסת המיקוד',
                'housenum: not_editable error': 'אין אפשרות לערוך שדה זה לפני הכנסת מיקוד לפי הכתובת שלך',
                'housenum: not_editable_locked error': 'כדי לשנות את הכתובת יש לחזור לבחירת כתובת משדה המיקוד',
                'housenum: not_editable_locked_empty error': 'יש לבחור את הכתובת מתוך רשימת הכתובות לאחר הכנסת המיקוד',
                'entry: not_editable error': 'אין אפשרות לערוך שדה זה לפני הכנסת מיקוד לפי הכתובת שלך',
                'entry: not_editable_locked error': 'כדי לשנות את הכתובת יש לחזור לבחירת כתובת משדה המיקוד',
                'entry: not_editable_locked_empty error': 'יש לבחור את הכתובת מתוך רשימת הכתובות לאחר הכנסת המיקוד',
                'entrance: not_editable error': 'אין אפשרות לערוך שדה זה לפני הכנסת מיקוד לפי הכתובת שלך',
                'entrance: not_editable_locked error': 'כדי לשנות את הכתובת יש לחזור לבחירת כתובת משדה המיקוד',
                'entrance: not_editable_locked_empty error': 'יש לבחור את הכתובת מתוך רשימת הכתובות לאחר הכנסת המיקוד',
                'apartment: not_editable error': 'אין אפשרות לערוך שדה זה לפני הכנסת מיקוד לפי הכתובת שלך',
                'apartment: not_editable_locked error': 'כדי לשנות את הכתובת יש לחזור לבחירת כתובת משדה המיקוד',
                'apartment: not_editable_locked_empty error': 'יש לבחור את הכתובת מתוך רשימת הכתובות לאחר הכנסת המיקוד',
                'apartmentinput: not_editable error': 'אין אפשרות לערוך שדה זה לפני הכנסת מיקוד לפי הכתובת שלך',
                'apartmentinput: not_editable_locked error': 'כדי לשנות את הכתובת יש לחזור לבחירת כתובת משדה המיקוד',
                'apartmentinput: not_editable_locked_empty error': 'יש לבחור את הכתובת מתוך רשימת הכתובות לאחר הכנסת המיקוד',
                'address1input: not_editable error': 'אין אפשרות לערוך שדה זה לפני הכנסת מיקוד לפי הכתובת שלך',
                'address1input: not_editable_locked error': 'כדי לשנות את הכתובת יש לחזור לבחירת כתובת משדה המיקוד',
                'address1input: not_editable_locked_empty error': 'יש לבחור את הכתובת מתוך רשימת הכתובות לאחר הכנסת המיקוד',
                'address2input: not_editable error': 'אין אפשרות לערוך שדה זה לפני הכנסת מיקוד לפי הכתובת שלך',
                'address2input: not_editable_locked error': 'כדי לשנות את הכתובת יש לחזור לבחירת כתובת משדה המיקוד',
                'address2input: not_editable_locked_empty error': 'יש לבחור את הכתובת מתוך רשימת הכתובות לאחר הכנסת המיקוד',
                'zipcode: not_editable error': 'אין אפשרות לערוך שדה זה',
                'zipinput: not_editable error': 'אין אפשרות לערוך שדה זה',
                'zipcodeinput: not_editable error': 'אין אפשרות לערוך שדה זה',
                'please enter an amount': 'אנא הכניסו כמות',
                'select order': 'בחרו מיון',
                'create new list': 'צרו רשימה חדשה',
                'my account': 'החשבון שלי',
                'continue shopping': 'המשיכו בקנייה',
                'homepagenavigation': 'לעמוד הבית',
                'add comments': 'הוסיפו הערות',
                'add comment': 'הוסיפו הערה',
                'what happens if something i ordered is not available?': 'מה קורה כאשר מוצר שהזמנתי לא זמין ?',
                'read more': 'קרא עוד',
                'read less': 'קרא פחות',
                'substitute_preference_1_label': 'החליפו מוצרים',
                'substitute_preference_1_description': 'אנו נחליף מוצרים חסרים מבלי להתקשר אליך',
                'substitute_preference_2_label': 'התקשרו אליי',
                'substitute_preference_2_description': 'אנו נחליף מוצרים בתיאום טלפוני',
                'substitute_preference_3_label': 'אל תחליפו מוצרים',
                'substitute_preference_3_description': 'אנו לא נחליף מוצרים חסרים',
                'substitute_preference_4_label': 'שלחו לי SMS',
                'substitute_preference_4_description': 'אנו נשלח לך SMS לפני שנחליף מוצרים חסרים',
                'substitute_preference_5_label': 'WhatsApp',
                'substitute_preference_5_description': 'אנו נשלח לך הודעת WhatsApp לפני שנחליף מוצרים חסרים',
                'substitute_preference__label': 'למנוע שגיאה',
                'substitute_preference__description': 'למנוע שגיאה',
                'zip code': 'מיקוד',
                'zip': 'מיקוד',
                'state': 'מדינה',
                'full name': 'שם מלא',
                'payment details': 'פרטי תשלום',
                'your order will be delivered': 'הזמנתך תישלח ביום',
                'your order will be ready for pickup': 'הזמנתכם תהיה מוכנה לאיסוף',
                'your order will be delivered in': 'הזמנתך תישלח תוך',
                'your order will be ready for pickup in': 'הזמנתכם תהיה מוכנה לאיסוף תוך',
                'substitution choice': 'החלפת מוצרים',
                'address line 1': 'כתובת',
                'add another address line': 'הוסיפו שורת כתובת נוספת',
                'address line 2': 'כתובת נוספת',
                'remove another address': 'הסירו כתובת נוספת',
                'almost there!': 'כמעט סיימנו...',
                'send the delivery sooner, if possible': 'אם אפשרי, שילחו הזמנה מוקדם יותר',
                'prepare the order sooner, if possible': 'אם אפשרי, הכינו הזמנה מוקדם יותר',
                'sooner if possible': 'בהקדם האפשרי',
                'checkout summary': 'סיכום קנייה',
                'only': 'רק',
                'day': 'יום',
                'free': 'חינם',
                'total save': 'סה"כ הנחה',
                'place order': 'סיום הזמנה',
                'your email or password seems to be wrong': 'הדואר האלקטרוני או הסיסמה שלכם אינם תקינים',
                'please try again': 'אנא נסו שנית',
                'oops': 'אופס',
                'null': '',
                'select': 'בחרו',
                'show previous orders': 'טענו הזמנות קודמות',
                'hide previous orders': 'הסתירו הזמנות קודמות',
                'choose list': 'בחר רשימה',
                'choose order': 'בחרו הזמנה',
                'choose shopping list': 'בחרו רשימת קניות',
                'we couldn\'t create your shopping list': 'לא הצלחנו לייצר את רשימת הקניות שלכם',
                'list name': 'שם הרשימה',
                'create list': 'צרו רשימה',
                'more details': 'פרטים נוספים',
                'floor': 'קומה',
                'apartment': 'דירה',
                'close details': 'סגור פרטים',
                'we just sent you an order confirmation by email': 'אישור הזמנה נשלח אליך בדואר אלקטרוני',
                'thank you for shopping at': 'תודה שקנית ב',
                'prutah_checkout_finish_thank_you_line_1': 'תודה שקנית',
                'prutah_checkout_finish_thank_you_line_2': 'ב',
                'please add your payment card': 'יש למלא את פרטי כרטיס האשראי',
                'my orders': 'ההזמנות שלי',
                'hours': 'שעות',
                'ready for pickup': 'תהיה מוכנה לאיסוף',
                'we are sorry, but the following products are out of stock': 'מצטערים, אך המוצרים הבאים חסרים במלאי',
                'remove from cart & continue': 'מחק מהסל והמשך',
                'you save': 'חסכתם',
                'can\'t find item?': 'לא מצאתם מוצר?',
                'can\'t find an item?': 'לא מצאתם מוצר?',
                'tax': 'מע"מ',
                'unknown': 'לא ידוע',
                'other': 'אחר',
                'add it here': 'הוסיפו אותו כאן',
                'choose category': 'בחר קטגוריה',
                'if we are unfamiliar with the product or don\'t have it in stock, we will give you a call': 'אם המוצר המבוקש אינו בנמצא, אנו ניצור קשר',
                'store info': 'פרטי החנות',
                'stores info': 'רשימת הסניפים',
                'store-details': 'פרטי החנות',
                'store location': 'מיקום החנות',
                'price high-low': 'מחיר מהגבוה לנמוך',
                'price low-high': 'מחיר מהנמוך לגבוה',
                'my shopping lists': 'רשימות הקניות',
                'remove shopping list': 'מחיקת רשימת קניות',
                'are you sure you want to remove the shopping list': 'האם ברצונכם למחוק את הרשימה',
                'removed': 'הוסר',
                'add to shopping list': 'הוספה לרשימת קניות',
                'payments number': 'מספר תשלומים',
                'cvv: required error': 'אנא הכניסו CVV',
                'cvv: invalid cvv error': 'CVV לא חוקי',
                'enter cvv': 'הזינו CVV',
                'cvv: please enter cvv error': 'אנא הכניסו CVV',
                'cvv: minlength error': 'cvv צריך להכיל לפחות 3 ספרות',
                'cancel': 'בטל',
                'continue with this cart': 'המשך עם העגלה הנוכחית',
                'merge carts': 'מזג עגלות',
                'would you like to merge the two carts or continue using this cart': 'האם ברצונכם למזג את שתי העגלות או להמשיך עם העגלה הנוכחית',
                'somewhere else': 'במקום אחר',
                'you have an open cart with': 'יש לכם עגלה פתוחה עם',
                'total weight': 'משקל כולל',
                'account info': 'פרטי חשבון',
                'to update details, log in to the yellow app or call *9974 customer service': 'כדי לעדכן פרטים, יש להיכנס לאפליקציית yellow או באמצעות שירות הלקוחות 9974*',
                'orders': 'הזמנות',
                'payment methods': 'אפשרויות תשלום',
                'coupons/discounts': 'קופונים/הנחות',
                'save changes': 'שמירת שינויים',
                'credit cards': 'כרטיסי אשראי',
                'paypal accounts': 'חשבונות PayPal',
                'shopping list named': 'רשימת קניות בשם',
                'pseudo_products_will_not_be_added_to_created_shopping_list': 'מוצרים שנוספו בטקסט חופשי לא יתווספו לרשימת הקניות ששמרת',
                'pseudo_products_will_not_be_added_to_saved_shopping_list': 'מוצרים שנוספו בטקסט חופשי לא יתווספו לרשימת הקניות',
                'your account info has been successfully updated': 'פרטי החשבון עודכנו בהצלחה',
                'was successfully created with': 'נוצרה בהצלחה עם',
                'items successfully added': 'מוצרים נוספו בהצלחה',
                'items were successfully added to shopping list': 'מוצרים נוספו בהצלחה לרשימת קניות',
                'item was successfully added to shopping list': 'המוצר נוסף בהצלחה לרשימת קניות',
                'items were not added to shopping list': 'מוצרים לא נוספו לרשימת הקניות',
                'item were not added to shopping list': 'המוצר לא נוסף לרשימת הקניות',
                'saved in': 'נשמר ב',
                'we are not able to add the following products to your current order': 'אין באפשרותנו להוסיף את המוצרים הבאים להזמנתכם הנוכחית',
                'opening hours': 'שעות פתיחה',
                'additional': 'פרטים נוספים',
                'area map': 'מפה',
                'contact us': 'יצירת קשר',
                'store information': 'פרטי החנות',
                'how can we help you today': 'איך אנחנו יכולים לעזור לכם',
                'how can we help you': 'איך אפשר לעזור לכם',
                'send': 'שליחה',
                'sent successfully': 'נשלח בהצלחה',
                'successfully updated': 'הפרטים עודכנו בהצלחה',
                'successful update': 'עודכן בהצלחה',
                'shopping list was successfully updated': 'רשימת הקניות עודכנה בהצלחה',
                'service provider blocker': 'חסימה ע"י ספק האינטרנט',
                'your service provider is blocking some of the website\'s functionality': 'ספק האינטרנט שלכם חוסם חלק מהתפעול של האתר, ומונע מהאתר לתפקד באופן מלא',
                'once we complete picking your order, we adjust your final charge based on weight adjustments, substitutions & out of stock items': 'במהלך ליקוט ההזמנה ייתכנו שינויים בחיוב הסופי כתוצאה משקילת מוצרים, מוצרים חלופיים ומוצרים חסרים במלאי',
                'please contact your service provider': 'אנא פנו לספק האינטרנט שלכם',
                'close': 'סגור',
                'passwords do not match': 'הסיסמאות אינן תואמות',
                'send reset code': 'שלחו קוד איפוס',
                'are you sure you want to clear your cart': 'האם לנקות את סל הקניות',
                'e-mail': 'דואר אלקטרוני',
                'your message has been sent': 'הודעתכם נשלחה בהצלחה',
                'change zip code': 'החלף מיקוד',
                'change area': 'שינוי אזור',
                'hey there': 'שלום',
                'let\'s make sure we\'re available in your area': 'בואו נוודא כי אנו זמינים באזורכם',
                'please enter your zip code': 'אנא הכניסו את המיקוד שלכם',
                'please enter your area': 'אנא הכניסו את ישוב מגוריכם',
                'area: required error': 'אנא הכניסו את ישוב מגוריכם',
                'the area you entered, is served by this store': 'חנות זו משרתת את אזורכם',
                'pickup service only': 'שירות איסוף עצמי בלבד',
                'we serve your area': 'אנו משרתים את אזורכם',
                'delivery method in this area': 'סוג משלוח באזור זה',
                'check': 'בדיקה',
                'for ': 'ל',
                'we offer': 'אנחנו מציעים',
                'both': '',
                'and': 'ו',
                'and the': 'ואת',
                'pick up': 'איסוף מהחנות',
                'options': '',
                'your store will be': 'החנות שלכם תהיה',
                'by changing your zip code you will lose your shopping cart': 'על ידי שינוי המיקוד שלכם תאבדו את עגלת הקניות שלכם',
                'by changing your area you will lose your shopping cart': 'על ידי שינוי האזור שלכם תאבדו את עגלת הקניות שלכם',
                'go back': 'חזרה',
                'don\'t change zip code': 'החליפו מיקוד',
                'don\'t change area': 'ביטול החלפת אזור',
                'currently we do not deliver to your area': 'נכון לעכשיו אנחנו לא מבצעים משלוחים לאזור שלכם',
                'change store': 'החליפו חנות',
                'change to pickup': 'החלף לאיסוף מהחנות',
                'change to delivery': 'החלף למשלוח',
                'go to store': 'המשיכו לחנות',
                'the zipcode you entered, is served by this store': 'אזור המשלוח שהכנסתם מקבל שירות ע"י החנות הנוכחית',
                'next delivery regular': 'מועד המשלוח הבא',
                'thank you for contacting us': 'תודה. פנייתכם חשובה לנו',
                'delivery preferences': 'משלוח',
                'pickup preferences': 'איסוף',
                'privacy policy': 'מדיניות הפרטיות',
                'about us': 'אודותינו',
                'not active': 'לא פעיל',
                'case of': 'מארז של',
                'case': 'מארז',
                'single': 'בודד',
                'ordersfromposfieldtitle0': 'אנא הכניסו את מספר הנייד שלך',
                'ordersfromposfielddescription0': 'אנחנו נשלח לך הודעת אימות',
                'phonenumber: required error': 'אנא הכנס את מספר הנייד שלך',
                'orderfromposcodesenttitle0': 'שלחנו הודעת טקסט עם קוד אימות למספר <%value%>',
                'confirmcode: required error': 'אנא הכניסו קוד אימות',
                'hi': 'שלום',
                'entrance': 'כניסה',
                'faq': 'שאלות נפוצות',
                'new password': 'סיסמה חדשה',
                'resetnewpassword: required error': 'אנא הכניסו סיסמה חדשה',
                'resetconfirmpassword: required error': 'אנא אשרו את סיסמתכם החדשה',
                'resetconfirmpassword: passwords do not match error': 'סיסמאות לא תואמות',
                'resetnewpassword: minlength error': 'הסיסמה החדשה חייבת להכיל לפחות 6 תווים',
                'once we collected your order, we will call to collect your payment': 'לאחר שהזמנתכם תאסף, נציגינו יצרו עמכם קשר על מנת לקחת את פרטי התשלום',
                'choose how you\'d like to pay': 'בחר את אופן ביצוע התשלום',
                'your credit cards': 'כרטיסי האשראי שלך',
                'your paypal accounts': 'חשבונות ה-PayPal שלכם',
                'enter your credit on file': 'אנא הכניסו את מספר חשבון הלקוח שלכם',
                'subtotal': 'סיכום ביניים',
                'substitutions choice': 'החלפת מוצרים',
                'let\'s change your password...': 'בחרו סיסמה חדשה...',
                'you must agree to the terms': 'עליכם להסכים לתנאי השירות',
                'registration completed': 'ההרשמה הושלמה',
                'registration is closed right now': 'ההרשמה סגורה כרגע',
                'please enter cvv': 'אנא הכניסו cvv',
                'pre auth: declined invld acct': 'קוד CVV אינו תואם את כרטיס האשראי. אנא נסו שנית',
                'cvv is an anti-fraud security feature to help verify that you are in possession of your credit card': 'קוד CVV - Credit Validation Value, הוא קוד המשמש לאימות שהכרטיס נמצא בחזקתכם',
                'the three-digit cvv number is printed on the signature panel on the back of the card immediately after the card\'s account number': 'שלושת ספרות הCVV מודפסות בגב הכרטיס על גבי פס החתימה',
                'select location': 'בחרו מיקום',
                'pickup location': 'מיקום האיסוף',
                'day0': 'ראשון',
                'day1': 'שני',
                'day2': 'שלישי',
                'day3': 'רביעי',
                'day4': 'חמישי',
                'day5': 'שישי',
                'day6': 'שבת',
                'week_day_0': 'ראשון',
                'week_day_1': 'שני',
                'week_day_2': 'שלישי',
                'week_day_3': 'רביעי',
                'week_day_4': 'חמישי',
                'week_day_5': 'שישי',
                'week_day_6': 'שבת',
                'ticket_status_1': 'חדש',
                'ticket_status_2': 'בטיפול',
                'ticket_status_3': 'סיום',
                'ticket': 'כרטיס שירות',
                'by': 'מאת',
                'back to tickets': 'חזרה לכרטיסי שירות',
                'me': 'אני',
                'status': 'סטאטוס',
                'description': 'תיאור',
                'created date': 'תאריך פתיחה',
                'customer service': 'שירות לקוחות',
                'service': 'שירות',
                'inquiry': 'פנייה מס\'-',
                'type in your comment': 'הכניסו הערה כאן',
                'ticket id': 'מזהה כרטיס שירות',
                'no previous inquiries': 'לא רשומות פניות',
                'we didn\'t recognize the address you entered': 'אנחנו לא מזהים את הכתובת שהזנתם',
                'sorry, currently we do not deliver to this area.': 'מצטערים, נכון לעכשיו שירות המשלוחים שלנו אינו מגיע לאזורכם',
                'sorry, currently we do not deliver to this area, for further inquiries call': 'מצטערים, נכון לעכשיו שירות המשלוחים שלנו אינו מגיע לאזורכם, לבדיקה נוספת התקשרו',
                'sorry, currently we do not deliver to this area, for further inquiries call our customer service': 'מצטערים, נכון לעכשיו שירות המשלוחים שלנו אינו מגיע לאזורכם, לבדיקה נוספת התקשרו לשירות לקוחות',
                'sorry, branch change required': 'מצטערים, דרוש שינוי סניף',
                'this address could only be delivered from branch': 'לכתובת זו ניתן לשלוח רק מסניף',
                'try another address or choose pickup option': 'נסו כתובת אחרת או בחרו באופציית האיסוף העצמי',
                'in': 'בתוך',
                'hrs': 'שעות',
                'minutes': 'דקות',
                'review your order and process payment': 'סיכום הזמנה ותשלום',
                'what did you mean': 'התכוונת ל',
                'please choose delivery time': 'עליכם לבחור זמן משלוח',
                'please choose pickup time': 'עליכם לבחור זמן איסוף',
                'delivery details': 'פרטי משלוח',
                'your details': 'פרטים אישיים',
                'pickup details': 'פרטי איסוף',
                'street number and street name': 'רחוב (אם קיים) ומספר בית',
                'entrance, apartment, suite, building, floor, etc.': 'כניסה, קומה, מספר דירה וכו',
                'apartment, suite, building, floor, etc.': 'קומה, מספר דירה וכו',
                'type letter': 'הזן אות',
                'add products': 'הוסיפו מוצרים',
                'add': 'הוסיפו',
                'choose exist list': 'בחרו רשימה קיימת',
                'clear all': 'נקה הכל',
                'or': 'או',
                'for': 'ל',
                'to ': 'ל',
                'your order total {{areaname}}does not meet the {{minimum | currency}} minimum order total': 'ההזמנה שלכם {{areaName}}טרם הגיעה למינימום של {{minimum | currency}}',
                'please add to your cart items worth {{difference | currency}} to proceed to checkout': 'אנא הוסיפו לסל מוצרים בשווי {{difference | currency}} על מנת לסיים את הקנייה',
                'not including items on sale': 'לא כולל מוצרים שבמבצע',
                'delivery fee': 'דמי משלוח',
                'pickup fee': 'דמי איסוף',
                'pickup times & areas': 'זמני ואזורי איסוף',
                'service fee': 'דמי שירות',
                'choose your pickup time': 'בחרו את זמן האיסוף שלכם',
                'delivery times': 'זמני משלוחים',
                'delivery times & areas': 'זמני ואזורי משלוח',
                'delivery areas': 'אזורי משלוחים',
                'add your gift card': 'תשלום בתווי קניה',
                'enter your gift card id': 'הכניסו את מספר הזיהוי של התו',
                'phone: pattern error': 'מספר טלפון לא תקין',
                'phone: maxlength error': 'מספר טלפון לא תקין',
                'phone: number error': 'מספר טלפון לא תקין',
                'homephone: pattern error': 'מספר טלפון לא תקין',
                'homephone: number error': 'מספר טלפון לא תקין',
                'workphone: pattern error': 'מספר טלפון לא תקין',
                'workphone: number error': 'מספר טלפון לא תקין',
                'mobilephone: pattern error': 'מספר טלפון לא תקין',
                'mobilephone: number error': 'מספר טלפון לא תקין',
                'pay by phone': 'תשלום טלפוני',
                'get it within': 'יישלח תוך',
				'next delivery': 'המשלוח הבא',
				'next pickup': 'שעת האיסוף הבאה',
                'changes to your cart will be lost. are you sure you want to leave?': 'קיימים שינויים בעגלה שלא נשמרו, האם ברצונכם לצאת מבלי לשמור?',
                'the following products were not saved correctly': 'המוצרים הבאים לא נשמרו כראוי',
                'the following products will not be for sale at this given time': 'המוצרים הבאים לא יהיו במבצע בזמן הנבחר',
                'shoplistname: required error': 'אנא הכניסו שם לרשימת הקניות',
                'coupon number': 'קוד קופון',
                'delivery & pickup': 'זמני משלוחים ואיסוף',
                'pickup times': 'זמני איסוף',
                'store map': 'מפה',
                'none': 'אין',
                'look for the express pickup icon': 'שימו לב לסמל האיסוף המהיר',
                'look for the express delivery icon': 'שימו לב לסמל המשלוח המהיר',
                'invalid coupon code': 'קוד הקופון אינו חוקי',
                'we couldn\'t find a matching coupon code': 'קוד הקופון לא נמצא במערכת',
                'where would you like to shop': 'איפה תרצו לקנות',
                'drop files here to upload or click to browse...': 'גררו קבצים לכאן או לחצו כדי להעלות...',
                'drag files or browse...': 'גררו קבצים לכאן או לחצו כדי להעלות...',
                'attachments': 'קבצים מצורפים',
                'link': 'קישור',
                'select the store closer to your home': 'בחרו את החנות הקרובה ביותר לביתכם',
                'for this store we offer': 'לחנות זו אנו מציעים',
                'buy from this store': 'קנו מחנות זו',
                'deliveries': 'משלוחים',
                'dear customer': 'לקוח/ה יקר/ה',
                'please notice': 'שימו לב',
                'pseudoproductname: required error': 'אנא הכניסו שם מוצר',
                'pseudoproductname: maxlength error': 'שם המוצר אינו יכול להכיל יותר מ-200 תווים',
                'area': 'אזור',
                'recipe': 'מתכון מומלץ',
                'steps': 'שלבים',
                'more info': 'מידע נוסף',
                'product description': 'תיאור המוצר',
                'ingredients': 'רכיבים',
                'more information': 'מידע נוסף',
                'directions': 'הוראות הכנה',
                'nutrition facts': 'סימון תזונתי',
                'nutrition values': 'ערכים תזונתיים',
                'serving size': 'גודל מנה',
                'servings per container': 'תכולה',
                'amount per serving': 'משקל ליחידה',
                'calories': 'קלוריות(אנרגיה)',
                'calories from fat': 'קלוריות משומן',
                'daily value': 'ערך יומי',
                'total fat': 'שומנים',
                'saturated fat': 'מתוכם שומן רווי',
                'trans fat': 'מתוכם שומן טראנס',
                'cholesterol': 'כולסטרול',
                'sodium': 'נתרן',
                'total carbohydrate': 'פחמימות',
                'dietary fiber': 'סיבים תזונתיים',
                'sugars': 'סוכרים',
                'protein': 'חלבונים',
                'vitamin a': 'ויטמין A',
                'vitamin c': 'ויטמין C',
                'calcium': 'קלציום',
                'iron': 'ברזל',
                'g': 'ג',
                'monounsaturated fat': 'חומצות שומן חד בלתי רוויות',
                'polyunsaturated fat': 'חומצות שומן רב בלתי רוויות',
                'percent daily': 'אחוזי הצריכה היומית מבוססים על תזונה יומית בת 2,000 קלוריות. הערך היומי שלכם עשוי להיות גבוה או נמוך בהתאם לצריכת הקלוריות שלכם.',
                'something went wrong': 'משהו השתבש',
                'total price is less then the minimum price': 'הסכום הכולל נמוך מהסכום המינימלי לביצוע הזמנה',
                'not all of the given products were found': 'לא נמצאו כל המוצרים',
                'the following products are not active': 'המוצרים הבאים לא פעילים',
                'add to shopping cart': 'הוסיפו לעגלת הקניות',
                'items to cart': 'מוצרים לסל',
                'min': 'דקות',
                'from': 'מקור',
                'edit order': 'עריכת הזמנה',
                'do you want to remove / add items to your existing order': 'האם ברצונכם להסיר / להוסיף מוצרים להזמנה קיימת',
                'note': 'שימו לב',
                'prices and specials will change according to the update date': 'המחירים והמבצעים ישתנו בהתאם לתאריך העדכון',
                'more': 'עוד',
                'less': 'פחות',
                'offer not found': 'המוצר לא נמצא',
                'product info disclaimer': 'אין להסתמך על הפירוט המופיע באתר על מרכיבי המוצר, יתכנו טעויות או אי התאמות במידע, הנתונים המדויקים מופיעים על גבי המוצר. יש לבדוק שוב את הנתונים על גבי אריזת המוצר לפני השימוש.',
                'delivery hours': 'זמני משלוח',
                'enter your city name': 'הקלידו את שם העיר שלכם',
                'delivery area': 'אזור משלוח',
                'branch': 'סניף',
                'quantity limitation': 'הגבלת כמות',
                'is limited to ': 'מוגבל ל - ',
                'items only': 'פריטים בלבד',
                'cart not found': 'סל מוצרים לא נמצא',
                'existing member': 'חבר מועדון קיים',
                'connect your {clubname} membership account': 'הזדהו למועדון {clubName}',
                'join {clubname} membership account': 'הצטרפו עכשיו למועדון {clubName}',
                'to complete your purchase, join/connect to {clubname} membership account': 'להשלמת הרכישה, התחברו/הרשמו למועדון הלקוחות של {clubName}',
                'in order to complete your purchase, you must connect to the loyalty club': 'על מנת להשלים את הרכישה יש להתחבר למועדון הלקוחות',
                'there is already a loyalty club card with your details but it has expired, please connect to the club and renew it': 'כבר קיים כרטיס מועדון שתואם לפרטים שהכנסת, אך הוא פג תוקף. יש להתחבר למועדון ולחדש את התוקף',
                'customer id': 'מספר מועדון',
                'connect': 'התחברות',
                'connect_to_loyalty': 'הזדהות',
                'could save': 'יכולתם לחסוך',
                'continue without membership': 'המשיכו בלי מועדון',
                'continue without club discounts': 'המשיכו בלי הטבות מועדון',
                'you are now logged in and connected to your membership': '',
                'membership club': 'מועדון לקוחות',
                'connect to our membership club': 'טרם התחברתם למועדון הלקוחות',
                'membership club number': 'מספר מועדון',
                'membership club special deals': 'מבצעי מועדון',
                'membership_club': 'מועדון',
                '{clubname} special deals': 'מבצעי {clubname}',
                'expiration date': 'תאריך תפוגה',
                'you already a registered member': 'אתם כבר חברים רשומים במועדון',
                'we did not find your membership club account': 'לא נמצא חשבון חבר מועדון',
                'sorry': 'לצערנו',
                'you are now connected to your membership club account': 'התחברתם בהצלחה למועדון הלקוחות',
                'you have successfully registered to {clubname}. you will need to connect as a club member, once you receive your credit card': 'ההרשמה ל{clubname} הסתיימה בהצלחה. יש להזדהות כחבר מועדון עם קבלת הכרטיס',
                'the registration process will end when you will pay a membership fee at checkout': 'תהליך ההרשמה יסתיים כשתשלמו את דמי החבר בסיום הקניה',
                'remove credit card info': 'הסרת מידע על כרטיס אשראי',
                'are you sure you want to remove credit card information': 'האם אתם בטוחים שאתם רוצים למחוק את המידע על כרטיס האשראי',
                'remove paypal account': 'הסרת חשבון PayPal',
                'are you sure you want to remove this paypal account': 'האם אתם בטוחים שאתם רוצים למחוק את חשבון ה-PayPal',
                'are you sure you want to cancel your order update and return to your original order': 'האם אתם בטוחים שאתם רוצים לבטל את עריכת ההזמנה ולחזור אל ההזמנה המקורית שלכם',
                'print': 'הדפס',
                'track order': 'הזמנה מספר',
                'view order': 'צפייה בהזמנה',
                'view details': 'פרטי הזמנה',
                'replaced with' : 'מוצר חלופי',
                'order time': 'זמן הזמנה',
                'new product': 'מוצר חדש',
                'credit card charge': 'חיוב בכרטיס אשראי',
                'substitutions':'החלפות',
                'total (estimated)': 'סה׳כ (משוער)',
                'number of provided products': 'מספר פריטים שסופקו',
                'forgot something': 'שכחת משהו',
                'update order': 'עדכון הזמנה',
                'update order failed': 'עדכון הזמנה נכשל',
                'payment method ending with':'אמצעי תשלום מסתיים ב',
                'order received': 'התקבלה הזמנה',
                'order is in process, no changes can be made at this time': 'ההזמנה בתהליך ליקוט, לא ניתן לבצע שינויים בשלב זה',
                'order in process': 'הזמנתך בטיפול',
                'order ready': 'הזמנתך מוכנה',
                'in process': 'בטיפול',
                'ready': 'מוכן',
                'need help': 'זקוקים לעזרה',
                'edit your order': 'עדכנו את ההזמנה',
                'your order': 'ההזמנה שלכם',
                'has been updated': 'התעדכנה',
                'please check your email for order details': 'בבקשה בדקו את הדואר האלקטרוני שלכם כדי לראות את פרטי ההזמנה',
                'thank you': 'תודה רבה',
                'we have started collecting your original order.': 'התחלנו ללקט את הזמנתכם',
                'we have started collecting your order.': 'התחלנו ללקט את הזמנתכם',
                'we have finished collecting your original order.': 'סיימנו כבר ללקט את הזמנתכם',
                'this is a canceled order.': 'הזמנה זו הינה הזמנה שבוטלה',
                'we have finished collecting your order.': 'סיימנו כבר ללקט את הזמנתכם',
                'your order can no longer be changed': 'כבר לא ניתן לערוך אותה',
                'your order cannot be changed at this time': 'כבר לא ניתן לערוך אותה',
                'cancel update': 'ביטול עדכון',
                'canceling': 'ביטול',
                'update': 'עדכון',
                'replace shopping cart': 'החלפת המוצרים בעגלה',
                'are you sure you want to replace your shopping cart with the order from': 'האם אתם בטוחים שאתם רוצים להחליף את המוצרים בסל הקניות עם המוצרים שהזמנתם בתאריך',
                'replace': 'להחליף',
                'replace_2': 'החלפה',
                'you can still edit your order': 'אתם עדיין יכולים לערוך את הזמנתכם',
                'your original order is': 'חוזר אל',
                'cancel order update': 'ביטול עדכון הזמנה',
                'in process ': 'ההזמנה המקורית שלכם',
                'continue as new cart': 'יצירת עגלה חדשה',
                'add your credit card': 'פרטי כרטיס אשראי',
                'add your credit/debit cards': 'הוספת כרטיס אשראי',
                'use my delivery address as my billing address*': 'השתמשו בכתובת המשלוח שלי ככתובת החיוב*',
                'add your credit/debit card': 'פרטי כרטיס אשראי',
                '*by checking this option you approve transfer of your address data to the payment provider': '*בסימון אפשרות זו אתם מאשרים להעביר את נתוני הכתובת שלכם לספק התשלומים',
                'you can move the selected items into a new order': 'הינכם יכולים להעביר את המוצרים שבחרתם לעגלת קניות חדשה',
                'paypal': 'PayPal',
                'icreditmasterpass': 'Master Pass',
                'cost': 'מחיר',
                'please wait': 'אנא המתינו',
                'checking your address': 'בודקים את כתובתיכם',
                'missing details': 'פרטים חסרים',
                'the server check your address': 'אנו בודקים אם יש משלוחים לכתובת שהזנת',
                'please enter your delivery address in order to see available delivery times': 'מלאו את כתובתיכם על מנת לראות את זמני המשלוח',
                'your cart is empty': 'העגלה שלכם ריקה',
                'housenum: max error': 'אנא הכניסו מספר הקטן מ 3000',
                'personalid: required error': 'אנא הכניסו את מספר תעודת הזהות שלכם',
                'personalid: pattern error': 'מספר תעודת זהות לא חוקי',
                'customerid: required error': 'אנא הכניסו את מספר הלקוח שלכם',
                'storecredit: required error': 'אנא הכניסו את פרטי התשלום שלכם',
                'special_exp': 'בתוקף עד',
                'special_exp_new': 'בתוקף עד',
                'close more delivery times': 'סגירת זמני משלוח נוספים',
                'logo {retailer_title} home page link': 'לוגו {retailer_title} קישור לדף הבית',
                'en': 'he',
                'product properties': 'מאפייני מוצר',
                'product properties need to be selected for': 'יש לבחור מאפייני מוצר ל',
                'categories': 'קטגוריות',
                'enter specials department': 'כניסה למחלקת מבצעים',
                'order by': 'מיון לפי',
                'start shopping': 'התחלת קנייה',
                'choose an order': 'בחרו הזמנה',
                'load more': 'טען עוד',
                'restore': 'החזר לעגלה',
                'newsletter': 'עלון',
                'sign up for the newsletter': 'הרשמה לניוזלטר',
                'show more': 'הצג הכל',
                'show less': 'הצג פחות',
                'savings': 'חיסכון',
                'membership savings': 'חסכון מועדון',
                'potential membership savings': 'יכולת לחסוך במועדון',
                'select delivery date & time': 'בחירת תאריך ושעה למשלוח',
                'select pickup date & time': 'בחירת תאריך ושעה לאיסוף עצמי',
                'the expiration of these': 'תוקפם של',
                'sales will not be valid for this chosen time': 'המבצעים המוצגים בהמשך יפוג ביום אספקת ההזמנה',
                'the special displayed below, will expire on the date you are supposed to receive your order': 'תוקף המבצע יפוג ביום אספקת ההזמנה',
                'these items will be charged at their regular price': 'מוצרים אלו יחויבו במחיר מחירון ללא מבצע',
                'this product will be charged the regular price, promotion will not be applied': 'מוצר זה יחויב במחיר מחירון ללא מבצע',
                'price of updated cart (est.)': 'עלות עגלה מעודכן (מוערך)',
                'price of updated service fee (est.)': 'עלות דמי שירות (מוערך)',
                'welcome': 'ברוכים הבאים',
                'please enter a zip code': 'הקלידו מיקוד',
                'please enter a city': 'הקלידו עיר או ישוב המשלוח',
                'please enter a delivery address': 'אנא הקלידו כתובת למשלוח',
                'verify_1': 'איסור מכירה',
                'verify_2': 'לקטינים',
                'verify_3': 'מכירת מוצר זה למי שטרם מלאו לו',
                'verify_4': '',
                'verify_5': 'אסורה בהחלט',
                'verify_6': '',
                'verify_7': 'מלאו',
                'verify_8': 'לי',
                'verify_9': 'טרם מלאו',
                'years': 'שנים',
                'sorry_2': 'מצטערים',
                'you cannot purchase': 'אינכם יכולים לרכוש',
                'this item': 'מוצר זה',
                'login_tab_title': 'כניסה לאתר',
                'register_tab_title': 'הרשמה לאתר',
                'club member name': 'שם חבר מועדון',
                'our top picks': 'המומלצים שלנו',
                'got it': 'התחילו קניה',
                'friend\'s email': 'דוא"ל של מוזמן',
                'or share your promo code': 'או שתפו את קוד המבצע',
                'copy': 'העתק',
                'send invitation': 'שלחו הזמנה',
                'invitation sent successfully': 'ההזמנה נשלחה בהצלחה',
                'congratulations': 'במיוחד בשבילך',
                'shop now': 'התחילו קניה',
                'shop': 'קנו',
                'your order is being edited by another device': 'ההזמנה שלך נערכת במכשיר אחר',
                'main menu': 'תפריט ראשי',
                'invalid cart id': 'מספר עגלה לא חוקי',
                'loyalty club member': 'חברי מועדון',
                'in this purchase': 'כבר בקניה זו',
                'save_1': 'חסכו',
                'received_items': 'התקבל',
                'not a member yet? join now!': 'עדיין לא חברי מועדון? הצטרפו וחסכו!',
                'continue without loyalty': 'המשיכו ללא מועדון',
                'please type in your zip code': 'נא להקליד את המיקוד שלך',
                'find address': 'מצא כתובת',
                'you cannot edit this field before entering a zip code for your address': 'אין אפשרות לערוך שדה זה לפני הכנסת מיקוד לפי הכתובת שלך',
                'please choose your address': 'יש לבחור את הכתובת שלך',
                'you will be able to edit the address details': 'תהיה אפשרות לערוך את פרטי הכתובת',
                'you cannot edit this field': 'אין אפשרות לערוך שדה זה',

                /*pele card errors*/
                "invalid card": "כרטיס לא תקין",
                "permitted transaction.": "עסקה תקינה.",
                "the card is blocked, confiscate it.": "חסום החרם כרטיס.",
                "the card is stolen, confiscate it.": "גנוב החרם כרטיס.",
                "contact the credit company.": "התקשר לחברת האשראי.",
                "refusal by credit company.": "סירוב.",
                "the card is forged, confiscate it.": "מזויף החרם כרטיס.",
                "incorrect cvv/id.": "מספר תעודת זהות ו/או מספר ה-CVV לא תואם למספר הכרטיס." /*"חובה להתקשר לחברת האשראי."*/,
                "an error occurred while building access key for blocked card files.": "תקלה בבניית מפתח גישה לקובץ חסומים.",
                "no communication. please try again or contact system administration": "לא הצליח להתקשר.",
                "the program was stopped by user`s command (esc) or com port can't be open (windows)": "תוכנית הופסקה עפ\"י הוראת המפעיל (ESC) או COM PORT לא ניתן לפתיחה (WINDOWS).",
                "the acquirer is not authorized for foreign currency transactions": "אין אישור סולק למטבע איזו.",
                "this card is not permitted for foreign currency transactions": "אין אישור למותג למטבע איזו.",
                "this card is not supported.": "כרטיס לא נתמך.",
                "track 2 (magnetic) does not match the typed data.": "אין התאמה בין המספר שהוקלד לפס המגנטי.",
                "additional required data was entered/not entered as opposed to terminal settings (z field).": "נתונים נוספים אינם או ישנם בניגוד להגדרות המסוף.",
                "last 4 digits were not entered (w field).": "לא הוקלדו 4 ספרות האחרונות.",
                "entry in int_in file is shorter than 16 characters.": "רשומה בקובץ INT_IN קצרה מ- 16 תווים.",
                "the input file (int_in) does not exist.": "קובץ קלט (IN_INT) לא קיים.",
                "blocked cards file (neg) does not exist or has not been updated, transmit or request authorization for each transaction.": "קובץ חסומים (NEG) לא קיים או לא מעודכן - בצע שידור או בקשה לאישור עבור כל עסקה.",
                "one of the parameter files/vectors does not exist.": "אחד מקבצי פרמטרים או ווקטורים לא קיים.",
                "date file (data) does not exist.": "קובץ תאריכים (DATA) לא קיים.",
                "format file (start) does not exist.": "קובץ אתחול (START) לא קיים.",
                "the difference in days in the blocked cards input is too large, transmit or request authorization for each transaction.": "הפרש בימים בקליטת חסומים גדול מדי - בצע שידור או בקשה לאישור עבור כל עסקה.",
                "the difference in generations in the blocked cards input is too large, transmit or request authorization for each transaction.": "הפרש דורות בקליטת חסומים גדול מידי - בצע שידור או בקשה לאישור עבור כל עסקה.",
                "when the magnetic strip is not completely entered, define the transaction as a telephone number or signature only.": "כאשר לא הוכנס פס מגנטי כולו הגדר עסקה כעסקה טלפונית או כעסקת חתימה בלבד.",
                "the central terminal number was not entered into the defined main supplier terminal.": "מספר מסוף מרכזי לא הוכנס למסוף המוגדר לעבודה כרב ספק.",
                "the beneficiary number was not entered into the defined main beneficiary terminal.": "מספר מוטב לא הוכנס למסוף המוגדר לעבודה כרב מוטב.",
                "the supplier/beneficiary number was entered, however the terminal was not updated as the main supplier/beneficiary.": "מסוף שאינו מעודכן כרב ספק/רב מוטב והוקלד מס' ספק/מס' מוטב.",
                "the beneficiary number was entered, however the terminal was updated as the main supplier": "מסוף מעודכן כרב ספק והוקלד גם מס' מוטב.",
                "old transactions, transmit or request authorization for each transaction.": "תנועות ישנות בצע שידור או בקשה לאישור עבור כל עסקה.",
                "defective card.": "טעות בהקלדת כרטיס האשראי, אנא נסה שוב." /*"כרטיס לא תקין."*/,
                "this card is not permitted for this terminal or is not authorized for this type of transaction.": "כרטיס לא רשאי לבצע במסוף זה או אין אישור לעסקה כזאת.",
                "this card is not permitted for this transaction or type of credit.": "כרטיס לא רשאי לבצע עסקה עם סוג אשראי זה.",
                "expired card.": "פג תוקף.",
				"expired token": "קישור פג תוקף",
				"installment error, the amount of transactions needs to be equal to: first installment plus fixed installments times number of installments.": "שגיאה בתשלומים-סכום עסקה צריך להיות שווה תשלום ראשון + (תשלום קבוע כפול מס' תשלומים).",
                "unable to execute a debit transaction that is higher than the credit card`s ceiling.": "לא ניתן לבצע עסקה מעל תקרה לכרטיס לאשראי חיוב מיידי.",
                "incorrect control number.": "טעות בהקלדת כרטיס האשראי, אנא נסה שוב." /*"סיפרת בקורת לא תקינה."*/,
                "the beneficiary and supplier numbers were entered, however the terminal is defined as main.": "מסוף שמוגדר כרב מוטב הוקלד מס' ספק.",
                "the transaction`s amount exceeds the ceiling when the input file contains j1, j2 or j3 (contact prohibited).": "מעל תקרה כאשר קובץ הקלט מכיל 3J או 2J או 1J (אסור להתקשר).",
                "the card is blocked for the supplier where input file contains j1, j2 or j3 (contact prohibited).": "כרטיס חסום בספק כאשר קובץ הקלט מכיל 3J או 2J או 1J (אסור להתקשר).",
                "random where input file contains j1 (contact prohibited).": "אקראית כאשר קובץ הקלט מכיל J1 (אסור להתקשר).",
                "the terminal is prohibited from requesting authorization without transaction (j5).": "מסוף לא רשאי לבקש אישור ללא עסקה (5J).",
                "the terminal is prohibited for supplier-initiated authorization request (j6).": "מסוף לא רשאי לבקש אישור ביוזמת קמעונאי (6J).",
                "the terminal must request authorization where the input file contains j1, j2 or j3 (contact prohibited).": "מסוף חייב לבקש אישור כאשר קובץ הקלט מכיל 3J או 2J או 1J (אסור להתקשר).",
                "secret code must be entered where input file contains j1, j2 or j3 (contact prohibited).": "חייב להקליד מספר סודי, כאשר קובץ הקלט מכיל J3 או 2J או 1J (אסור להתקשר).",
                "incorrect vehicle number.": "מספר רכב לא תקין.",
                "the number of the distance meter was not entered.": "מד מרחק לא הוקלד.",
                "the terminal is not defined as gas station (petrol card or incorrect transaction code was used).": "מסוף לא מוגדר כתחנת דלק (הועבר כרטיס דלק או קוד עסקה לא מתאים).",
                "an id number is required (for israeli cards only) but was not entered.": "לא הוקלד מספר תעודת זהות.",
                "cvv is required but was not entered.": "לא הוקלד CVV2",
                "cvv and id number are required (for israeli cards only) but were not entered.": "לא הוקלדו מספר תעודת הזהות ו – CVV2 .",
                "abs attachment was not found at the beginning of the input data in memory.": "צרוף ABS לא נמצא בהתחלת נתוני קלט בזיכרון.",
                "the card number was either not found or found twice.": "מספר כרטיס לא נמצא או נמצא פעמיים.",
                "incorrect transaction type.": "סוג עסקה לא תקין.",
                "incorrect transaction code.": "קוד עסקה לא תקין.",
                "incorrect credit type.": "סוג אשראי לא תקין.",
                "incorrect currency.": "מטבע לא תקין.",
                "the first installment and/or fixed payment are for non-installment type of credit.": "קיים תשלום ראשון ו/או תשלום קבוע לסוג אשראי שונה מתשלומים.",
                "number of installments exist for the type of credit that does not require this.": "קיים מספר תשלומים לסוג אשראי שאינו דורש זה.",
                "linkage to dollar or index is possible only for installment credit.": "לא ניתן להצמיד לדולר או למדד לסוג אשראי שונה מתשלומים.",
                "the magnetic strip is too short.": "אורך הפס המגנטי קצר מידי.",
                "the pin code device is not defined.": "לא מוגדר מכשיר להקשת מספר סודי.",
                "must enter the pin code number.": "חובה להקליד מספר סודי.",
                "smart card reader not available - use the magnetic reader.": "קכ\"ח לא זמין – העבר בקורא מגנטי.",
                "must use the smart card reader.": "חובה להעביר כרטיס בקכ\"ח.",
                "denied - locked card.": "דחייה – כרטיס נעול.",
                "denied - smart card reader action didn't end in the correct time.": "דחייה – פעולה עם קכ\"ח לא הסתיימה בזמן הראוי.",
                "denied - data from smart card reader not defined in system.": "דחייה – נתונים אשר התקבלו מקכ\"ח אינם מוגדרים במערכת.",
                "incorrect pin code.": "הוקלד מספר סודי שגוי.",
                "currency does not exist in vector 59.": "מטבע לא קיים בווקטור 59.",
                "the club code entered does not match the credit type.": "הוכנס \"קוד מועדון\" לסוג אשראי לא מתאים.",
                "cannot cancel charge transaction. please create a credit transaction.": "עסקת ביטול אסורה בכרטיס יש לבצע עסקת זיכוי.",
                "unable to read/write/open the tran file.": "לא מצליח לקרוא/ לכתוב/ לפתוח קובץ TRAN.",
                "no authorization from credit company for clearance.": "הכרטיס שהוקלד אינו נתמך ע\"י בית העסק." /*"אין אישור מחברת אשראי לעבודה."*/,
                "the terminal is not permitted to send queries for immediate debit cards.": "למסוף אין אישור לביצוע שאילתא לאשראי חיוב מיידי.",
                "the transaction amount is too large, divide it into a number of transactions.": "סכום העסקה גדול מידי - חלק במספר העסקאות.",
                "the terminal is not authorized to execute forced transactions.": "למסוף אין אישור לבצע עסקאות מאולצות.",
                "the terminal is not authorized for cards with service code 587.": "למסוף אין אישור לכרטיס עם קוד השרות 587.",
                "the terminal is not authorized for immediate debit cards.": "למסוף אין אישור לכרטיס חיוב מיידי.",
                "the terminal is not authorized for installment transactions.": "למסוף אין אישור לעסקה בתשלומים.",
                "the terminal is authorized for installment transactions only, not telephone/signature.": "למסוף אין אישור לעסקה טלפון/ חתימה בלבד בתשלומים.",
                "the terminal is not authorized for telephone transactions.": "למסוף אין אישור לעסקה טלפונית.",
                "the terminal is not authorized for signature-only transactions.": "למסוף אין אישור לעסקה \"חתימה בלבד\".",
                "the terminal is not authorized for foreign currency transactions, or transaction is not authorized.": "למסוף אין אישור לעסקה בדולרים.",
                "the terminal is not authorized for club transactions.": "למסוף אין אישור לעסקת מועדון.",
                "the terminal is not authorized for star/point/mile transactions.": "למסוף אין אישור לעסקת כוכבים/נקודות/מיילים.",
                "the terminal is not authorized for isracredit credit.": "למסוף אין אישור לאשראי ישראקרדיט.",
                "the terminal is not authorized for amex credit.": "למסוף אין אישור לאשראי אמקס קרדיט.",
                "the terminal is not authorized for dollar linkage.": "למסוף אין אישור להצמדה לדולר.",
                "the terminal is not authorized for index linkage.": "למסוף אין אישור להצמדה למדד.",
                "the terminal is not authorized for index linkage with foreign cards.": "למסוף אין אישור להצמדה למדד לכרטיסי חו\"ל.",
                "the terminal is not authorized for star/point/mile transactions for this type of credit.": "למסוף אין אישור לעסקת כוכבים/נקודות/מיילים לסוג אשראי זה.",
                "the terminal is not authorized for isra 36 credit.": "למסוף אין אישור לאשראי ישרא 36.",
                "the terminal is not authorized for amex 36 credit.": "למסוף אין אישור לאשראי אמקס 36.",
                "the terminal is not authorized for this club code.": "למסוף אין אישור לקוד מועדון זה.",
                "the terminal is not authorized for immediate debit transactions (except for immediate debit cards).": "למסוף אין אישור לעסקת חיוב מיידי פרט לכרטיסי חיוב מיידי.",
                "the terminal is not authorized to accept visa card staring with 3.": "למסוף אין אישור לקבל כרטיסי ויזה אשר מתחילים ב - 3.",
                "the terminal is not authorized to execute credit transactions above the ceiling.": "למסוף אין אישור לבצע עסקת זכות מעל תקרה.",
                "the card is not permitted to execute club transactions.": "כרטיס לא רשאי לבצע עסקת מועדון.",
                "the card is not permitted to execute star/point/mile transactions.": "כרטיס לא רשאי לבצע עסקת כוכבים/נקודות/מיילים.",
                "the card is not permitted to execute dollar transactions (regular or telephone).": "כרטיס לא רשאי לבצע עסקאות בדולרים (רגילות או טלפוניות).",
                "the card is not valid according to isracard`s list of valid cards.": "כרטיס לא תקף על פי רשימת כרטיסים תקפים של ישראכרט.",
                "defective card according to system definitions (isracard vector1), error in the number of figures on the card.": "כרטיס לא תקין עפ”י הגדרת המערכת (VECTOR1 של ישראכרט)- מס' הספרות בכרטיס- שגוי.",
                "the card is not permitted to execute dollar transactions according to system definitions (isracard vector1).": "כרטיס לא רשאי לבצע עסקאות דולריות עפ”י הגדרת המערכת (VECTOR1 של ישראכרט).",
                "the card belongs to a group that is not permitted to execute transactions according to system definitions (visa vector 20).": "הכרטיס שייך לקבוצת כרטיסים אשר אינה רשאית לבצע עסקאות עפ”י הגדרת המערכת (VECTOR20 של ויזה).",
                "the card`s prefix (7 figures) is invalid according to system definitions (diners vector21).": "קידומת הכרטיס (7 ספרות) לא תקפה עפ”י הגדרת המערכת (21VECTOR של דיינרס).",
                "the card is not permitted to carry out installment transactions according to isracard`s list of valid cards.": "כרטיס לא רשאי לבצע עסקאות בתשלומים על פי רשימת כרטיסים תקפים של ישראכרט.",
                "the number of installments is too large according to isracard`s list of valid cards.": "מספר תשלומים גדול מידי על פי רשימת כרטיסים תקפים של ישראכרט.",
                "visa and diners cards are not permitted for club installment transactions.": "כרטיסי ויזה ודיינרס לא רשאים לבצע עסקאות מועדון בתשלומים.",
                "series of cards are not valid according to system definition (isracard vector5).": "סידרת כרטיסים לא תקפה עפ”י הגדרת המערכת (VECTOR5 של ישראכרט).",
                "invalid service code according to system definitions (isracard vector6).": "קוד שרות לא תקף עפ”י הגדרת המערכת (VECTOR6 של ישראכרט).",
                "the card`s prefix (2 figures) is invalid according to system definitions (isracard vector7).": "קידומת הכרטיס (2 ספרות) לא תקפה עפ”י הגדרת המערכת (VECTOR7 של ישראכרט).",
                "invalid service code according to system definitions (visa vector12).": "קוד שרות לא תקף עפ”י הגדרת המערכת (VECTOR12 של ויזה).",
                "invalid service code according to system definitions (visa vector13).": "קוד שרות לא תקף עפ”י הגדרת המערכת (VECTOR13 של ויזה).",
                "immediate debit card is prohibited for executing credit transaction.": "לכרטיס חיוב מיידי אסור לבצע עסקת זכות.",
                "the card is not permitted to execute installment transactions according to alpha vector no. 31.": "כרטיס לא רשאי לבצע עסקאות בתשלומים עפ\"י וקטור 31 של לאומי קארד.",
                "the card is not permitted for telephone and signature-only transactions according to alpha vector no. 31.": "כרטיס לא רשאי לבצע עסקאות טלפוניות וחתימה בלבד עפ\"י ווקטור 31 של לאומי קארד.",
                "the card is not permitted for telephone transactions according to alpha vector no. 31.": "כרטיס אינו רשאי לבצע עסקאות טלפוניות עפ\"י וקטור 31 של לאומי קארד.",
                "credit is not approved for immediate debit cards.": "אשראי לא מאושר לכרטיסי חיוב מיידי.",
                "credit is not approved for foreign cards.": "אשראי לא מאושר לכרטיסי חו\"ל.",
                "incorrect club code.": "קוד מועדון לא תקין.",
                "the card is not permitted to execute flexible credit transactions (adif/30+) according to system definitions (diners vector21).": "כרטיס לא רשאי לבצע עסקאות אשראי גמיש (עדיף +30/) עפ\"י הגדרת המערכת (21VECTOR של דיינרס).",
                "the card is not permitted to execute immediate debit transactions according to system definitions (diners vector21).": "כרטיס לא רשאי לבצע עסקאות חיוב מיידי עפ\"י הגדרת המערכת (VECTOR21 של דיינרס).",
                "the payment amount is too low for credit transactions.": "סכום לתשלום בעסקת קרדיט קטן מידי.",
                "incorrect number of installments for credit transaction.": "מספר תשלומים לעסקת קרדיט לא תקין.",
                "zero ceiling for this type of card for regular credit or credit transaction.": "תקרה 0 לסוג כרטיס זה בעסקה עם אשראי רגיל או קרדיט.",
                "zero ceiling for this type of card for immediate debit credit transaction.": "תקרה 0 לסוג כרטיס זה בעסקה עם אשראי חיוב מיידי.",
                "zero ceiling for this type of card for immediate debit in dollars.": "תקרה 0 לסוג כרטיס זה בעסקת חיוב מיידי בדולרים.",
                "zero ceiling for this type of card for telephone transaction.": "תקרה 0 לסוג כרטיס זה בעסקה טלפונית.",
                "zero ceiling for this type of card for credit transaction.": "תקרה 0 לסוג כרטיס זה בעסקת זכות.",
                "zero ceiling for this type of card for installment transaction.": "תקרה 0 לסוג כרטיס זה בעסקת תשלומים.",
                "american express card issued abroad not permitted for instalments transaction.": "כרטיס אמריקן אקספרס אשר הונפק בחו\"ל לא רשאי לבצע עסקאות בתשלומים.",
                "jcb cards are only permitted to carry out regular credit transactions.": "כרטיסי JCB רשאים לבצע עסקאות רק באשראי רגיל.",
                "the amount in stars/points/miles is higher than the transaction amount.": "סכום בכוכבים/נקודות/מיילים גדול מסכום העסקה.",
                "the club card is not within terminal range.": "כרטיס מועדון לא בתחום של המסוף.",
                "star/point/mile transactions cannot be executed.": "לא ניתן לבצע עסקת כוכבים/נקודות/מיילים בדולרים.",
                "dollar transactions cannot be executed for this type of card.": "למסוף אין אישור לעסקה דולרית עם סוג אשראי זה.",
                "credit transactions cannot be executed with other than regular credit.": "לא ניתן לבצע עסקת זכות עם אשראי שונה מהרגיל",
                "amount of discount on stars/points/miles is higher than the permitted.": "סכום הנחה בכוכבים/נקודות/מיילים גדול מהמותר.",
                "forced transactions cannot be executed with credit/immediate debit card.": "לא ניתן לבצע עסקה מאולצת לכרטיס/אשראי חיוב מיידי.",
                "the previous transaction cannot be cancelled (credit transaction or card number are not identical).": "לא ניתן לבטל עסקה קודמת (עסקת זכות או מספר כרטיס אינו זהה).",
                "double transaction.": "עסקה כפולה.",
                "the terminal is not permitted for index linkage of this type of credit.": "למסוף אין אישור להצמדה למדד לאשראי זה.",
                "the terminal is not permitted for dollar linkage of this type of credit.": "למסוף אין אישור להצמדה לדולר לאשראי זה.",
                "the card is invalid according to system definitions (isracard vector1).": "כרטיס אינו תקף עפ”י הגדרת ה מערכת (וקטור 1 של ישראכרט).",
                "unable to execute the self-service transaction if the gas station does not have self service.": "תחנות דלק לא ניתן לבצע \"שרות עצמי\" אלא \"שרות עצמי בתחנות דלק\".",
                "credit transactions are forbidden with stars/points/miles.": "אסור לבצע עיסקת זכות בכוכבים/נקודות/מיילים.",
                "dollar credit transactions are forbidden on tourist cards.": "אסור לבצע עיסקת זכות בדולר בכרטיס תייר.",
                "phone transactions are not permitted on club cards.": "בכרטיס מועדון לא ניתן לבצע עסקה טלפונית.",
                "application error.": "שגיאה יישומית.",
                "transaction amount missing or zero.": "סכום העסקה חסר או אפס.",
                "no communication to pelecard.": "אין תקשורת לפלא-קארד.",
                "doubled transaction.": "עסקה כפולה.",
                "terminal number does not exist.": "מספר מסוף לא קיים.",
                "terminal executes broadcast and/or updating data. please try again later.": "מסוף מבצע שידור ו/או מעדכן נתונים אנא נסה שנית מאוחר יותר.",
                "user name and/or password not correct. please call support team.": "שם משתמש ו/או סיסמה לא נכונים אנא פנה למחלקת התמיכה.",
                "user password has expired. please contact support team.": "פג תוקף סיסמת המשתמש אנא פנה למחלקת התמיכה.",
                "locked user. please contact support team.": "משתמש נעול אנא פנה למחלקת התמיכה.",
                "blocked terminal. please contact account team.": "מסוף חסום אנא פנה להנהלת חשבונות.",
                "token number abnormal.": "מספר טוקן לא תקין.",
                "user is not authorized in this terminal.": "משתמש לא רשאי לבצע פעולות במסוף זה.",
                "validity structure invalid. use mmyy structure only.": "מבנה תוקף לא תקין יש להשתמש במבנה MMYY בלבד.",
                "ssl verifying access is blocked. please contact support team.": "גישה לאימות תעודת אבטחה חסומה אנא פנה למחלקת התמיכה.",
                "data not exist.": "לא קיימים נתונים.",
                "general error. please contact support team.": "שגיאה כללית אנא פנה למחלקת התמיכה.",
                "necessary values are blocked/wrong.": "ערכים נחוצים חסרים/שגויים.",
                "general error. repeat action.": "שגיאה כללית אנא פנה למחלקת התמיכה.",
                "necessary values missing to complete installments transaction": "ערכים נחוצים חסרים לעסקת תשלומים.",
                /*end pele card errors*/

                'video': 'וידאו',
                'user not found': 'שם משתמש או סיסמה שגוים',
                'in {name} dep.': 'במחלקת {name}',
                'in specials': 'במבצעים',
                'coupons': 'קופונים',
                'my coupons': 'הקופונים שלי',
                'no coupons available at this time. check again later or check our specials!': 'אין קופונים זמינים כעת. אנא נסו שנית מאוחר יותר או בדקו את המבצעים שלנו.',
                'giftcards.0: gift card must be up to 255 characters error': 'תו קניה יכול להכיל עד 255 תווים',
                'your membership has expired': 'פג תוקף חברותך במועדון',
                'hello': 'שלום',
                'your club membership has expired on': 'פג תוקף חברותך במועדון בתאריך',
                'membership number': 'מספר חבר',
                'renew you membership and enjoy exclusive benefits and discounts': 'חדשו את חברותכם במועדון ותיהנו מהטבות ומבצעים כבר בקניה זו',
                'please call customer service for renewal': 'לחידוש החברות וניצול ההטבות בקניה זו התקשרו לשירות הלקוחות',
                'loyalty registration was not fully completed, registration voucher will be sent by mail': 'תהליך ההרשמה לא הסתיים באופן מלא. שובר ההטבה ישלח אליכם בדואר עם סיום התהליך',
                'phone number does not match the existing phone number on the system': 'מספר הטלפון לא תואם למספר הקיים במערכת',
                'pay attention': 'שימו לב',
                'enter a valid number': 'הזנת מספר מועדון תקף',
                'paying with different payment method will': 'הטבות המועדון תקפות רק בתשלום בכרטיס אשראי מועדון.',
                'discard your loyalty club discounts': 'תשלום באמצעי תשלום אחר יביא לביטול הטבות שהתקבלו',
                'do you want to continue': 'האם ברצונכם להמשיך',
                'return and pay with loyalty credit card': 'חזור לבחירת כרטיס אשראי מועדון',
                'no thanks, continue shopping': 'המשך ללא הטבות מועדון',
                'renew membership': 'חידוש החברות במועדון',
                'loyalty membership renewal': 'חידוש החברות במועדון',
                'your membership has been renewed successfully': 'חידוש החברות למועדון הסתיים בהצלחה',
                'you can enjoy from the club benefits in this order': 'תוכלו להנות מהטבות המועדון כבר בהזמנה זו',
                'continue': 'להמשיך',
                'loyalty card id': 'מספר כרטיס מועדון',
                'loyalty': 'מועדון',
                'clip & add': 'הוסף',
                'clip': 'הוסף',
                'personal id': 'מספר תעודת זהות',
                'we are not able to register your membership club account': 'לא ניתן להצטרף למועדון',
                'please make sure your details are correct or try again later': 'אנא וודאו כי הפרטים שהזנתם נכונים או נסו שנית מאוחר יותר',
                'new member': 'הרשמה למועדון',
                '{retailername} membership offers exclusive': 'חברות במועדון {retailername} מעניקה לכם',
                'promotions in-store and online': 'הטבות, הנחות ומבצעים ייחודיים',
                'join our membership and enjoy exclusive benefits and promotions': 'הצטרפו למועדון הלקוחות וקבלו הטבות ייחודיות',
                'the items in your shopping cart will be cleared if you': 'מעבר לעריכת הזמנה',
                'choose to edit your previous order': 'ינקה את כל הפריטים שהוספת לסל הקניות',
                'edit order & clear cart': 'ערוך הזמנה ונקה סל',
                'it seems you are editing your order on a different device/browser.': 'אתם מבצעים עריכת הזמנה על גבי מכשיר/דפדפן אחר',
                'it seems you have finished editing your order on a different device/browser.': 'יצאתם מעריכת הזמנה ממכשיר/דפדפן אחר',
                'do you want to add the product to this new order or to the order that is being updated?': 'האם ברצונכם להוסיף את המוצר להזמנה חדשה זו, או להזמנה שנמצאת במצב עריכה',
                'note! you in the middle of editing order': 'שימו לב! הינכם נמצאים באמצע עריכת הזמנה',
                'you in the middle of editing order. changing store is impossible': 'הינכם נמצאים באמצע עריכת הזמנה. לא ניתן להחליף חנות',
                'note! it seems you have finished editing your order on a different device/browser.': 'שימו לב! סיימתם כבר לערוך את ההזמנה במכשיר/דפדפן אחר',
                'add to new order': 'הוספה להזמנה חדשה',
                'add to updated order': 'הוספה להזמנה בעריכה',
                'join our membership club': 'הצטרפו למועדון הלקוחות',
                'join': 'הצטרפו',
                'join the club': 'הצטרפות למועדון',
                'join now': 'הצטרפו עכשיו',
                'join / connect to club': 'הזדהות / הצטרפות למועדון',
                'connect / join to club': 'התחברות / הצטרפות למועדון',
                'connect to club': 'התחברות למועדון',
				'this email already exists': 'כתובת דוא"ל זו קיימת במערכת',
				'unable to create user': 'לא ניתן ליצור משתמש',
                'delivery_type_1_times': 'מועדי משלוח',
                'delivery_type_2_times': 'מועדי איסוף עצמי',
                'packed': 'נארז',
                'frombranch': 'מ',
                'special type': 'סוג מבצע',
                'select from variety': 'בחרו ממגוון מוצרים',
                'forpackquantity': 'ב',
                'pack': 'אריזת חיסכון',
                'forbidden action': 'הפעולה אינה מורשית',
                'internal server error': 'שגיאת שרת פנימית',
                'please leave the order outside the door if nobody\'s home': 'נא להשאיר את המשלוח מחוץ לדלת במידה ואין איש בבית',
                'identify': 'הזדהות',
                'show full details in {clubname} club page': 'ראו פרטים מלאים בעמוד מועדון {clubName}',
                'enter your promo code': 'הכנס קוד מבצע',
                'promo code unidentified': 'קוד מבצע לא זוהה',
                'promocode: promo code unidentified error': 'קוד מבצע לא זוהה',
                'promo code': 'קוד מבצע',
                'for {clubname} full details': 'לפרטים מלאים של {clubname}',
                'click here': 'לחצו כאן',
                'home phone number': 'מספר טלפון בבית',
                'work phone number': 'מספר טלפון בעבודה',
                'mobile phone number': 'מספר טלפון נייד',
                'special': 'מבצע',
                'check all | clicking the action buttons above as "add to cart" refers to selected items': 'סמן הכל | לחיצה על כפתורי הפעולה למעלה כמו “הוספה לסל” מתייחסת לפריטים בחורים',
                'back to previous orders': 'חזרה להזמנות קודמות',
                'save changes before leaving page?': 'לשמור שינויים לפני היציאה מהדף?',
                'don\'t save': 'אל תשמור',
                'back to my shopping lists': 'חזרה לרשימות שלי',
                'add file': 'הוספת קובץ',
                'link to a file': 'קישור לקובץ',
                'no attachments': 'אין קבצים מצורפים',
                'buy': 'קנה',
                'no purchases have been made yet previous orders will be saved on this screen': 'טרם בוצעו קניות, הזמנות קודמות ישמרו במסך זה',
                'no shopping list created yet': 'טרם נוצרה רשימת קניות',
                'all departments': 'כל המחלקות',
                '{retailer} club members can save time and upload their shopping cart from their last visit to the store': 'חברי מועדון {retailer} יכולים לחסוך זמן ולטעון את עגלת הקניות מהביקור האחרון שלהם בחנות.',
                'upload my cart': 'טען עגלת קניות',
                'no, thanks': 'לא, תודה',
                'approve & register': 'מאשר/ת וסיום הרשמה',
                'not approve': 'לא מאשר/ת',
                'i agree to receive promotional content & offers from {company_name} and {hubname}': 'אני מעוניין לקבל תוכן פרסומי והטבות מחברת {company_name} בע"מ המפעילה את האתר הקמעונאי ומ{hubname}',
                'unfortunately, your cart cannot be uploaded': 'לצערנו לא ניתן לטעון את העגלה',
                'please try again later or continue shopping': 'אנא נסו במועד מאוחר יותר או המשיכו בקניה באתר',
                'areaquery: spautocompleterequirematch error': 'אנא בחרו כתובת מהרשימה',
                'areaquery: required error': 'אנא בחרו כתובת מהרשימה',
                'pickup address': 'כתובת איסוף עצמי',
                'the pickup will be picked from': 'האיסוף יתבצע מסניף',
                'additional special\'s variety': 'מגוון מוצרי מבצע',
                'password: invalid password error': 'על הסיסמה החדשה להכיל בין 6 ל 20 תווים',
                'customer service order': 'הזמנת שירות לקוחות',
                'logo': 'לוגו',
                'copy completed': 'העתקה הושלמה',
                'edit comment': 'ערוך הערה',
                'filters': 'אייקון מסננים',
                'close filters': 'סגור מסננים',
                'cant find item image': 'תמונת לא מצאתם מוצר',
                'plus': 'פלוס',
                'minus': 'מינוס',
                'open recipe': 'פתח מתכון',
                'banner': 'באנר',
                'play video': 'הפעל וידאו',
                'display video': 'לחץ כדי להציג וידאו ואז המשך בניווט להפעלת הוידאו',
                'remove from cart': 'הסר מהסל',
                'express delivery': 'משלוח מהיר',
                'express pickup': 'איסוף מהיר',
                'paypal logo': 'לוגו PayPal',
                'masterpass logo': 'לוגו MasterPass',
                'language': 'שפה',
                'account': 'חשבון',
                'open store info': 'פתח פרטי חנות',
                'close store info': 'סגור פרטי חנות',
                'open cart': 'פתח עגלה',
                'close cart': 'לסגור עגלה',
                'open ticket': 'פתח כרטיס',
                'close ticket': 'סגור כרטיס',
                'paypal account': 'חשבון PayPal',
                'previous product': 'המוצר הקודם',
                'next product': 'המוצר הבא',
                'close promotion tooltip': 'סגור טולטיפ מבצע',
                'disclaimer': 'תנאי המבצע',
                'the previous items': 'לפריטים קודמים',
                'the next items': 'לפריטים הבאים',
                'open filter options': 'פתח אפשרויות סינון',
                'close filter options': 'סגור אפשרויות סינון',
                'clear': 'נקה',
                'open product page': 'פתח דף מוצר',
                'open': 'לפתיחה',
                'close our top picks': 'סגור את המומלצים שלנו',
                'clarification': 'הבהרה',
                'show category': 'הצג קטגוריה',
                'show order': 'הצג הזמנה',
                'close order': 'סגור הזמנה',
				'on credit payment': 'תשלום בהקפה',
                'please enter order confirmation number': 'אנא הקלידו מספר אישור הזמנה',
                'user not connected to organization': 'משתמש לא מחובר לארגון',
                'no delivery time slots available': 'אין זמני משלוח זמינים',
                'no pick up time slots available': 'אין זמני איסוף זמינים',
                'invalid phone number': 'מספר טלפון לא תקין',
                'phones - invalid phone number.': 'מספר טלפון לא תקין',
                'invalid city': 'ישוב לא חוקי',
                'invalid address': 'כתובת לא חוקית',
                'invalid street': 'רחוב לא חוקי',
                'invalid address 1': 'רחוב & מספר בית לא תקינים',
                'invalid address 2': 'קומה & דירה לא תקינים',
                'invalid zip code': 'מיקוד לא חוקי',
                'shopping cart': 'עגלת קניות',
                'main manu': 'תפריט ראשי',
                'home page banner': 'באנר דף הבית',
                'promoted specials': 'מבצעים מובילים',
                'filters & sorting': 'פילטור ומיון מוצרים',
                'product list': 'רשימת מוצרים',
                'checkout footer': 'כותרת תחתונה צקאאוט',
                'delivery method': 'שיטת משלוח',
                'delivery method & details': 'שיטת ופרטי משלוח',
                'navigation': 'ניווט',
                'main display': 'תצוגה ראשית',
                'shortcuts': 'קיצורי דרך',
                'filters_region': 'פילטור מוצרים',
                'languages': 'שפות',
                'settings': 'הגדרות מערכת',
                'next slide': 'תמונה הבאה',
                'home page link': 'לינק לדף הבית',
                'newsletter bubble': 'ניוזלטר',
                'number of items': 'מספר פריטים',
                'cart_total': 'סך הכל',
                'items_in_cart': 'פריטים',
                'review our last minute recommendations, just before you checkout': 'רגע לפני שמסיימים, אולי יעניין אותך המומלצים שלנו',
                'thank you, you are now logged in': 'תודה, הכניסה למערכת הושלמה בהצלחה',
                'thank you, registration completed successfully': 'תודה, ההרשמה הושלמה בהצלחה',
                'delivery method options': 'אפשרויות שיטת משלוח',
                'substitute preference options': 'אפשרויות החלפת מוצרים',
                'payment method options': 'אפשרויות שיטת תשלום',
                'add coupon': 'הוסף קופון',
                'remove product': 'מחק מוצר',
                'remove coupon': 'מחק קופון',
                'all specials': 'כל המבצעים',
                'sorry, we do not deliver to your area': 'נכון לעכשיו אנחנו לא מבצעים משלוחים לאזורכם',
                'sorry, we do not provide pickup services': 'מצטערים, אנחנו לא מספקים שירותי איסוף',
                'please type in your email and we will let you know when we expand to your location': 'השאירו לנו את כתובת הדוא"ל שלכם ונעדכן אתכם כשנגיע לאזורכם',
                'email address': 'כתובת דואר אלקטרוני',
                'keep in touch': 'עדכנו אותי',
                'try a different location': 'נסו מיקום אחר',
                'try a different address': 'נסו כתובת אחרת',
                'try a different city': 'נסו עיר אחרת',
                'try a different zip code': 'נסו מיקוד אחר',
                'please enter a more accurate address to continue': 'אנא הקלידו כתובת מדוייקת יותר כדי להמשיך',

                'express delivery is allowed only with products that contain express delivery tag': 'משלוח מהיר אפשרי רק עם מוצרים שמסומנים כמוצרי משלוח מהיר',
                'this service is available for express items only. removing non express items from your cart will enable using this option': 'שירות זה זמין למוצרים שמסומנים כמוצרי משלוח מהיר בלבד. הסרה של מוצרים שלא מסומנים תאפשר אפשרות זו',
                'express delivery products limit exceeded': 'כמות המוצרים חורגת מהגבלת מוצרים למשלוח מהיר',
                'express delivery is limited to': 'משלוח מהיר מוגבל ל',
                'items. to enable this service, remove items from your cart': 'פריטים בלבד. כדי לאפשר שירות זה, הסירו מוצרים מהעגלה',

                'apologies': 'מתנצלים',

                'view all': 'הצג הכל',
                'call customer': 'התקשרו ללקוח',
                'payment method': 'אמצעי תשלום',

                'products limit': 'מגבלת מוצרים',
                'this delivery has a limit of {deliveryitemslimit} products': 'במשלוח זה קיימת הגבלה של {deliveryItemsLimit} מוצרים',
                'limited to {deliveryitemslimit} products': 'מוגבל ל-{deliveryItemsLimit} מוצרים',
                'limited to products marked with "{tagname}" tag only': 'מוגבל למוצרים המסומנים בתגית "{tagName}" בלבד',
                'has no items limit': 'ללא הגבלת מוצרים',
                'choose the pickup location': 'בחרו את נקודת האיסוף',
                'if you wish to add another product, remove one from the cart first': 'אם ברצונכם להוסיף מוצר נוסף, הסירו תחילה מוצר אחר מהעגלה',
                'or change the delivery method': 'או החליפו סוג משלוח',
                'change delivery method': 'החליפו סוג משלוח',
                'are you sure you want to change the delivery type': 'האם ברצונכם להחליף סוג משלוח',
                'changes in delivery times may apply': 'ייתכן שוני בזמני המשלוח',
                'changes in inventory between the stores may apply': 'ייתכן שוני במלאי בין החנויות השונות',
                'yes, change delivery method': 'כן, החליפו סוג משלוח',
                'no, stay in current delivery': 'לא, השארו במשלוח זה',
                'or select a store for pickup': 'או בחרו חנות לאיסוף עצמי',
                'select a store for pickup': 'בחרו חנות לאיסוף עצמי',
                'choose a convenient time & pickup your order at one of our stores': 'איספו את ההזמנה מאחד הסניפים בשעה שנוחה לכם',
                'choose': 'בחרו',
                'branch logo': 'אייקון של הסניף',
                'unfortunately we didn\'t find any stores in this area': 'לצערנו לא מצאנו חנויות באזור זה',
                'try a different area name': 'נסה אזור אחר',
                'please enter a valid address': 'אנא הקלידו כתובת חוקית',
                'please add street and house number': 'אנא הוסיפו שם רחוב ומספר בית',
                'delivery_type_1': 'משלוח',
                'delivery_type_2': 'איסוף עצמי',
                'delivery_type_5': 'משלוח מהיר',
                'see you soon': 'נפגש בסניף',
                'the order will wait for you at the store': 'ההזמנה תמתין לכם ארוזה ומוכנה',
                'from branch': 'מסניף',
                'chametz': 'חמץ',
                'passoverkosher': 'כשר לפסח כל השנה',
                'passoverkoshertemporarily': 'כשר לפסח זמני',
                'passoverkitniyotkosher': 'כשר לפסח כל השנה לאוכלי קטניות',
                'passoverkitniyotkoshertemporarily': 'כשר לפסח זמני לאוכלי קטניות',
                'meat': 'כשר בשרי',
                'milky': 'כשר חלבי',
                'pareve': 'כשר פרווה',
                'israelmilk': 'כשר חלב ישראל',
                'israelcooking': 'בישול ישראל',
                'sabbathobservingfactory': 'מפעל שומר שבת',
                'noshviitconcern': 'ללא חשש שביעית',
                'noorlaconcern': 'ללא חשש עורלה',
                'notevelconcern': 'ללא חשש טבל',
                'noovernightconcern': 'ללא חשש לינת לילה',
                'nogeniza': 'ללא חשש גניזה',
                'recipes and allergies': 'רכיבים ואלרגנים',
                'list of ingredients': 'רשימת רכיבים',
                'allergies contained': 'אלרגנים כלולים',
                'possible allergies': 'אלרגנים שעשויים להיות כלולים',
                'about the product': 'אודות המוצר',
                'extra info and instructions': 'מידע נוסף והנחיות',
                'supervisions': 'כשרויות',
                'usage warnings': 'אזהרות שימוש ובטיחות',
                'storage instructions': 'הוראות אחסון',
                'usage instructions': 'הוראות הכנה ושימוש במוצר',
                'fat percentage': 'אחוז שומן',
                'cream percentage': 'אחוז שמנת',
                'fruit percentage': 'אחוז פרי',
                'alcohol percentage': 'אחוז אלכהול',
                'ph': 'אחוז הגבה (pH)',
                'country of origin': 'ארץ ייצור',
                'date taken into effect': 'תאריך תחילת תוקף',
                'height': 'גובה',
                'depth': 'עומק',
                'width': 'רוחב',
                'net width': 'משקל נטו',
                'gross width': 'משקל ברוטו',
                'general information': 'פרטים כללים',
                'diet type': 'סוג דיאטה',
                'the waiting period will not exceed two hours': 'אספקה תוך שעתיים מהמועד הנבחר',
                'at_hour': 'בשעה',
                'special_reminder_bubble_title': 'חסכו!',
                'special_reminder_awarded_bubble_title': 'חסכתם!',
                'special_reminder_button_title': 'לחצו וחסכו!',
                'add products to earn this promotion': 'הוסיפו מוצרים למימוש המבצע',
                'done': 'סיום',
                'select your benefit': 'בחרו את ההטבה',
                'more specials': 'מבצעים נוספים',
                'show more specials': 'צפו בעוד מבצעים',
                'show less specials': 'צפו בפחות מבצעים',
                'coupon': 'קופון',
                'coupon_requirements_error': 'אינכם עומדים בתנאי הקופון',
                'remaining credit': 'יתרת התקציב',
                'cart amount higher': 'סכום העגלה גבוה',
                'than your credit': 'מיתרת התקציב שנותרה',
                'edit cart': 'עריכת עגלה',
                'cart total': 'סה"כ בעגלה',
                'to complete the purchase, remove products from the cart valued at': 'להשלמת הרכישה הסירו מוצרים מהעגלה בשווי של',
                'payment by credit': 'חיוב לקוח בהקפה',
                'this user is not set as credit customer': 'הלקוח אינו מוגדר כלקוח בהקפה',
                'cart amount higher than your credit. to proceed please remove at least': 'סכום ההזמנה חורג מהמסגרת שהוקצתה ללקוח זה. על מנת לסיים יש להוריד מההזמנה מוצרים בשווי לפחות',
                'valid until': 'בתוקף עד',
                'please verify that you are not a robot': 'אנא סמן/י כי אינך רובוט',
                'attachment.size - invalid attachment, file bigger then 2mb': 'שגיאה: הקובץ המצורף גדול מ-2 מגה',
                'attachment.originalname - invalid attachment name': 'שגיאה: שם הקובץ המצורף אינו חוקי',
                'attachment.mimetype - invalid attachment, file type not allowed': 'שגיאה: סוג הקובץ המצורף אינו חוקי',
                'all conditions must be marked in order to complete the order': 'יש לסמן את כל התנאים על מנת לסיים את ההזמנה',
                'employee discount': 'הנחת עובדים',
                'card id': 'מספר הכרטיס',
                'discount': 'הנחה',
                'remaining obligo': 'יתרת התקציב',
                'monthly obligo': 'תקציב חודשי',
                'club name': 'שם מועדון',
                'obligo type': 'סוג התקציב',
                'monthly discount obligo': 'תקציב הנחה חודשי',
                'cardid: required error': 'אנא הכניסו מספר הכרטיס',
                'pin: required error': 'אנא הכניסו את מספר Pin',
                'employee discount card holder?': 'מגיע לך הנחת עובדים?',
                'employee discount card data': 'פרטי חשבון הנחת עובדים',
                'employee discount account not found': 'לא נמצא חשבון עובדים',
                'estimated employee savings': 'הנחת עובדים משוערת',
                'getting data...': 'מקבל נתונים...',
                'error': 'שגיאה',
                'what\'s your lifestyle?': 'מהו אורח חייכם?',
                'filter entire store': 'סננו את כל החנות',
                'filter the entire store according to your preferred lifestyle or diet!': 'סננו את כל החנות לפי אורח החיים או הדיאטה המועדפת עליכם!',
                'clear all filters': 'ניקוי כל המסננים',
                'all filters': 'כל המסננים',
                'filtering store': 'מסנן חנות',
                'the entire store is now filtered by': 'כל החנות מסוננת לפי',
                'store is filtered': 'החנות מסוננת',
                'back to filters': 'בחזרה למסננים',
                'we couldn\'t find any products': 'לא נמצאו מוצרים',
                'we couldn\'t find any specials': 'לא נמצאו מבצעים',
                'clear_all_filters_2': 'נקו את כל המסננים',
                'try editing your filters': 'נסו לערוך את המסננים',
                'permanent': 'קבוע',
                'permanent_filters_legal_text': 'בעת השימוש באפשרויות הסינון השונות המוצעות באתר, החברה אינה מתחייבת כי לא יהיו תקלות ושגיאות באתר בכלל ובביצוע הסינון בפרט. אין החברה מתחייבת כי סיווג המוצרים לקטגוריות יהיה מדויק ולא יכלול מוצרים אשר אינם עונים על הקריטריונים הנדרשים. השימוש במידע המתקבל, נעשה באחריותך הבלעדית, לרבות בנוגע לכל נזק שייגרם כתוצאה מהשימוש במידע או מהסתמכות עליו. יש לבדוק כל מוצר, לרבות בכל הנוגע למרכיביו, באופן פרטני. אתה תהיה אחראי לאמת כל מידע אל מול נתוני היצרן של אותו מוצר ובמקרה של סתירה בין המידע המוצג באתר למידע המוצג על ידי היצרן, יש להתייחס למידע שמוצג על ידי היצרן. אין באמור לעיל בכדי ליצור אחריות, בין אם לנזקים עקיפים או ישירים, של החברה בקשר עם המידע שמוצג על ידי יצרן של מוצר כזה או אחר.',
                'pause': 'השהיה',
                'play': 'הפעלה',
                'required fields are marked with *': 'שדות חובה מסומנים ב*',
                'select language': 'בחר שפה',
                'retailer data': 'פרטי חנות',
                'banner link': 'קישור',
                'advertising slider delay': 'השהיית סליידר פרסומי',
                'map': 'מפה',

                'verify account': 'אימות חשבון',
                'there was a problem verifying your account': 'הייתה בעיה באימות חשבונך',
                'please contact customer service': 'אנא צרו קשר עם שירות הלקוחות',
                'you are now registered': 'ההרשמה בוצעה בהצלחה',
                'please verify your account': 'אנא אמתו את חשבונכם',
                'before your first purchase, a verification code will be sent to your mobile phone': 'לפני הרכישה הראשונה, יישלח קוד אימות למכשיר הנייד שלכם',
                'send sms': 'שליחה',
                'enter the code that was sent to your phone': 'הכניסו את קוד האימות שנשלח למכשיר הנייד שלכם',
                'change number': 'שינוי מספר',
                'verify': 'אימות',
                'i didn\'t receive the code (resend)': 'לא קיבלתי את קוד האימות (שליחה שוב)',
                'the code is invalid or expired': 'קוד האימות שגוי או פג תוקף',
                'your account is verified': 'תהליך האימות הושלם בהצלחה',
                'invalid verification code': 'קוד אימות שגוי',
                'you have successfully registered': 'נרשמת בהצלחה',
                'for the customer club': 'למועדון הלקוחות',
                'for the customer club and the website': 'למועדון הלקוחות ולאתר האונליין',
                'loyalty club details': 'פרטי חשבון מועדון',
                'loyalty_terms_confirm': 'אני מאשר/ת שקראתי את',
                'loyalty terms': 'תקנון המועדון',
                'website registration': 'הרשמה לאתר',
                'join club': 'הצטרפו למועדון',
                'loyaltypolicyapproval: required error': 'אנא אשרו את תקנון המועדון ותנאיו',
                'personalid: minlength error': 'אנא הקלידו מספר ת.ז בן 9 ספרות',
                'personalid: maxlength error': 'אנא הקלידו מספר ת.ז בן 9 ספרות',
                'personalid: number error': 'יש להקליד ספרות בלבד',
                'please let me know about promotions on site and club': 'הנני מאשר/ת קבלת עדכונים והטבות בדוא"ל ובמסרונים מהאתר ומהמועדון',
                'phonenum: required error': 'אנא הקלידו טלפון נייד',
                'phonenum: pattern error': 'מספר טלפון לא תקין',
                'organization': 'ארגון',
                'organization management': 'ניהול ארגון',
                'detach branch': 'ניתוק סניף',
                'go to organization': 'כניסה לארגון',
                'user added to organization': 'משתמש נוסף לארגון',
                'user removed from organization': 'משתמש הוסר מהארגון',
                'organization created': 'ארגון נוצר',
                'organization data changed': 'פרטי הארגון השתנו',
                'organization activated': 'ארגון הופעל',
                'organization deactivated': 'ארגון הושבת',
                'organization obligo changed': 'תקציב הארגון השתנה',
                'role': 'תפקיד',
                'remaining budget': 'תקציב הנותר',
                'select branch': 'בחר סניף',
                'users': 'משתמשים',
                'admin': 'מנהל',
                'max length of phone number is 10': 'מספר טלפון לא יעלה על 10 ספרות',
                'payment by organization credit': 'חיוב מתקציב הארגון',
                'sorry, your organization account is inactive': 'מצטערים, חשבון הארגון אינו פעיל',
                'sorry, there are no branches assigned to this organization': 'מצטערים, לא מחוברים סניפים לארגון זה',
                'please contact your organization admin': 'אנא פנה לאחראי הארגון',
                'total assigned': 'סה"כ הוקצה',
                'credit cards can not be added. you are assigned to an organization': 'לא ניתן להוסיף כרטיס אשראי. הינך משוייך לארגון',
                'associated branch': 'שיוך לסניף',
                'budget history': 'הסטוריית תקציב',
                'organization budget': 'תקציב ארגוני',
                'remaining organization budget': 'ייתרת תקציב ארגוני',
                'overall budget for users': 'סה״כ הוקצה למשתמשים',
                'user': 'משתמש',
                'changed by user': 'עודכן על ידי משתמש',
                'action': 'פעולה',
                'action sum': 'סכום פעולה',
                'remaining user credit': 'יתרת מסגרת משתמש',
                'remaining organization credit': 'יתרת מסגרת ארגון',
                'user credit update': 'עדכון מסגרת משתמש',
                'credit update': 'עדכון מסגרת',
                'data update': 'עדכון נתונים',
                'set as credit customer': 'סומן כלקוח בהקפה',
                'unset credit customer': 'הוסר מלקוח בהקפה',
                'order canceled in backend': 'הזמנה בוטלה בבקאנד',
                'order changed in backend': 'הזמנה שונתה בבקאנד',
                'order renewed in backend': 'הזמנה חודשה בבקאנד',
                'user checkout': 'תשלום משתמש',
                'user order edit': 'עריכת הזמנה',
                'collection app edit': 'עריכת הזמנה בליקוט',
                'collection app canceled': 'הזמנה בוטלה בליקוט',
                'collection app finished': 'הזמנה לוקטה',
                'changed by pos': 'עדכון על ידי קופה',
                'order refunded': 'זיכוי כספי',
                'remove selection': 'מחק בחירה',
                'payment cancelled': 'התשלום בוטל',
                'payment expired': 'התשלום פג תוקף',
                'bit logo': 'לוגו Bit',

                'cancel order': 'ביטול הזמנה',
                'are you sure you want to cancel this order': 'האם אתם בטוחים שברצונכם לבטל את ההזמנה',
                'order #': 'מספר הזמנה',
                'keep the order': 'השאירו את ההזמנה',
                'your order was cancelled': 'הזמנתכם בוטלה',
                'this order will not be charged': 'הזמנה זו לא תחויב',
                'a confirmation email was sent to {email}': ' אימייל אישור נשלח ל {email}',
                'your payment method will not be charged': 'אמצעי התשלום שלך לא יחויב',
                'no available deliveries in the upcoming days': 'לא נותרו משלוחים פנויים בימים הקרובים',
                'home page': 'דף הבית',
                'days': 'ימים',
                'business days': 'ימי עסקים',
                'delivery within': 'משלוח תוך',
                'within': 'תוך',
                'delivery price': 'מחיר משלוח',
                'we will contact you in order to': 'אנחנו ניצור איתך קשר בכדי',
                'schedule the delivery hours': 'לתאם את שעת המשלוח',
                'remove item from cart': 'הסרת המוצר מהעגלה',
                'to the selected delivery area we only send products marked with "{tagname}" tag': 'לאזור המשלוח שבחרתם אנו שולחים מוצרים המסומנים בתגית "{tagName}" בלבד',
                'you can either remove the product from the cart or change the delivery area': 'תוכלו להסיר מוצר זה מהעגלה או להחליף את אזור המשלוח',
                'or change the delivery area': 'או החליפו אזור משלוח',
                'ordered': 'הוזמן',
                'accessibility statement': 'הצהרת נגישות',
                'accessibility arrangements': 'הסדרי נגישות',
                'accessibility_statement_p1': 'אנו ב{retailerName} מכירים בחשיבות הנגישות במרחב הדיגיטלי והפיזי לאנשים עם מוגבלות ורואים בכך חלק בלתי נפרד מתפיסת השירות והאחריות החברתית שלנו. אתר זה הונגש בתשומת לב רבה על פי התקן הישראלי 5568 – ברמה AA, הרמה הנדרשת בחוק.',
                'accessibility_statement_p2': 'יש לציין כי למרות מאמצנו להנגיש את כלל הדפים באתר, ייתכן ויתגלו חלקים באתר שטרם נגישים, אנו ממשיכים במאמצים לשפר את נגישות האתר כחלק ממחויבותנו לאפשר שימוש בו עבור כלל האוכלוסייה כולל אנשים עם מוגבלויות.',
                'accessibility_statement_p3': 'ההנגשה של האתר באה לידי ביטוי בנושאים הבאים:',
                'accessibility_point_1': '* האתר מותאם לגלישה במחשב נייח, נייד ובסלולר.',
                'accessibility_point_2': '* ניתן להגדיל את הטקסט ע"י לחיצה בו זמנית על המקשים "Ctrl" ו "+" (קונטרול ופלוס). כל לחיצה על צמד המקשים האלו תגדיל את התצוגה ב-10%. כדי להקטין את התצוגה יש ללחוץ בו זמנית על המקשים "Ctrl" ו "-" (קונטרול ומינוס).',
                'accessibility_point_3': '* גולשים המתקשים בהפעלת עכבר יכולים לגלוש באתר באמצעות מקלדת. לחיצה חוזרת ונשנית על המקש Tab תעביר בין הקישורים, הכפתורים בעמוד והשדות השונים בטופס. לחיצה על Enter תפעיל את הקישור/הכפתור המסומן.',
                'accessibility_point_4': '* מבנה העמודים סמנטי ותקני (חלוקה לכותרות, תיוג איזורים, קישורים ותמונות וכיו"ב).',
                'accessibility_point_5': '* תמיכה בטכנולוגיות מסייעות- אתר זה הינו אתר נגיש המותאם לצפייה גם לאנשים עם מוגבלות ומאפשר תמיכה בתוכנת קריאת מסך. לנגישות מיטבית באתרנו אנו ממליצים להשתמש בקורא מסך NVDA או Voice Over.',
                'accessibility_point_6': '* חלוקה היררכית לכותרות',
                'accessibility_point_7': '* תיוג תמונות ורכיבים שאינם טקסטואליים ואינם קישוט בלבד.',
                'accessibility_point_8': '* פוקוס נראה לעין – פוקוס משמעותי יותר ניתן למצוא ברכיב הנגישות, הפעילו את האפשרות של ניווט מקלדת',
                'accessibility_point_9': '* התראות נגישות על בעיות בשדות בטופס צור קשר',
                'accessibility_point_10': '',
                'please enter your house number': 'אנא הכניסו מספר בית',
                'so we can assign you to the right branch': 'כך שנוכל לשייך אתכם לסניף המתאים',

                'your products from a website': 'המוצרים שלכם מאתר',
                'inventory availability may vary according to the delivery area': 'ייתכנו שינויים בזמינות המלאי בהתאם לאזור המשלוח והחנות',
                'please note - we could not identify {number}': 'שימו לב - לא הצלחנו לזהות {number}',
                'more products': 'מוצרים נוספים',
                'more product': 'מוצר נוסף',
                'from the {name} website': 'מאתר {name}',
                'out of stock': 'חסר במלאי',
                'the site is active only for organization users': 'האתר פעיל למשתמשים ארגוניים בלבד',
                'please use your e-mail address that was approved by the organization': 'יש להשתמש בכתובת הדואר האלקטרוני אשר אושרה ע"י הארגון',
                'please login or register': 'אנא התחברו או הירשמו',
                'sorry, this website is for organization users only': 'מצטערים, אתר זה מיועד למשתמשים ארגוניים בלבד',
                'you user is not assigned to an organization': 'המשתמש שלך לא משוייך לארגון',
                'sorry, you can not register with this email address': 'מצטערים, לא ניתן להרשם עם כתובת מייל זו',
                'sorry, you can not register with this email address. use the organization-approved email address': 'מצטערים, לא ניתן להרשם עם כתובת מייל זו. יש להשתמש בכתובת הדוא"ל המאושרת ע"י הארגון',
                'your account has not yet been verified via email - please verify by clicking on the link sent to your email inbox': 'חשבונך טרם אומת. אנא וודאו כי אישרתם את הדואר האלקטרוני שנשלח לחשבונכם לאחר הרישום',
                'send again': 'שלחו שוב',
                'didn\'t receive an email': 'לא קבלתם דוא"ל',
                'user email address not found': 'כתובת הדוא"ל של המשתמש לא נמצאה',
                'a verification email will be sent to your email box. the link is valid for {link_ttl} minutes': 'מייל אימות נשלח לתיבת הדוא״ל שלכם. הקישור תקף למשך {link_ttl} דקות',
                'your account requires activation, email with activation link have been sent to your email. the link is valid for {link_ttl} minutes': 'על מנת להכנס לאתר נדרש אימות חשבונכם. שלחנו מייל לתיבת הדוא״ל שלכם, ובו קישור לאימות החשבון. הקישור תקף למשך {link_ttl} דקות.',
                'activation code expired': 'קוד האימות פג תוקף',
                'website login': 'כניסה לאתר',
                'send a new link': 'שלח קישור חדש',
                'sorry, this link has expired': 'מצטערים, פג תוקפו של הקישור זה',
                'a new link can be sent': 'ניתן לשלוח קישור חדש',
                'you have successfully registered for the site': 'נרשמתם בהצלחה לאתר',
                'this account has already been verified': 'חשבון זה כבר מאומת במערכת',
                'you may enter the trade site': 'תוכלו להיכנס לאתר הסחר',
                'this account is an organizational account. you may need to verify it similarly from time to time': 'חשבון זה הינו חשבון ארגוני. ייתכן ויהיה עליכם לאמת אותו בצורה דומה מעת לעת',
                'please verify your identity': 'אנא אמתו את זהותכם',
                'to verify your identity, please enter a phone number': 'על מנת לאמת את זהותכם, אנא הזינו מספר טלפון',
                'continue to registration': 'המשיכו להרשמה',
                'phone number does not match': 'מספר הטלפון אינו תואם',
                'user invitation not found': 'ההזמנה של המשתמש לא נמצאה',
                'user already registered': 'המשתמש כבר רשום',
                'phone number hint': 'רמז למספר טלפון',
                'sorry, there was an authentication error': 'מצטערים, חלה שגיאה באימות',
                'please try again or contact the person in charge of the organization': 'אנא נסו שנית או פנו לאחראי הארגון',

                'add users': 'הוסף משתמשים',
                'adding users': 'הוספת משתמשים',
                'remove users': 'הסר משתמשים',
                'remove user': 'הסר משתמש',
                'please select a file': 'בחר קובץ',
                'search for a user by phone or email': 'חפש משתמש לפי טלפון או אימייל',
                'download csv example': 'הורד CSV לדוגמא',
                'uploading a csv file': 'העלאת קובץ CSV',
                'file upload complete. a summary email will be sent to your inbox': 'העלאת הקובץ הסתיימה. מייל סיכום יישלח לתיבת הדואר שלך',

                'in order to add users to the organization, a csv file must be uploaded with the users information': 'בכדי להוסיף משתמשים לארגון, יש להעלות קובץ CSV עם פרטי המשתמשים',
                'once added to the system, an identity verification email will be sent to each of the users': 'עם הוספתם למערכת, יישלח מייל אימות זהות לכל אחד מהמשתמשים',
                'only after identity verification, users will be able to complete the registration process for the site': 'רק לאחר אימות הזהות, יוכלו המשתמשים להשלים את תהליך הרישום לאתר',
                'in order to remove users from the organization, a csv file must be uploaded with the users details': 'בכדי להסיר משתמשים מהארגון, יש להעלות קובץ CSV עם פרטי המשתמשים',
                'pay attention! these users will no longer be able to access the organization website': 'שימו לב! משתמשים אלו לא יוכלו עוד להכנס לאתר הארגון',
                'the file must contain at least one user with a phone number and email': 'הקובץ חייב להכיל לפחות משתמש אחד עם מספר טלפון ודואר אלקטרוני',
                'user_verification_status_1': 'לא אומת',
                'user_verification_status_2': 'לא אומת',
                'user_verification_status_3': 'אומת',
                'please confirm removing user from the organization': 'אנא אשר הסרת המשתמש מהארגון',
                'user removed from the organization': 'המשתמש הוסר מהארגון',
                'order_organization_confirm: required error': 'אנא הקלידו מספר אישור הזמנה',
                'account not verified': 'חשבון זה אינו מאומת',
                'this account is an organizational account. you account verification has expired': 'חשבון זה הינו חשבון ארגוני. פג תוקפו של אימות של החשבון',
                'account verification succeed': 'חשבון אומת בהצלחה',
                'this user may already be on the system': 'ייתכן ומשתמש זה כבר קיים במערכת',
                'please try to login': 'אנא נסה להכנס לאתר',

                'delivery precedence for senior citizens!': 'קדימות במשלוחים לאזרחים הותיקים!',
                'we keep delivery slots for senior citizens to allow high availability in this challenging time': 'אנו שומרים זמני משלוח עבור אזרחים ותיקים כדי לאפשר זמינות גבוהה בתקופה מאתגרת זו',
                'enter your id number': 'הזינו מספר תעודת זהות',
                'used for verification only, we arn\'t saving this number': 'לצורך בדיקת זכאות בלבד, אנו לא שומרים מספר זה',
                'another time, continue shopping': 'בפעם אחרת, המשיכו לאתר',
                'subject to delivery slots availability out of senior citizens allocation': 'בכפוף לזמינות זמני אספקה מתוך ההקצאה השמורה לאזרחים הותיקים, אין באמור להוות התחייבות',
                'the verification was successful': 'הבדיקה עברה בהצלחה',
                'your account is now tagged as "senior citizen"': 'חשבונכם מסומן כעת כ"חשבון אזרח ותיק"',
                'seniorcitizenid: required error': 'אנא הזינו את תעודת הזהות שלכם',
                'seniorcitizenid: minlength error': 'תעודת זהות לא חוקית',
                'seniorcitizenid: maxlength error': 'תעודת זהות לא חוקית',
                'seniorcitizenid: pattern error': 'תעודת זהות לא חוקית',
                'i am a senior citizen': 'אני אזרח ותיק',
                'may grant you priority delivery slots': 'עשוי להעניק עדיפות בחלונות משלוח',

                'wrong captcha token': 'טוקן של קאפצא שגוי',
                'wrong captcha host': 'שם הדומיין של קאפצא שגוי',

                'user products list not found': 'רשימת המוצרים של משתמש לא נמצאה',
                'user products list is disabled for this retailer': 'רשימת המוצרים של משתמש מושבתת עבור קמעונאי זה',
                'my lists': 'הרשימות שלי',
                'my products': 'המוצרים שלי',
                'future purchases will generate a list of popular items bought by you and update your products list': 'ברכישות הבאות שלך רשימה זו תתעדכן אוטומטית לרשימת המוצרים הפופולריים שלך',
                'in the meantime, you can use the popular items list on the website': 'בינתיים ניתן להיעזר ברשימת הפריטים הפופולריים באתר',
                'my products list': 'רשימת המוצרים שלי',
                'products i usually purchase': 'המוצרים שאני בד"כ קונה',
                'the products i usually purchase': 'המוצרים שאני בד"כ קונה',
                'your time is precious': 'הזמן שלכם יקר',
                'we have prepared a list of your personal products': 'הכנו עבורך רשימה של המוצרים שלך',
                'we have prepared a list of products you usually purchase': 'הכנו עבורכם רשימה של המוצרים שאתם בד"כ קונים',
                'add products from the list': 'הוספת מוצרים מהרשימה',
                'in cart': 'בסל',

				'we use cookies to improve your experience': 'אנו עושים שימוש בקבצי cookies כדי לשפר את השירות וחוויית המשתמש',
				'we use cookies or other trackers to facilitate the use of the site, improve the performance and security of the site, personalize the content offered, including personalized advertising content, and compile traffic and navigation statistics. some cookies, other than functional and security cookies, require your consent to be placed.': 'אנו עושים שימוש בקבצי cookies כדי לשפר את השימוש, השירות ואבטחת האתר וכן לצורך שיפור החוויה האישית, התוכן המוצע כולל תוכן שיווקי ומדידת traffic ושימושיות. חלק מקבצי העוגיות דורשים את הסכמתך.',
				'accept': 'קבל',
				'accept all cookies': 'קבל את כל קבצי הcookies',
				'setup my cookies': 'הגדר את קבצי הcookies שלי',
				'managing cookies': 'ניהול קבצי cookies',
				'these cookies allow you to benefit from the services offered by the site, to optimize its use and personalization (in particular personalization of offers and advertisements) depending on your profile.': 'קבצי ה cookies מאפשרים לכם להנות מהשירותים המוצעים על ידי אתר זה, לשפר אותו ואת השירות האישי כולל פרסומות בהתאם להגדרות הפרופיל האישי.',
				'marketing': 'שיווק',
				'marketing cookies are used to improve the relevance of the ads you see and to make sure that you do not see the same ad multiple times.': 'אנו עושים שימוש בקבצי cookies שיווקיים כדי לשפר את דיוק השירותים והמודעות המוצגים ולמנוע הצגה כפולה.',
				'additional features': 'שירותים נוספים',
				'these cookies make it possible to activate additional features on the site, for example to facilitate your purchases.': 'קבצי cookies אלה מאפשרים לנו להפעיל שירותים נוספים באתר כגון כאלה המסייעים בתהליך הרכישה.',
				'audience measurement': 'מדידת קהלים',
				'these cookies allow us to obtain navigation statistics on our site. we use this data to identify possible malfunctions of the site, to improve the site, for marketing purposes, and in order to personalize your experience on the site.': 'קבצי cookies אלה מאפשרים לנו למדוד את הרגלי הגלישה באתר, אנו עושים שימוש במידע זה כדי לזהות תקלות אפשריות ולשפר את האתר כולל חוויה אישית בנוסף לצרכים שיווקיים.',
				'google analytics': 'Google analytics',
				'these cookies allow us to obtain statistics on the number of visits to our site (e.g., number of visits, most viewed pages, etc.). we use this data to understand our users better, check the performance of our content, get insights, and identify possible malfunctions of the site to make improvements.': 'קבצי cookies אלה מאפשרים לנו להשיג נתונים סטטיסטיים על מספר המבקרים וצפיות באתר. אנו עושים שימוש במידע זה כדי לנתח את אופן השימוש באתר לשפר תוכן, ביצועים ולקבל תובנות בנושאים כגון תקלות אפשריות ואיך לתקנן.',
				'functional (not configurable)': 'שימושיות (אין אפשרות הגדרה)',
				'these cookies allow you to remember your choices and preferences on your account and to provide features that facilitate the use of the site. they are essential for the proper functioning of the site and are strictly necessary for the provision of a service that you have expressly requested.': 'קבצי cookies אלה מאפשרים לנו לזכור את ההגדרות האישיות על חשבנוכם ולספק יכולות המשפרות את השימוש באתר והם חיוניים באופן מוחלט לשימוש האתר והספקת שירותים שנתבקשו באופן מפורש.',

                'ebt': 'EBT',
                'ebt payment': 'תשלום EBT',
                'credit card': 'כרטיס אשראי',
                'step': 'שלב',
                'add ebt card': 'הוספת כרטיס EBT',
                'card': 'כרטיס',
                'check balance': 'בדיקת יתרה',
                'add your ebt card': 'פרטי כרטיס EBT',
                'ebt snap': 'EBT SNAP',
                'ebt cash': 'EBT מזומן',
                'balance': 'יתרה',
                'ebtfsamount: please add your amount error': 'אנא הכניסו סכום',
                'ebtfsamount: amount exceeded error': 'הסכום עבר את הזכאות',
                'amount exceeded eligibility': 'הסכום עבר את הזכאות',
                'ebtcbamount: amount exceeded error': 'הסכום עבר את הזכאות',
                'eligible': 'זכאות',
                'amount to pay': 'סכום לתשלום',
                'includes 10% weight debit': 'כולל 10% פקדון מוצרים שקילים',
                'authorize your': 'אימות',
                'enter your pin on the next screen': 'הכניסו את קוד הPIN במסך הבא',
                'proceed to pin code': 'המשיכו לקוד הPIN',
                'no available slots': 'אין חלונות זמינים',
                'your account requires activation.': 'להשלמת ההרשמה יש לאמת את החשבון.',
                'email with activation link have been sent to your email.': 'קישור לאימות החשבון נשלח כעת לתיבת הדואר האלקטרוני שלכם.',
                'the link is valid for {link_ttl} minutes': 'הקישור תקף למשך {link_ttl} דקות',
                'view all coupons': 'לרשימת הקופונים',
                'time slot unavailable': 'חלון זמן משלוח אינו זמין',
                'we\'re sorry, this time slot is no longer available. please select a different time': 'אנו מצטערים, חלון זמן המשלוח שבחרת כבר לא זמין. אנא בחר זמן משלוח אחר',
                'return to schedule': 'חזרה לזמני משלוח',
                'the coupon is in your cart': 'הקופון נמצא בעגלה שלך',
                'please select': 'אנא בחר',
                'due to suspicious activity on your account, we have taken protective measures and blocked the possibility of making purchases on our website. please contact customer support to clarify the issue as soon as possible.':'עקב פעילות חשודה בחשבונך, נקטנו אמצעי הגנה וחסמנו את האפשרות לבצע רכישות באתרנו. אנא צרו קשר עם תמיכת הלקוחות כדי להבהיר את הנושא בהקדם האפשרי',
                'security message':'הודעת אבטחה',
                'sitemap': 'מפת האתר',
                'pages': 'עמודים',
                'the {product_count} products listed below will be': '{product_count} מוצרים הבאים',
                'removed from your cart': 'יוסרו מהעגלה ולא יסופקו',
                'out of stock products': 'המוצרים הבאים אינם במלאי',
                'confirm': 'אישור',
                'back to cart': 'חזרה לעגלה',
                'the items below will be removed from your cart': 'הפריטים הבאים יוסרו מהעגלה',
                '{product_count} out of stock products will not be supplied': '{product_count} מוצרים חסרים במלאי ולכן לא יסופקו',
                '{coupons_count} expired coupons cannot be redeemed': '{coupons_count} קופונים פגי תוקף ולא ניתן לממשם',
                'products marked with': 'מוצרים המסומנים ב-',
                'limited quantity': 'כמות מוגבלת',
                'tag are limited to': 'תג מוגבל ל',
                'items only.': 'פריטים בלבד.',
                'custom-phone-validation-range': 'מספר הטלפון חייב להכיל {min} עד {max} ספרות',
                'custom-phone-validation-min': 'מספר הטלפון חייב להכיל מינימום {min} 6 ספרות',
                'custom-phone-validation-max': 'מספר הטלפון חייב להכיל מקסימום {max} ספרות',
                'expired': 'פג תוקף',
                'are you sure you want to remove all products from your shopping cart?': 'האם ברצונך להסיר את כל המוצרים מהעגלה?',
                'yes, clear cart': 'מחק את העגלה',
                'clear cart?': 'מחק את העגלה?',
                'you_can_not_add_more_than': 'לא ניתן להוסיף לעגלה יותר מ-',
                'items_marked_with_symbol': 'פריטים המסומנים בסמל זה',

                                'a quick and easy way to login with your former account': 'דרך קלה ומהירה להתחבר לחשבונכם מהאתר הקודם',
                'no account associated with this email was found, please try again': 'לא נמצא חשבון המשוייך לדוא״ל זה, אנא נסו שנית',
                'this email address is used for an account on our previous site': 'לכתובת דוא"ל זו משוייך חשבון באתר הקודם',
                'in order to link your account information, a verification required via email': 'לצורך קישור פרטי החשבון והמועדונים לאתר החדש יש לבצע אימות באמצעות דוא"ל',
                'please enter the email address you previously registered with': 'אנא מלאו את כתובת הדוא"ל עמה נרשמת בעבר',
                'send me a verification code': 'שילחו לי קוד אימות',
                'or go to': 'או עברו',
                'to_login': 'להתחברות',
                'to_register': 'להרשמה',
                'or you can': 'או שתוכלו',
                'register with a different email': 'להרשם עם כתובת דוא"ל אחרת',
                'for support contact customer service:': 'לעזרה צור קשר עם שירות לקוחות:',
                'enter verification code': 'הכנס קוד אימות',
                'an email with verification code has been sent to': 'קוד אימות נשלח באמצעות אימייל לכתובת',
                'didn\'t receive the code?': 'לא קיבלת את הקוד?',
                'you may need to check your spam folder': 'ייתכן שתצטרך לבדוק את תיקיית הספאם שלך',
                'invalid code': 'הקוד שהוכנס אינו תקין',
                'please try again or': 'נסו שוב או',
                'resend a new code': 'שילחו לי קוד חדש',
                'choose a new password': 'יצירת סיסמא חדשה',
                'from now on you will use this password to login': 'סיסמה זו תשמש אותך בכניסה לאתר מעתה והלאה',
                'welcome!': 'ברוכים הבאים!',
                'your account and loyalty club information has been successfully updated!': 'פרטי החשבון והמועדונים שלכם עודכנו בהצלחה!',
                'items are marked with this symbol': 'פריטים מסומנים בסמל זה',

                'not a club member yet? join and save': 'עדיין לא חברי מועדון? הצטרפו וחסכו',
                'club membership gives you unique benefits, discounts and promotions': 'חברות במועדון מעניקה לכם הטבות, הנחות ומבצעים ייחודיים',
                'choose to which club you want to join/sign up': 'בחרו לאיזה מועדון ברצונכם להתחבר/להרשם',
                'logged': 'מחובר',
                'no.': 'מס\'',
                'log in with your club member or sign up and receive unique benefits': 'התחברו עם חבר המועדון שלכם או הרשמו וקבלו הטבות ייחודיות',
                'you can now enjoy the club specials': 'כעת ביכולתך להנות מהטבות המועדון',
                'back to loyalty clubs': 'חזרה למועדונים',
                'you\'ve logged in successfully': 'התחברת בהצלחה',
                'continue without a club': 'המשיכו בלי מועדון',
                'validity': 'תוקף',
                'you saved on this purchase': 'בקנייה זו חסכתם',
                'membership approval': 'אישור חברות',
                'do you have our credit card? login and enjoy unique club benefits': 'מחזיקים בכרטיס אשראי שלנו? התחברו ותהנו מהטבות מועדון ייחודיות',
                'i own the loyalty club credit card': 'בבעלותי כרטיס האשראי של המועדון',
                'club benefits are conditional on payment with the club credit card at checkout': 'הטבות המועדון מותנות בתשלום עם האשראי בסיום הקנייה',
                'register and start shopping!': 'הירשמו והתחילו לקנות! ',
                'cancel_order_credit_card': 'כרטיס אשראי',
                'cancel_order_credit_card_sum': 'סה”כ בכרטיס אשראי',
                'cancel_order_ebt_card' : 'EBT card',
                'cancel_order_ebt_snap_refund' : 'EBT SNAP Refund',
                'cancel_order_ebt_cash_refund' : 'EBT Cash Refund',
                'cancel_order_updated_snap_balance' : 'Updated SNAP Balance',
                'cancel_order_updated_cash_balance' : 'Updated Cash Balance',
                'loyalty club term & conditions': 'לצפייה בתקנון המועדון',
                'image thumbnail': ' תמונה ממוזערת',
                '360 image thumbnail': 'תמונת 360 מעלות ממוזערת',
                'zoom out': 'הקטנה',
                'accessibility component': 'רכיב נגישות',
                'additional delivery times': 'זמני הספקה נוספים',
                'collapse button': 'כפתור כיווץ',
                'expansion button': 'כפתור הרחבה',
                'additional options menu': 'תפריט אפשרויות נוספות',
                'registration attempt has been blocked, please contact support': 'ניסיון ההרשמה נחסם, אנא צור קשר עם התמיכה',
                'account has been suspended, please contact support': 'החשבון הושעה, נא צור קשר עם התמיכה',
                'cart_line_added_alert': 'נוסף לסל הקניות',
                'skip to categories menu': 'דלג לתפריט הקטגוריות',
                'skip to main content': 'דלג לתוכן הראשי',
                'skip to footer': 'דלג לתפריט התחתון',
                'tavplus': 'תו פלוס+',
                'your tav plus': 'התו פלוס שלך',
                'add voucher': 'הוספת תו קניה',
                'tavplusamount: max error': 'הסכום חורג מיתרת ההזמנה',
                'tav_plus_credit_card_disclaimer_short': 'שימוש בתווים באתר אפשרי רק בתנאי שהוזן כרטיס אשראי כגיבוי לתשלום. סכום החיוב הסופי להזמנה ייקבע בזמן איסוף המוצרים ועשוי להשתנות בשל מוצרים שקילים, מוצרים חסרים או...',
                'tav_plus_credit_card_disclaimer_long': 'שימוש בתווים באתר אפשרי רק בתנאי שהוזן כרטיס אשראי כגיבוי לתשלום. סכום החיוב הסופי להזמנה ייקבע בזמן איסוף המוצרים ועשוי להשתנות בשל מוצרים שקילים, מוצרים חסרים או מוצרים תחליפיים. במקרה של חריגת החיוב מהסכום שהוזן למימוש בקניה או מיתרת הסכום בתו/גיפט קארד במועד איסוף המוצרים, יתרת הסכום תחויב בכרטיס האשראי . יש לשמור את התווים/גיפט קארד עד קבלת ההזמנה וביצוע החיוב בהתאם. כמו כן, בעת ביצוע ההזמנה היתרה הצבורה בתו תוותר ללא שינוי וביצוע העסקה יהיה אפשרי אך ורק ביחס ליתרת הצבירה בתו במועד התשלום בפועל. והכל בכפוף למפורט בתקנון. הבהרה ביחס לעובדי קבוצת אלקטרה, קבוצת יינות ביתן ומגה- עובד שיבחר לשלם באמצעות תווי קניה לא יהיה זכאי לקבל בנוסף את הנחת העובד הקבועה שהוא זכאי לקבל.',
                'for additional terms and details': 'לפרטים ותנאים נוספים בנוגע לתו פלוס',
                'go to site': 'לחצו',
                'your voucher details': 'פרטי תו הקניה שלכם',
                'please enter your voucher number': 'אנא הכניסו את מספר התו שלכם',
                'voucher number': 'מספר תו קניה',
                'if your voucher is a plastic card, please keep it in your possession until receiving your order': 'במידה ותוי הקניה שלכם הינם כרטיס פלסטיק, אנא שימרו עליהם ברשותכם לקבלת הזמנתכם',
                'see more': 'הרחב',
                'voucher': 'תו קניה',
                'tav plus': 'תו פלוס',
                'tav plus charge': 'חיוב בתו פלוס',
                'due multiple unsuccessful attempts ,the process cannot be completed': 'בשל ריבוי נסיונות , לא ניתן להשלים את התהליך',
                'multiple attempts': 'ניסיונות מרובים',
                'an error occurred while processing the voucher':'אירעה שגיאה במהלך עיבוד תוי הקניה',
                'try again or check your coucher details':'נסו שוב או בדקו את פרטי השובר',
                'try again':'נסו שוב',
                'delivery-fee-explain-default': 'סכום דמי המשלוח הסופי צפוי להתעדכן בעת התשלום ועשוי להיות נתון לשינויים עקב אזור/זמן המשלוח או כל הנחות נוספות',
                'delivery_type_1_fee': 'דמי משלוח',
                'delivery_type_2_fee': 'איסוף עצמי',
                'includes_delivery_type_1_fee': 'כולל דמי משלוח',
                'includes_delivery_type_2_fee': 'כולל דמי איסוף',
                'not_includes_delivery_fee': '(לא כולל דמי משלוח)',
                'product image, view large image': 'תמונת המוצר, צפה בהגדלה',

                'your points': 'הנקודות שלך',
                'buy with points': 'לקנות עם נקודות',
                'almost expired': 'פג תוקף בקרוב',
                'clip coupon': 'הוסף קופון',
                'product in cart': 'המוצר בעגלה',
                'add product': 'הוסף מוצר',
                'coupon clipped': 'הקופון בעגלה',
                'not clipped but in cart': 'לא הוסף אך מוצרים בעגלה',
			    'clipped but not in cart': 'למימוש, הפריטים לא נוספו',
                'clipped externally': 'למימוש חיצוני',
                'personal coupons': 'קופונים אישיים',
                'may interest you': 'אולי יעניין אתכם',
                'clip for {x} points': 'הוסף עבור {x} נקודות',
                'not enough points': 'לא מספיק נקודות',
				'coupon expires today': 'תוקף הקופון יפוג היום',
                'coupon expires in {x} days': 'תוקף הקופון יפוג בעוד {x} ימים',
                'clip for': 'הוסף עבור',
                'valid': 'תוקף',
                'can be redeemed once': 'ניתן למימוש פעם אחת',
                'can be redeemed {x} times': 'ניתן למימוש {x} פעמים',

                'last_minute_recommendations_headline': 'יש לנו המלצות במיוחד בשבילך',
                'last_minute_recommendations_title_coupons': 'קופונים שיחסכו לכם כסף',
                'last_minute_recommendations_title_tip_donation': 'הוסיפו תוספת קטנה להזמנה שלכם',
                'last_minute_recommendations_title_products_usually': 'אולי שכחת?',
                'last_minute_recommendations_title_products_complementary': 'אולי יעניין אתכם גם...',
                'last_minute_recommendations_title_specials_offers': 'מבצעים שלא כדאי לפספס',
                'last_minute_recommendations_title_products_offers': 'לא תרצו לפספס את המוצרים האלו',
                'move to image': 'מעבר לתמונה',
                'of': 'מתוך',
                'displays image': 'מציג תמונה',
                'in case the "large text" field is empty, this field must be filled in for accessibility support': 'במקרה שהשדה "טקסט גדול" ריק, יש למלא שדה זה בשביל תמיכה בנגישות',
                'increase the amount': 'הגדלת הכמות',
                'reduce the amount': 'הקטנת הכמות',
                'all results': 'כל התוצאות',
                'see all results': 'לכל התוצאות',
                'delete shopping cart': 'מחק עגלת קניות',
                'get': 'קבל',
                'increase the amount of': 'הגדלת הכמות ל',
                'reduce the amount of': 'הקטנת הכמות ל',
                'loyalty_club_continue': 'המשיכו',

                'add_units_special': 'הוסף {remaining} יחידות למימוש המבצע',
                'add_currency_special': 'הוסף {remaining} למימוש המבצע',
                'add_measure_special': 'הוסף {remaining} {unit} למימוש המבצע',
                'add_units_coupon': 'הוסף {remaining} מוצרים כדי לקבל הנחה',
                'add_currency_coupon': 'הוסף {remaining} כדי לקבל הנחה',
                'add_measure_coupon': 'הוסף {remaining} {unit} כדי לקבל הנחה',
                'add_units_cart_coupon': 'הוסיפו {remaining} מוצרים',
                'add_unit_cart_coupon': 'הוסיפו מוצר {remaining}',
                'add_currency_cart_coupon': 'הוסיפו {remaining}',
                'add_measure_cart_coupon': 'הוסיפו {remaining} {unit}',
                'add_units_special_purchase': 'הוסף עוד {remaining}',
                'buy_units_total': 'קנה {total} מוצרים',
                'buy_unit_total': 'קנה מוצר {total}',
                'buy_currency_total': 'קנה ב {total}',
                'buy_measure_total': 'קנה {total} {unit}',
                'there are no available items for this promotion': 'אין מוצרים זמינים למבצע זה כרגע',
                'cart deleted': 'העגלה נמחקה',
                'iframe source not loaded': 'לינק הרשמה ממקור חיצוני אינו נטען',
                'use the arrow keys to navigate between the tabs': 'כדי לעבור בין הלשוניות השתמשו במקשי החיצים',
                'cant_find_address_error': 'we couldn\'t find any addresses for this country code',
                'otpphone: required error': 'הכנס מספר טלפון תקין (10 ספרות)',
                'otpphone: minlength error': 'הכנס מספר טלפון תקין (10 ספרות)',
                'otpemail: required error': 'הכנס כתובת מייל תקינה',
                'otpemail: email error': 'הכנס כתובת מייל תקינה',
                'sorry, you have exceeded the number of attempts allowed for the current user.': 'חרגת ממספר הניסיונות המותר עבור משתמש זה',
                'please try again later or contact customer service for assistance: xxx-xxxxxx': 'אנא נסה שנית מאוחר יותר או צור קשר עם שירות לקוחות לעזרה:XXX-XXXXXX',
                'no_coupons_for_filter': 'מצטערים<br>לא מצאנו את קופון "{coupon_filter}".',
                'search coupon': 'חפש קופון',
                'change chosen time': 'שנה זמן נבחר',
                'ok, continue': 'אוקי, המשך',
                'suggestions_dialog_header_1': 'המלצות למוצר חליפי',
                'suggestions_dialog_header_2': 'בחרו תחליף ממגוון המוצרים הדומים שבמלאי',
                'suggestions_dialog_header_3': 'חסר במלאי',
                'suggestions_dialog_header_4': 'מוצרים מומלצים כתחליף',

                'hover description for cvv': 'מספר CVV (קוד אימות כרטיס) למרבית כרטיסי ויזה, Mastercard, כרטיסים בנקאיים, וכו \' זהו קוד של שלוש ספרות. בכרטיסי אמריקן אקספרס (AMEX), זה קוד בן ארבע ספרות ',
                'enter cvv number': '',
                'select pickup time': 'בחר זמן איסוף',
                'select delivery time': 'בחר זמן אספקה',
                'please notice, the expiration of these {count} {type} will not be valid for this chosen time': 'שימו לב, התוקף עבור {count} {type} אלו לא יהיו תקפים עבור הזמן שנבחר',
                'the expiration of these {count} {type} will not be valid for this chosen time': 'התוקף עבור {count} {type} אלו לא יהיו תקפים עבור הזמן שנבחר',
                'these {count} coupons will not be valid for this chosen time': 'עבור הזמן שנבחר {count} קופונים אלו לא יהיו תקפים',
                'please notice, the coupon displayed below, will not be valid for this chosen time': 'שימו לב, הקופון המוצג למטה לא יהיה בתוקף עבור הזמן שנבחר',
                'please notice, the special displayed below, will not be valid for this chosen time': 'שים לב, מבצעים אלו לא יהיו בתוקף עבור הזמן שנבחר',
                'the coupon displayed below, will not be valid for this chosen time': 'הקופון המוצג למטה לא יהיה בתוקף עבור הזמן שנבחר',
                'the special displayed below, will not be valid for this chosen time': 'מבצעים אלו לא יהיו בתוקף עבור הזמן שנבחר',
                'the reward you have redeemed will be added automatically at discount': 'הכסף שמימשת מחכה לך בקופה',
                'your accumulated reward points will be added automatically at checkout': 'הנקודות שצברת מחכות לך בקופה',
                'congrats! you have accumulated': 'איזה כייף, צברת במועדון',
                'redeem now': 'רוצה לממש בקנייה זו',
                'in this purchase you have accumulated about {xxx points} reward points. the final calculation will be made after your order has been fulfilled and charged': 'בקנייה זו צברת כ {xxx points} נקודות החישוב הסופי יקבע לאחר ליקוט וחיוב ההזמנה',
                'points': 'נקודות',
                'congrats! you\'ve redeemed': 'איזה כיף, מימשת',
                'i have changed my mind': 'התחרטתי',
                'enjoy! we have updated your discount': 'תהנו! עדכנו לכם את ההנחה',
                'accumulate another {xxx points} to get a discount on your next purchase': 'בצבירה נוספת של כ {xxx points} נקודות, תוכל להנות מהנחה בקנייה הבאה',
                'you have accumulated': 'צברת כ',
                'through {name}': 'במועדון {name}',
                'total after discounts and promotions': 'סה"כ אחרי הנחות ומבצעים',
                'payment with': 'תשלום ב',
                'you haven\'t accumulated any points on {name} yet': 'טרם צברת נקודות במועדון {name}',
                'your personal payment code': 'הקוד שלך לתשלום במסעדה',
                'loyalty members enjoy the variety of promotions': 'חברי מועדון נהנים ממגוון מבצעים',
                'what kind of foodie are you?': 'איזה Foodies אתם?',
                'cooking at home or eating outside?': 'אוכלים בחוץ או מבשלים בבית?',
                'sign in/ sign up': 'התחברו / הצטרפו למועדון',
                'hi {customer name}, you\'ve accumulated {points accumulation} points on foodies program': 'שלום {Customer Name} , צברת {points accumulation} נקודות בתוכנית המועדון',
                'with an additional accumulation of {money value} ,you\'ll be able to redeem cashback.': 'עוד קנייה ב{money value} ותוכלו לממש את הנקודות שצברתם',
                'hi {customer name}, congrats! {cashback amount} is waiting for you on the cart': 'שלום {Customer Name} , תהנו ! {cashback amount} מחכים לך בקופה',
                'unredeemed': 'התחרטתי',
                'find out how much you\'ll earn from your purchases in a month.': 'גלו כמה כסף תרוויחו מהקניות שלכם בחודש',
                'what\'s your monthly shopping spend? (estimated)': 'כמה אתם מוציאים בחודש על קניות? (בערך, לא נתפוס אתכם במילה)',
                'in other words...': 'רק כדי לעורר לכם את התיאבון...',
                'it means you\'ll earn {yearly profit amount} per year': 'זה אומר שבשנה תרוויחו {yearly profit amount}',
                'the code is only valid for the next 30 minutes': 'הקוד תקף ל30 דקות בלבד',
                'you can choose where and how to redeem your rewards!': 'אתם בוחרים איפה ואיך לממש את הנקודות שלכם!',
                'here\'s a list of hundreds of external services to redeem points': 'לרשימת מאות המסעדות בהן ניתן לממש את הכסף שצברתם',
                'the locations in which you can redeem your rewards': 'הסניפים בהם תוכל לממש',
                'price does not include bottle deposit': 'המחיר אינו כולל פיקדון',
                'price includes bottle deposit': 'המחיר כולל פיקדון',
                'questions and answers': 'שאלות ותשובות',
                'loyalty points': 'נקודות מועדון',
                'login / sign up to continue...it\'s easy!': 'התחברו / הצטרפו למועדון כדי להמשיך...זה פשוט!',
                'tenbis': 'תן ביס',
                'rak besmachot': 'רק בשמחות',
                'praxel': 'פרקסל',
                'cibus': 'סיבוס',
                'hitech zone': 'הייטקזון',
                'gold north': 'גולד צפון',
                'dolce vita': 'דולצ\'ה ויטה',
                'istudent': 'איי סטודנט',
                'cal': 'ויזה כאל',
                'direct insurance club': 'מועדון ביטוח ישיר',
                'yours club': 'מועדון שלך',
                'psagot': 'פסגות',
                'yerushalmi': 'ירושלמי עיריית ירושלים',
                'leumi goodies': 'לאומי גודיז',
                'pais plus': 'פיס פלוס מועדון מפעל הפיס',
                'raayonit': 'רעיונית',
                'you\'ve accumulated {xxx points} points final calculation will be based on picking and payment': 'בקנייה זו צברת כ {xxx points} נקודות החישוב הסופי יקבע לאחר ליקוט וחיוב ההזמנה',
                'with an additional accumulation of {xxx points} points, you will receive a discount on your next purchase': 'בצבירה נוספת של כ {xxx points} נקודות, תוכל להנות מהנחה בקנייה הבאה',
                'balance to be paid': 'יתרה לתשלום',
                'please enter the card number to check the balance': 'הקלידו את מספר הכרטיס בכדי לבדוק את היתרה בו',
                'redeem': 'למימוש',
                'please save the voucher or gift card until you receive your order and are charged': 'נא לשמור את השובר או כרטיס המתנה עד שתקבל/י את ההזמנה והחיוב',
                'choose gift card/s': 'בחרו כרטיס/י מתנה',
                'add gift card': 'הוסף גיפט קארד',
                'redeem gift card & discount cards': 'למימוש גיפט קארד וכרטיסי הנחה',
                'for gift card and discount cards': 'למימוש גיפט קארד וכרטיסי הנחה',
                'card number': 'מספר כרטיס',
                'gift_card_error_message': 'לא הצלחנו לעבד את הגיפט קארד, נא לנסות שנית או לנסות עם גיפט קארד אחר',
                'wrong_gift_card_amount_error': 'פדיון הכרטיס אינו אפשרי. הסכום שהזנת גבוה מהיתרה בעגלה / כרטיס המתנה',
                'customercredit': 'לקוח בהקפה',
                "sorry, currently we don't deliver to this address for further inquiries call to the customer service.": 'נכון להיום, שירות המשלוחים עדיין לא מגיע לכתובת הזאת. לעזרה, אפשר להתקשר לשירות הלקוחות שלנו.',
                'gg_map_dialog_title': 'בחרו כתובת',
                'gg_map_dialog_hint': 'לא הצלחנו לאתר את מיקומכם. אנא נעצו את הסיכה בצורה מדויקת כדי שנוכל למצוא את הכתובת שלכם',
                'gg_map_alert_need_location': 'אנא הפעילו מיקום כדי להשתמש בגוגל מפות',
                'gg_map_alert_error': 'ישנן בעיות בהצגת גוגל מפות',
                'gg_map_alert_unsupport': 'מיקום גיאוגרפי אינו נתמך על ידי דפדפן זה',
                'autocomplete_adrs_didnt_find': 'אינכם מוצאים את הכתובת?',
                'autocomplete_adrs_choose_location': 'בחרו את מיקומכם במפה',
                'sorry, your city was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.': 'מצטערים, העיר לא נמצאה. אנא בחרו כתובת מתוך הצעות להשלמה אוטומטית או נעצו את הכתובת באמצעות גוגל מפות.',
                'sorry, your address was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.': 'מצטערים, הכתובת לא נמצאה. אנא בחרו כתובת מתוך הצעות להשלמה אוטומטית או נעצו את הכתובת באמצעות גוגל מפות.',
                'country: required error': 'אנא בחרו מדינה',
                'country_of_origin_title': 'ארץ מוצא',
                'origin_country_legal_text': 'שימו לב- ארץ המקור נכונה למועד ההזמנה. ייתכן וארץ המקור של המוצר שיסופק תהיה שונה, בהתאם למלאי הקיים בנקודת הליקוט במועד האספקה',
                'after package deduction': 'בניכוי עלויות אריזה',
                'january': 'ינואר',
                'february': 'פברואר',
                'march': 'מרץ',
                'april': 'אפריל',
                'may': 'מאי',
                'june': 'יוני',
                'july': 'יולי',
                'august': 'אוגוסט',
                'september': 'ספטמבר',
                'october': 'אוקטובר',
                'november': 'נובמבר',
                'december': 'דצמבר',
                'redeemed': 'מומשו',
                'points gained': 'נקודות שנצברו',
                'source': 'מקור',
                'when': 'מתי',
                'retrieving data. wait a few seconds and try to cut or copy again': 'האם הכתובת נכונה? עכשיו אפשר לערוך ולדייק',
                'is this address correct? you can now edit and refine it': 'האם הכתובת נכונה? עכשיו אפשר לערוך ולדייק',
                'edit your address': 'ערוך את כתובתך',

                'log in': 'הכנס',
                'continue with email and password': 'כניסה באמצעות מייל וסיסמה',
                'continue with facebook': 'כניסה באמצעות פייסבוק',
                'continue with a one time password': 'המשך עם סיסמה חד-פעמית',
                'please enter the {number of digits} digit code sent to {otpcredential} through {typeofotp}': 'נא להזין את הקוד בעל {number of digits} הספרות שנשלח {typeOfOTP} למספר {otpCredential}',
                "few more details and we're done!": 'עוד כמה פרטים וסיימנו!',
                'i agree the terms & conditions and the privacy policy': 'אני מסכים/ה לתנאים וההגבלות ולמדיניות הפרטיות',
                'new here? sign up': 'חדשים כאן? הירשמו',
                'do not have an account?': 'אין לכם חשבון?',
                'sign up': 'הירשמו',
                'already have an account?': 'כבר יש לכם חשבון?',
                'send code': 'שלח קוד',
                'phone number or email': 'מספר טלפון או מייל',
                'a one - time identification by sending a code to your mobile or email': 'זיהוי חד פעמי באמצעות שליחת קוד לטלפון או למייל שלכם',
                'log in or sign up': 'התחברות או הרשמה',
                'this number is connected with more than one email. please choose one of the following email addresses to continue with.': 'מספר זה משויך למספר כתובות מייל. בחרו בבקשה בכתובת מייל אחת כדי להמשיך.',
                'are you sure you want to continue with this email?': 'בטוחים שאתם רוצים להמשיך עם המייל הזה?',
                'choose one email to continue': 'בחרו בכתובת מייל אחת כדי להמשיך',
                'forgot your password?': 'שכחתם סיסמה?',
                'just one more step to go': 'עוד צעד אחד בלבד',
                'registering to the website automatically registers you to the club without any cost or obligations': 'הרשמה לאתר מחברת אתכם אוטומטית למועדון הלקוחות ללא עלות או התחייבות',
                'enter valid phone number (10 digits)': 'הכנס מספר טלפון תקין (10 ספרות)',
                'are you sure you want to log out? this action will cancel the login / registration process': 'האם אתם בטוחים שתרצו לצאת? פעולה זאת תבטל את ההתחברות / תהליך ההרשמה',
                'yes, log out': 'כן, לצאת',
                'password must contain at least one numeric digit and one character': 'הסיסמה חייבת להכיל לפחות ספרה אחת ותו אחד',
                'the passwords must match': 'הסיסמאות צריכות להיות תואמות',
                'please confirm password': 'אנא אשרו את הסיסמה',

                'the given phone number already exists in the system': 'מספר הטלפון הנתון כבר קיים במערכת',
                'searchtext_storename_placeholder': '{שם חנות}',
                'contact_us': 'יצירת קשר',
                'how_can_we_help_you_today': 'איך נוכל לעזור לכם היום?',
                'leave_us_a_message': 'השאירו לנו הודעה ונחזור אליכם בהקדם',
                'fullname': 'שם מלא',
                'message': 'איך נוכל לעזור לכם?',
                'your_message_has_been_sent': 'ההודעה שלכם נשלחה',
                'thank_you_for_contact_us': 'תודה על שיצרתם איתנו קשר',
                'nofshonit': 'נופשונית',
                'max gift card': 'מקס גיפט קארד',
                'instead': 'במקום',
                'x items in cart': 'x פריטים בעגלה',
                'please enter cvv to continue': 'יש להקליד CVV על מנת להמשיך',
                'customer support phone number': 'מספר טלפון שירות לקוחות',
                'please notice, the following products are not available on this chosen time': 'שים לב, המוצרים הבאים אינם מסופקים בזמן שנבחר',
                'the following products are not available on this chosen time': 'המוצרים הבאים אינם מסופקים בזמן שנבחר',
                'if you continue, they will be removed from your cart': 'אם תמשיכו, מוצרים אלו יוסרו מהעגלה',
                'Verification challenge expired. Check the checkbox again.': 'פג תוקף האימות. עליך לסמן שוב את תיבת הסימון.',
                'complete_and_proceed_to_checkout': 'סיים והמשך לתשלום',
                'result for “{title}”': 'תוצאות עבור “{title}”',
                'find a product': 'חפשו מוצר',
                'brand Results:': 'תוצאות לפי מותג:',
                'checkout is opened on another screen':'דף התשלום נפתח במסך אחר',
				'loading coupons, please wait...': 'קופונים בטעינה, אנא המתינו...',
				'please wait, we are loading the products': 'אנא המתינו, אנחנו טוענים את המוצרים',
                'pickup time:': 'זמן איסוף:',
                'pickup address:': 'כתובת לאיסוף:',
                'delivery time:': 'זמן קבלת המשלוח:',
                'delivery address:': 'כתובת למשלוח:',
                'on_day': '',
                'wish_to_change_delivery':'מעוניין לשנות את זמן קבלת המשלוח?',
                'wish_to_change_pickup':'רוצה לשנות את זמן האיסוף?',
                'select date & time':'בחירת תאריך ושעה',
                'save & change delivery time':'שמירה ושינוי זמן המשלוח',
                'save & change pickup time':'שמירה ושינוי זמן האיסוף',
                'back to home page':'חזרה לדף הבית',
                'your order has been updated':'ההזמנה עודכנה',

                'checkout_finish_last_update_time_delivery': 'אפשר לעדכן את זמן קבלת המשלוח עד',
                'checkout_finish_last_update_time_pickup': 'אפשר לעדכן את זמן האיסוף עד',
                'forgot_sth_want_to_change_delivery': 'רוצה לשנות את זמן המשלוח?',
                'forgot_sth_want_to_change_pickup': 'רוצה לשנות את תאריך האיסוף?',

                'update_order_popup_title_def': 'אפשר לעדכן את פרטי ההזמנה או את זמן ה {delivery method}.',
                'update_order_popup_order_item_def': 'עדכון פריטי הזמנה',
                'update_order_popup_delivery_time_def': 'עדכון זמן המשלוח',
                'update_order_popup_pickup_time_def': 'עדכון זמן האיסוף',
                'update_order_popup_cancel_def': 'רוצים לבטל את ההזמנה? לחצו כאן כדי לבטל אותה {cancel order}',
                'update_order_popup_lastest_time_def': 'ניתן לעדכן את ההזמנה {delivery method} עד {time calculate}',
                'update_order_popup_err_cant_update_time': 'עדכון מועד ה-{delivery method} שלך אינו זמין',

                'irre_sales_collect_one': 'ה{saleType} הזה לא יהיה תקף לפרק הזמן המעודכן ותחויבו לפי המחיר הרגיל',
                'irre_sales_collect_many': 'ה{count} {saleType} האלה לא יהיו תקפים לפרק הזמן המעודכן ותחויבו לפי המחיר הרגיל',
                'irre_sales_checkout_one': 'עם עדכון ההזמנה הזו, ה{saleType} הבא לא יהיה תקף עוד ותחויבו על המוצר לפי מחירו הרגיל',
                'irre_sales_checkout_many': 'עם עדכון ההזמנה הזו, ה{count} {saleType} הבאים לא יהיו תקפים עוד ותחויבו על המוצרים לפי מחירם הרגיל',
                'irre_sales_oos_one': 'המוצרים הבאים אינם זמינים כעת ויוסרו מהסל שלך',
                'irre_sales_oos_many': 'המוצרים הבאים אינם זמינים כעת ויוסרו מהסל שלך',
                'irre_sales_btn_continue': 'אוקיי, עדכן',
                'irre_sales_btn_change_slot': 'שינוי תאריך ה{delivery method}',
                'irre_sales_btn_cancel_update': 'ביטול עדכון',
                
                'confirm_full_credit_card_charge_title': 'אישור חיוב מלא בכרטיס האשראי',
                'confirm_full_credit_card_charge_content': 'סכום התשלום EBT מוגדר ל- $0. האם אתה בטוח שברצונך לגבות את הסכום המלא מכרטיס האשראי שלך?',
                'confirm_full_credit_card_charge_content_btn_confirm': 'כן, גבו מכרטיס האשראי',
                'confirm_full_credit_card_charge_content_btn_back': 'חזרה',
                'thank you for your order!':'תודה על הזמנתך!',
                'an error occurred (error {error_code}), please try again in a few minutes, if the problem recurs contact customer service': 'ארעה שגיאה (שגיאה {error_code}), אנא נסו שוב בעוד מספר דקות, אם הבעיה חוזרת פנו לשירות הלקוחות',
                'payment error': 'שגיאת תשלום',
                'error received': 'התקבלה שגיאה',
                'please check you credit card details or contact your credit card provider': 'אנא בדקו את פרטי כרטיס האשראי שלכם או פנו לספק האשראי שלכם.'
            };
        }]);
})(angular, app);
